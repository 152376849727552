/** @jsx jsx */
import { jsx } from '@emotion/core';
import loader from '../../_elements/loader';


const Loader = () => {
    return (
        <div css={loader}>
            <div className="d-flex flex-column align-items-center justify-content-center">
                <span>
                    <img src="/images/logo_blue.png" className="loader-img-blue" alt="" />
                    <img src="/images/logo_white.png" className="loader-img-white" alt="" />
                    <img src="/images/logo_orange.png" className="loader-img-orange" alt="" />
                </span>
                <div class="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
	);
}

export default Loader
