import React from 'react';
import Main from '../../Layouts/Main';

function NotFound() {
	return (
		<Main>
			<h1 className="title">404 Not Found</h1>
		</Main>
	);
}

export default NotFound;
