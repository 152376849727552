/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import {Row, Col} from 'react-bootstrap';

const timeline = css`
    .timeline {
		position: relative;
		max-width: 1200px;
		margin: 0 auto;
	}
	.timeline::after {
		content: '';
		position: absolute;
		width: 3px;
		background-color: white;
		top: 0;
		bottom: 0;
		left: 50%;
		margin-left: -3px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)

	}
	.timeline-container {
		padding: 10px 25px;
		position: relative;
		background-color: inherit;
		width: 50%;
	}
	.timeline-container::after {
		content: '';
		position: absolute;
		width: 20px;
		height: 20px;
		right: -9px;
		background-color: white;
		/* border: 4px solid #ff9f55; */
		top: 30px;
		border-radius: 50%;
		z-index: 1;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
	}

    .new-date .timeline-container {
        margin-top:100px;
    }

    .new-date .timeline-container::before {
        content: attr(year-data);
        display:block;
        position:absolute;
        margin-bottom:50px;
        margin-top:-10px;
        top:-50px;
        right:-37px;
        z-index:100;
        height:40px;
        width:80px;
        border:1px solid white;
        background-color:#B4D7FE;
        color:white;
        font-size:24px;
        padding-top:3px;
        text-align:center;
    }

	.left {
		left: 0;
	}
	.right {
		left: 50%;
	}
	.right::after {
		left: -11px;
	}
	.content {
		padding: 10px 20px;
		position: relative;
		border-radius: 15px;
	}
    .left {
        display:flex;
        flex-direction:row;
        justify-content:flex-end;
    }
    .right {
        display:flex;
        flex-direction:row;
        justify-content:flex-start;
    }

    .left .content  {
        text-align:right;
        background-color: white;
    }

    .right .content  {
        text-align:left;
        background-color: white;
    }

	.timeline-date {
		color: #ce4242;
	}

`;

const Timeline = () => {
    return (
        <Row className="mt-5">
            <Col md="12">
                <div css={timeline}>
                    <h4 className="mb-4">Your Timeline</h4>
                    <div className="timeline" year-data="2019">
                        <div className="timeline-container left">
                            <div className="content">
                                    <h3 className="timeline-date">2020-10-04</h3>
                                    <h5 className="timeline-task">Posted New Energy</h5>
                                    <p className="timeline-details">Energy Name: Solar Energy</p>
                            </div>
                        </div>
                        <div className="timeline-container right">
                            <div className="content">
                                <h3 className="timeline-date">2020-06-11</h3>
                                <h5 className="timeline-task">Started Using Energy of Ramesh Nepal</h5>
                                <p className="timeline-details">Energy Name: Solar Energy</p>

                            </div>
                        </div>  
                        
                        <div className="timeline-container left">
                            <div className="content">
                                <h3 className="timeline-date">2020-06-04</h3>
                                <h5 className="timeline-task">Posted New Energy</h5>
                                <p className="timeline-details">Energy Name: Solar Energy</p>
                            </div>
                        </div>
                        
                        <div className="timeline-container right">
                            <div className="content">
                                <h3 className="timeline-date">2020-03-24</h3>
                                <h5 className="timeline-task">Subscribed Energy</h5>
                                <p className="timeline-details">Energy Name: Solar Energy</p>
                            </div>
                        </div>

                        <div className="new-date">
                            <div className="timeline-container left"  year-data="2019">
                                <div className="content">
                                    <h3 className="timeline-date">2019-12-04</h3>
                                    <h5 className="timeline-task">Posted New Energy</h5>
                                    <p className="timeline-details">Energy Name: Solar Energy</p>
                                </div>
                            </div>
                        </div>

                        <div className="timeline-container right">
                            <div className="content">
                                <h3 className="timeline-date">2019-11-23</h3>
                                <h5 className="timeline-task">Posted New Energy</h5>
                                <p className="timeline-details">Energy Name: Solar Energy</p>
                            </div>
                        </div>

                        <div className="timeline-container left">
                            <div className="content">
                                <h3 className="timeline-date">2019-01-23</h3>
                                <h5 className="timeline-task">Account Created</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default Timeline
