import React, { useEffect, useState } from 'react';
import PropertyForm from './PropertyForm';
import { fetchWrapper } from '../../_helpers';

const PropertyEdit = ({ match }) => {
	const [property, setProperty] = useState(null);
	const { id: propertyId, type } = match.params;
	useEffect(() => {
		if (propertyId) {
			fetchWrapper
				.get(`property/${propertyId}`)
				.then((res) => setProperty(res))
				.catch((e) => console.log(e));
		}
	}, [propertyId]);
	return property && <PropertyForm data={property} type={type} />;
};

export default PropertyEdit;
