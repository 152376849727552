/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import Sidebar from './Sidebar/Sidebar';

const main = css`
	margin-left: 14rem;
	width: auto;
	@media (max-width: 1200px) {
		margin-left: 3rem;
	}
`;

function Main(props) {
	return (
		<div style={{ width: '100%' }}>
			<Sidebar />
			<div css={main}>{props.children} </div>
		</div>
	);
}

export default Main;
