/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Main from '../../Layouts/Main';
import TopBar from '../../Layouts/TopBar/TopBar';
import { Button, Card, Row, Col, Form, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getAllSubsciption, getAllInvoice } from '../../_redux/actions/ActionPayment';
import { RiFilePaperFill } from 'react-icons/ri';
import Loader from '../../Layouts/Loader/Loader';
import { dateFormat } from '../../_helpers';
// import { fetchWrapper } from '../../_helpers';

const billingStyle = css`
	padding:0px 20px;

	.upcomming-payment-card {
		background-color: #011941;
		color: #fff;
		margin-right: 1rem;
		width: 15rem;
		padding: 0.5rem;
	}
	.upcomming-payment-card span {
		font-size: 1rem;
		border-bottom: 1px solid #fff;
	}
	.upcomming-payment-card p,
	.upcomming-payment-card p span {
		font-size: 0.8rem;
		border: none;
	}
	.pay-btn {
		border-radius: 10px;
		background-color: #006191;
		padding: 0.1rem 1rem !important;
		font-size: 0.8rem;
		color: #fff;
		border: none;
	}
	.search-input {
		width: 15rem;
		background-color: #dedee3;
		font-size: 0.9rem;
	}

	.table thead > tr > th {
		border-top: none;
	}
`;

const bigButtons = css`
	font-size: 1rem;
	border-radius: 10px;
	color: white;
	border: solid 1px #fbfbfb;
	background-color: #006191;
	padding: 0.2rem 1rem;
`;

const Billing = ({ getAllSubsciption, getAllInvoice, user }) => {
	const [subsription, setSubscription] = useState(null);
	const [invoices, setInvoices] = useState(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		getAllSubsciption().then((sub) => setSubscription(sub));
	}, [getAllSubsciption, getAllInvoice]);

	useEffect(() => {
		setLoading(true);
		getAllInvoice()
			.then((sub) => {
				setInvoices(sub);
				setLoading(false);
			})
			.catch((e) => setLoading(false));
	}, [getAllInvoice]);

	console.log(invoices)
	
	return (
		<Main>
			<TopBar>
				<div css={billingStyle}>
					<Row>
						{loading ? (
							<Loader />
						) : invoices && invoices.length > 0 ? (
							invoices
								.map((inv) => (
									<Col sm="3" className="mb-4">
										<Card className="upcomming-payment-card">
											<div className="text-center mb-1">
												<span>Upcoming payment</span>
											</div>
											<p className="mb-0">
												Energy name:{' '}
												<span className="font-weight-bold ">{inv.energy_name}</span>
											</p>
											<p className="mb-0">
												Renewed on:
												<span className="font-weight-bold">{dateFormat(inv.from)}</span>
											</p>
											<p className="mb-0">
												Expires on:
												<span className="font-weight-bold">{dateFormat(inv.to)}</span>
											</p>
											<p className="mb-0">
												Remaining days:{' '}
												<span className="font-weight-bold">
													{Math.round(
														Math.max(
															0,
															(new Date(inv.to) - new Date()) / 1000 / 60 / 60 / 24
														)
													).toFixed(0)}
												</span>
											</p>
											<p className="mb-0">
												Price: <span className="font-weight-bold">{inv.price}</span>
											</p>
											<div className="d-flex justify-content-center my-2">
												<Link
													to={`/cart/checkout/${inv.subscription_id}/${inv.price}/${inv.invoice_id}`}
												>
													<Button className="pay-btn">Pay Now</Button>
												</Link>
											</div>
										</Card>
									</Col>
								))
								.reverse()
						) : (
							<Col md={12}>
								<div className="d-flex align-items-center justify-content-center text-danger">
									<RiFilePaperFill size="46" className="px-2" />{' '}
									<span>Billing is currently empty</span>
								</div>
								<div className="d-flex align-items-center justify-content-center mt-5">
									<Link className="text-decoration-none" to={`/market`}>
										<Button className="p-2" css={bigButtons}>
											Discover Clean Energy
										</Button>
									</Link>
								</div>
							</Col>
						)}
					</Row>
					{user && user.is_seller && (
						<div className="my-4">
							<Card>
								<Card.Body>
									<div className="text-center mb-2">
										<p className="mb-1 font-weight-bold">Energy you are selling</p>
										<div className="d-flex justify-content-center">
											<Form.Control
												type="text"
												className="search-input"
												placeholder="Search Energy"
											/>
										</div>
									</div>
									<Table hover className="mb-5">
										<thead>
											<tr>
												<th>Date</th>
												<th>Payment Mode</th>
												<th>Payment Type</th>
												<th>Paying</th>
												<th>Payment Done</th>
												<th>Status (paid/unpaid)</th>
											</tr>
											{subsription
												? subsription.map((sub) => (
														<tr key={sub.id}>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
														</tr>
												  ))
												: null}
										</thead>
										<tbody></tbody>
									</Table>
								</Card.Body>
							</Card>
						</div>
					)}
					{user && user.is_buyer && (
						<div className="my-4">
							<Card>
								<Card.Body>
									<div className="text-center mb-2">
										<p className="mb-1 font-weight-bold">Energy you are buying</p>
										<div className="d-flex justify-content-center">
											<Form.Control
												type="text"
												className="search-input"
												placeholder="Search Energy"
											/>
										</div>
									</div>
									<Table hover className="mb-5">
										<thead>
											<tr>
												<th>Date</th>
												<th>Payment Mode</th>
												<th>Payment Type</th>
												<th>Paying</th>
												<th>Payment Done</th>
												<th>Status (paid/unpaid)</th>
											</tr>
										</thead>
										<tbody></tbody>
									</Table>
								</Card.Body>
							</Card>
						</div>
					)}
				</div>
			</TopBar>
		</Main>
	);
};

const mapStateToProps = (state) => ({ user: state.user.user });

const mapDispatchToProps = { getAllSubsciption, getAllInvoice };

export default connect(mapStateToProps, mapDispatchToProps)(Billing);
