/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useState, Fragment } from 'react';
import { Button, Modal, Form, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import {RiCloseFill} from 'react-icons/ri'

const addToCartBox = css`
	font-size: 0.9rem;
	.close-btn {
		position: absolute;
		top: 0.4rem;
		right: 0.6rem;
		font-size: 1.3rem;
		cursor: pointer;
	}

	select,
	select:focus {
		-webkit-appearance: none;
		appearance: none;
		-moz-appearance: none;
		outline: none;
		border-top: none;
		border-right: none;
		border-left: none;
		border-bottom: 2px solid #000;
		border-radius: 0;
		background-color: transparent !important;
		background-image: url('/images/dropdown.svg');
		background-repeat: no-repeat !important;
		background-position: right 10px top 50%;
		background-size: 10px;
	}
`;

const bigButtons = css`
	margin: 1rem;
	font-size: 1.2rem;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;
	height: 3rem;
	border-radius: 10px;
	letter-spacing: normal;
	font-weight: 700;
	background-color: var(--secondary);
	color: white;
`;

const smallButton = css`
	font-size: 1rem;
	border-radius: 10px;
	color: white;
	border: solid 1px #fbfbfb;
	background-color: #006191;
	padding: 0.2rem 1rem;
`;

const addToCartButton = css`
	background-color: #006191;
	color: #fff;
	border-radius: 8px;

	.select-property{
		min-width:400px;
	}
`;

function AddToCartBox({ handleAddToCart, mar, property, buttonText }) {
	const [show, setShow] = useState(false);

	const { register, handleSubmit } = useForm({
		mode: 'onBlur',
		defaultValues: {},
	});

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const handleAdd = (data) => {
		handleAddToCart(mar, data);
		setShow(false);
	};

	return (
		<Fragment>
			<Button css={addToCartButton} style={{ width: buttonText ? 'auto' : '100%' }} onClick={handleShow}>
				{buttonText ? buttonText : 'Order'}
			</Button>

			<Modal show={show} css={addToCartBox} onHide={handleClose} centered>
				<Modal.Body className="p-3 position-relative">
					<span className="close-btn" onClick={handleClose}>
						<RiCloseFill/>
					</span>
					<div className="addtocart-content mt-3 py-2 text-center">
						{property.length > 0 ? (
							<Form onSubmit={handleSubmit(handleAdd)}>
								<div className="text-left px-2">
									<Form.Group controlId="formPlaintextEmail">
										<div className="d-flex flex-row">
											<Form.Label column sm="5" className="font-weight-bold">
												Buying for
											</Form.Label>
											<Form.Control className="select-property pr-5" as="select" name="property_id" ref={register}>
												{property.length &&
													property.map((pro) => (
														<option key={pro.id} value={pro.id}>
															{pro.name}
														</option>
													))}
											</Form.Control>
										</div>
									</Form.Group>
									
									<div className="my-2">
										<p className="mb-0 font-weight-bold">
											How long are you planning to use the energy?
										</p>
										<div className="mb-3">
											<Form.Group controlId="monthly">
												<Form.Check type="checkbox" label="$ XXXX billed monthly" />
											</Form.Group>
											<Form.Group controlId="annually">
												<Form.Check type="checkbox" label="$ XXXX billed annually" />
											</Form.Group>
										</div>
									</div>
								</div>
								<Button css={bigButtons} type="submit">
									Confirm
								</Button>
							</Form>
						) : (
							<Col md={12}>
								<div className="d-flex align-items-center justify-content-center text-danger">
									<span>You do not have any properties</span>
								</div>
								<div className="d-flex align-items-center justify-content-center mt-5">
									<Link className="text-decoration-none" to={`/property`}>
										<Button className="p-2" css={smallButton}>
											Add New Property
										</Button>
									</Link>
								</div>
							</Col>
						)}
					</div>
				</Modal.Body>
			</Modal>
		</Fragment>
	);
}
const mapStateToProps = (state) => ({ property: state.user.property });

export default connect(mapStateToProps)(AddToCartBox);
