/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, Card } from 'react-bootstrap';
import Main from '../../Layouts/Main';
import TopBar from '../../Layouts/TopBar/TopBar';
import { updateUserData, getUserData } from '../../_redux/actions/ActionUser';
import { Link } from 'react-router-dom';
import { WiSmoke } from 'react-icons/wi';
import { FaTree } from 'react-icons/fa';
import SavedPayment from './SavedPayment';
import EditProfile from './EditProfile';
import Timeline from './Timeline';
import { fetchWrapper } from '../../_helpers';
import TransferEarning from './TransferEarning';
import Loader from '../../Layouts/Loader/Loader';
import AddCard from './AddCard';

const profile = css`
	padding:0px 20px;
	.profile-section {
		background-color: #a1ceff;
		padding: 30px;
		margin: 0px;
	}

	input,
	input:focus,
	textarea {
		border-top-style: hidden;
		border-right-style: hidden;
		border-left-style: hidden;
		border-bottom-style: groove;
		width: 50%;
		background-color: transparent !important;
		font-size: 1rem;
		outline: none !important;
		border: 1px 0px 0px 0px solid black;
	}
	.profile-img {
		height: 150px;
		width: 150px;
		object-fit: cover;
		border-radius: 16px;
	}
	.impact-card {
		padding: 0.4rem;
		border-radius: 12px;
		background-color: #006191;
	}
	.impact-sub-card--1 {
		border-radius: 10px;
		margin-bottom: 0.5rem;
		background-image: linear-gradient(to right, rgba(0, 141, 15, 0.8), rgba(7, 255, 32, 0.8));
	}
	.impact-sub-card--2 {
		border-radius: 10px;
		background-image: linear-gradient(to right, rgba(220, 113, 35, 0.8), rgba(255, 162, 0, 0.8));
	}
	.status-card1 {
		background-color: #fff;
		padding: 15px 10px;
		box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
		z-index: 2;
		margin-bottom: 5px;
	}
	.status-card2 {
		margin-top: 5px;
		background-color: #ff9c02;
		padding: 15px 10px;
		box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
		z-index: 1;
	}
	.status-card3 {
		background-color: #fff;
		padding: 15px 10px;
		box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
		z-index: 2;
		margin-bottom: 5px;
		color: #018bde;
	}
	.status-card4 {
		margin-top: 5px;
		background-color: #018bde;
		padding: 15px 10px;
		box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
		z-index: 1;
	}
	.show-btn {
		background-color: #fff;
		border-radius: 14px;
	}
	.impact-title {
		margin-top: 20px;
		font-size: 12px;
		color: #fff;
	}
	.card-wrapper {
		display:flex;
		flex-direction:row;
		width:100%;
	}
`;

const Profile = ({ user, updateUserData, getUserData }) => {
	const [count, setCount] = useState(null);
	const [paymentList, setPaymentList] = useState([]);
	const [loading, setLoading] = useState(false)

	useEffect(() => { 
			(async () => {
				try {
					setLoading(true)
					const response = await fetchWrapper.get('property/get_energy_property_list');
					setCount(response);
					setLoading(false)
				} catch (e) {
					console.log(e, "thisis error");
				}
			})();
		}, []);

	useEffect(() => {
		(async () => {
			try {
				const response = await fetchWrapper.get('payment/list_pyment_method');
				console.log(response, 'list payment');
				setPaymentList(response);
			} catch (e) {
				console.log(e);
			}
		})(); 
	}, []);

	return (
		<Main>
			<TopBar>
				{loading && <Loader/>}
				<div css={profile} className="position-relative">
					<Row className="profile-section">
						<Col md={2}>
							<div className="position-relative">
								<div>
									<img
										src={(user && user.picture) || '/images/3826549.jpg'}
										alt="logo"
										className="profile-img"
									/> 
								</div>
							</div>
						</Col>
						<Col md={4}>
							<div className="font-weight-bold">
								{user && (
									<div className="d-flex flex-row ">
										<h4 className="font-weight-bold mr-4">
											{user.first_name} {user.last_name}
										</h4>
										<EditProfile
											user={user}
											updateUserData={updateUserData}
											getUserData={getUserData}
										/>
									</div>
								)}
							</div>
							<Row>
								<Col md="4">
									<p className="font-weight-bold">Email</p>
								</Col>
								<Col md="8">
									<p> {user && user.email}</p>
								</Col>
								<Col md="4">
									<p className="font-weight-bold">Phone</p>
								</Col>
								<Col md="8">
									<p>{user && user.phone}</p>
								</Col>
								<Col md="4">
									<p className="font-weight-bold mb-0">Property</p>
								</Col>
								<Col md="8">
									<p className="d-flex align-items-center">
										{count && (count.properties || 0)}
										<Link to="property">
											<Button className="ml-2 show-btn" style={{ fontSize: '10px' }}>
												Show all your property
											</Button>
										</Link>
									</p>
								</Col>
								{user && user.is_seller && (
									<Fragment>
										<Col md="4">
											<p className="font-weight-bold mb-0">Energy</p>
										</Col>
										<Col md="8">
											<p className="d-flex align-items-center">
												{count && (count.energies || 0)}
												<Link to="/profile/energy-list">
													<Button className="ml-2 show-btn" style={{ fontSize: '10px' }}>
														Show all your energy
													</Button>
												</Link>
											</p>
										</Col>
									</Fragment>
								)}
							</Row>
						</Col>

						<Col md="3">
							<h6 style={{ color: '#006191' }}>Status</h6>
							<div className="status-cards d-flex">
								<div className="status-card1">
									<h5 className="text-secondary mb-0">300KwH</h5>
								</div>
								<div className="status-card2">
									<p className="text-white mb-0">Energy Selling</p>
								</div>
							</div>
							<div className="status-cards d-flex mt-3">
								<div className="status-card3">
									<h5 className="mb-0">405KwH</h5>
								</div>
								<div className="status-card4">
									<p className="text-white mb-0">Energy Buying</p>
								</div>
							</div>
						</Col>
						<Col md="3">
							<h6 style={{ color: '#006191' }}>Impact</h6>
							<Card className="impact-card">
								<Card className="impact-sub-card--1">
									<div className="d-flex justify-content-between p-2">
										<div className="text-bottom">
											<p className="mb-0 impact-title">Trees saved</p>
											<h5 className="mb-0 text-white">15 Trees</h5>
										</div>
										<div>
											<FaTree size="60" color="#fff" />
										</div>
									</div>
								</Card>
								<Card className="impact-sub-card--2">
									<div className="d-flex justify-content-between p-2">
										<div className="text-bottom">
											<p className="mb-0 impact-title">Co2 Emmission</p>
											<h5 className="mb-0 text-white">251 gigaton</h5>
										</div>
										<div>
											<WiSmoke size="60" color="#fbfbfb" />
										</div>
									</div>
								</Card>
							</Card>
						</Col>
					</Row>
					{/* button and modal to add card */}
					<AddCard/>
					<div className="d-flex flex-row mt-2">
						{paymentList.length > 0 &&
							paymentList.map((card) => 
								<SavedPayment key={card.id} card={card}/> 
							)}
						<TransferEarning />	
					</div>
					<Timeline />
				</div>
			</TopBar>
		</Main>
	);
};

const mapStateToProps = (state) => ({
	user: state.user.user,
});

const mapDispatchToProps = { updateUserData, getUserData };

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
