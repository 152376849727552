/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Card, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Main from '../../Layouts/Main';
import TopBar from '../../Layouts/TopBar/TopBar';

const checkoutForm = css`
	width: 80%;
	.card {
		width: 8rem;
		border-radius: 9px;
		font-size: 0.8rem;
		text-align: center;
		background-color: #006191;
		color: #ffffff;
		line-height: 1;
		padding: 0.4rem;
		cursor: pointer;
	}
	.payment-img {
		width: 15rem;
		height: 10rem;
	}
`;

const padding = css`
	padding: 1.81rem 2rem 1.81rem 2rem;
`;

function SavedPayment({ user }) {
	return (
		<Main>
			<TopBar>
				<Container className="d-flex justify-content-center mt-2">
					<Card css={checkoutForm}>
						<div css={padding} className="position-relative ">
							<div className="d-flex justify-content-between">
								<p className="font-weight-bold ">Saved payment method</p>
								<Link to="/cart/checkout">
									<Card>
										<p className="mb-0">New</p>
										<p className="mb-0">payment method</p>
									</Card>
								</Link>
							</div>
							<div className="py-3 px-2 text-center">
								<Link to="/cart/checkout">
									<img src="/images/payment.jpg" className="payment-img" alt="payment" />
								</Link>
							</div>
						</div>
					</Card>
				</Container>
			</TopBar>
		</Main>
	);
}
const mapStateToProps = (state) => ({
	user: state.user.user,
});
export default connect(mapStateToProps)(SavedPayment);
