import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import styled from '@emotion/styled';
import { fetchWrapper } from '../../_helpers';
import { useDispatch } from 'react-redux';
// toasts 
import { toast } from 'react-toastify';

const Label2faModal = styled.label`
	font-size: ${(props) => (props.medium ? '16px' : '14px')};
	color: gray;
	margin-top: 20px;
`;

const Disable2fa = ({ openConfirmAlert, setOpenConfirmAlert, setTwoFa, user }) => {
	const dispatch = useDispatch();
	const {handleSubmit} = useForm({
		mode: 'onBlur',
	});

	const handleDisable2fa = async (data) => {
		const updatedUser = { email: user.email };
		try {
			const response = await fetchWrapper.put('auth/users/two_factor/', updatedUser, true);
			if (response) {
				dispatch({ type: 'SET_USER', payload: {...user, has_2fa:false} });
				setOpenConfirmAlert(false);
				toast.success('Two Factor Authentication Disabled');
				setTwoFa(false)	
			}
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<Modal show={openConfirmAlert} onHide={() => setOpenConfirmAlert(false)} className="p-4">
			<Modal.Dialog style={{ width: '550px', margin: '0' }}>
				<div className="p-4">
					<Form onSubmit={handleSubmit(handleDisable2fa)}>
						<Label2faModal medium className="text-danger font-bold text-center">
							<strong>Are you sure you want to disable the Two Factor Authentication?</strong>
						</Label2faModal>
						<Label2faModal className="text-center">
							{' '}
							Doing this will no longer require an additional security code besides your email address and
							password while you sign in to p2p or link a new computer, phone or tablet.{' '}
						</Label2faModal>
						<div className="d-flex flex-row justify-content-center my-4">
							<Button type="submit" variant="secondary" className="mr-4 w-100">
								Confirm
							</Button>
							<Button variant="primary" className="w-100" onClick={() => setOpenConfirmAlert(false)}>
								Cancel
							</Button>
						</div>
					</Form>
				</div>
			</Modal.Dialog>
		</Modal>
	);
};

export default Disable2fa;
