/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { connect } from 'react-redux';
import { IconContext } from 'react-icons';
import { FaFacebookSquare, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Main from '../../Layouts/Main';
import TopBar from '../../Layouts/TopBar/TopBar';

const successStyle = css`
	text-align: center;
	.icon {
		margin: 0rem 0.5rem;
		color: gray;
	}
	.facebook:hover {
		color: #3b5998;
	}
	.instagram-icon:hover > .instagram {
		fill: url('#myGradient');
	}

	.linkedin:hover {
		color: #0e76a8;
	}
	.dark-bold {
		color: #006191;
		font-weight: 900;
	}
	.ligher-bold {
		color: #006191;
		font-weight: 700;
	}
`;

const bigButtons = css`
	margin: 1rem;
	font-size: 1rem;
	width: 40%;
	height: 2.5rem;
	border-radius: 10px;
	letter-spacing: normal;
	font-weight: 600;
	background-color: #e97724;
	color: white;
`;

const CheckoutSuccess = () => {
	return (
		<Main>
			<TopBar>
				<div className="h-100" css={successStyle}>
					<div>
						<img src="/images/3826549.jpg" style={{ height: '20rem', width: '18rem' }} alt="logo"></img>
					</div>
					<p className="m-0 dark-bold">Congratulations!</p>
					<p className="ligher-bold m-0">You saved 50 Trees</p>
					<p className="m-0">Thank you for your order</p>
					<div className="mt-3">
						<p className="small m-0">Help others to save more trees</p>
						<div className="d-flex justify-content-center">
							<IconContext.Provider value={{ size: '32', className: 'icon' }}>
								<FaFacebookSquare className="facebook" />
								<svg width="32" height="32" className="instagram-icon">
									<defs>
										<linearGradient id="myGradient" gradientTransform="rotate(45)">
											<stop offset="16%" stopColor="#405de6" />
											<stop offset="32%" stopColor="#5851db" />
											<stop offset="48%" stopColor="#833ab4" />
											<stop offset="64%" stopColor="#c13584" />
											<stop offset="80%" stopColor="#e1306c" />
											<stop offset="96%" stopColor="#fd1d1d" />
										</linearGradient>
									</defs>

									<FaInstagram className="instagram" />
								</svg>
								<FaLinkedin className="linkedin" />
							</IconContext.Provider>
						</div>
						<Link to="/billing">
							<Button variant="secondary" type="submit" css={bigButtons}>
								Return to Billings
							</Button>
						</Link>
					</div>
				</div>
			</TopBar>
		</Main>
	);
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutSuccess);
