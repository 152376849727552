/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { connect } from 'react-redux';
import { NavLink, Link } from 'react-router-dom';
import { IconContext } from 'react-icons';

import { logoutUser } from '../../_redux/actions/ActionAuthentication';
import { FaRegUser, FaRegMoneyBillAlt } from 'react-icons/fa';
import { AiOutlineSetting, AiOutlineLogout, AiOutlineHome } from 'react-icons/ai';
import { BiCoinStack, BiCart } from 'react-icons/bi';
import { VscKey } from 'react-icons/vsc';

const sidebar = css`
	width: 14rem;
	margin: 0px;
	padding: 0px;
	overflow: hidden;
	height: 100vh;
	position: fixed;
	z-index: 1;
	.small-logo-image {
		display: none;
	}
	.big-logo-image {
		width: 10rem;
		height: 4rem;
	}
	.costume-sidebar {
		margin-top: 1.2rem;
		width: 100%;
	}

	.costume-nav-links {
		font-size: 1.2rem;
		display: block;
		margin: 0 3px 0 3px;
		padding: 0.5rem;
		border-radius: 8px;
		color: black;
		text-decoration: none;
		color: #333;
	}
	.nav-name {
		font-weight: 300 !important;
		font-size: 16px;
	}
	.selected {
		border-radius: 8px;
		background-color: #006191;
		color: #fff !important;
	}
	.selected .nav-name {
		color: #fff;
	}
	.global-class-name {
		font-size: 1rem;
		margin: 0 0.5rem;
	}
	@media (max-width: 1200px) {
		width: 3rem;
		.nav-name {
			display: none;
		}
		.big-logo-image {
			display: none;
		}
		.small-logo-image {
			display: flex;
			height: 2rem;
			width: 2rem;
			margin: auto;
		}
	}
	.cart-counter{
		height:18px;
		width:18px;
		border-radius:9px;
		color:white;
		font-size:12px;
		background-color:red;
	}
`;

const Sidebar = ({ logoutUser, cartCounter }) => {
	return (
		<div css={sidebar} className="p-0  m-0 overflow-hidden d-flex flex-column justify-content-between">
			<IconContext.Provider value={{ className: 'global-class-name' }}>
				<div>
					<div className="text-center mt-2 ">
						<img className="big-logo-image" src="/images/p2p.png" alt="logo" />
						<img className="small-logo-image" src="/images/p2plogo.png" alt="logo" />
					</div>
					<div className="d-flex flex-column justify-content-between">
						<div className="costume-sidebar">
							<NavLink exact activeClassName="selected" className="costume-nav-links" to="/">
								<AiOutlineHome /> <span className="nav-name">Home</span>
							</NavLink>

							<NavLink activeClassName="selected" className="costume-nav-links" to="/market">
								<BiCoinStack /> <span className="nav-name">Marketplace</span>
							</NavLink>

							<NavLink activeClassName="selected" className="costume-nav-links d-flex flex-row align-items-center" to="/cart">
								<BiCart /> 
								<div className="d-flex flex-row align-items-center">
								<span className="nav-name mr-2">
									Cart 
								</span>
								{cartCounter > 0 && <div className="cart-counter">
									<div className="d-flex flex-row justify-content-center align-items-center">
										{cartCounter}
									</div>
								</div>}
								</div>
							</NavLink>

							<NavLink activeClassName="selected" className="costume-nav-links" to="/profile">
								<FaRegUser /> <span className="nav-name">Profile</span>
							</NavLink>

							<NavLink activeClassName="selected" className="costume-nav-links" to="/property">
								<VscKey /> <span className="nav-name">Property</span>
							</NavLink>

							<NavLink activeClassName="selected" className="costume-nav-links" to="/billing">
								<FaRegMoneyBillAlt /> <span className="nav-name">Billing</span>
							</NavLink>
						</div>
					</div>
				</div>
				<div className="mb-3">
					<NavLink exact activeClassName="selected" className="costume-nav-links" to="/setting">
						<AiOutlineSetting /> <span className="nav-name">Settings</span>
					</NavLink>
					<Link to="/" className="costume-nav-links" onClick={() => logoutUser()}>
						<AiOutlineLogout /> <span className="nav-name">Logout</span>
					</Link>
				</div>
			</IconContext.Provider>
		</div>
	);
};

const mapStateToProps = (state) => ({
	cartCounter : state.market.cartCounter,
});

const mapDispatchToProps = { logoutUser };

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
