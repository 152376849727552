/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useEffect, useState, Fragment } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col, Container } from 'react-bootstrap';

// icons
import { FaRegCalendarAlt, FaChartArea, FaChartLine } from 'react-icons/fa';
import { MdLocationOn } from 'react-icons/md';
import { AiFillThunderbolt } from 'react-icons/ai';
import { GiRollingEnergy } from 'react-icons/gi';

import Main from '../../Layouts/Main';
import { getAllProducts } from '../../_redux/actions/ActionMarket';
import { getProperty, getUserData } from '../../_redux/actions/ActionUser';
import GeoLocation from './GeoLocation';
import HomeBottomHalf from './HomeBottomHalf';
import { fetchWrapper } from '../../_helpers';
import TopBar from '../../Layouts/TopBar/TopBar';
import Loader from '../../Layouts/Loader/Loader';
// import {addressFromLatLong} from '../../_helpers/addressFromLatLong';


const homeStyle = css`
	
	
	.homeSection {
		background-color: var(--primary);
	}
	.welcome-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 290px;
		width: 100%;
		border-radius: 10px;
		box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
		background-image: linear-gradient(249deg, #4ba1cb, #011941);
		padding: 20px;
		position: relative;
	}
	.widgets-item {
		display: flex;
		flex-direction: row;
		justify-content: start;
		align-items: center;
		font-size: 16px;
		font-weight: lighter;
		color: white;
		.icon {
			margin-right: 10px;
			margin-bottom: 5px;
			font-size: 18px;
		}
	}
	.user-name {
		font-size: 20px;
		font-weight: lighter;
	}
	.weather {
		font-size: 16px;
		color: white;
		font-weight: lighter;
		.icon {
			font-size: 30px;
		}
	}

	.user-properties-wrapper {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		align-items: flex-end;
		width: 100%;
		color: white;

		.user-properties {
			height: 150px;
			width: 30%;
			border-radius: 10px;
			box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
			background-color: #006090;
			padding: 10px;
		}
	}
	.avatar {
		border-radius: 50%;
		height: 60px;
		width: 60px;
	}

	.map-wrapper {
		border-radius: 10px;
		box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
		background-color: #4ba1cb;
		height: 290px;
		padding: 10px;
		width: 100%;
		color: #fff;
	}

	.temperature {
		font-weight: normal;
		font-size: 24px;
	}
	.primary-wrapper {
		border-radius: 10px;
		box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
		background-color: #4ba1cb;
		padding: 10px;
	}
	.secondary-wrapper {
		border-radius: 10px;
		border: solid 1px #707070;
		background-color: #011941;
		padding: 10px;
		color: #fff;
	}
	.dark-wrapper {
		border-radius: 10px;
		box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
		background-color: #006191;
		padding: 10px;
	}
	.tips-description {
		color: #b4d7fe;
		float: left;
		font-size: 12px;
	}
	.tips-img {
		width: 120px;
		border-radius: 10px;
	}
	.source-img {
		width: 40px;
		height: 40px;
		object-fit: cover;
		border-radius: 8px;
	}
	.supplier-single {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 20px;
	}
	.market-btn {
		background-color: #006191;
		color: #fff;
		padding: 10px 20px;
		border: 0;
		border-radius: 8px;
		width: 100%;
	}

	select,
	select:focus {
		-webkit-appearance: none;
		appearance: none;
		-moz-appearance: none;
		outline: none;
		border-top: none;
		border-right: none;
		border-left: none;
		border-bottom: none;
		border-radius: 0;
		color: white;
		background-color: transparent !important;
		background-image: url('/images/dropdown.svg');
		background-repeat: no-repeat !important;
		background-position: right 10px top 50%;
		background-size: 10px;
	}
	option {
		background: black;
	}

	.progress {
		display: flex;
		height: 2.3rem;
		overflow: hidden;
		line-height: 0;
		font-size: 0.75rem;
		background-color: transparent;
		border-radius: 0;
	}
	.progress-bar {
		text-align: start;
		padding: 0 8px;
		font-size: 1.2rem;
		color: black;
	}
	.skyblue--1 > .progress-bar {
		background-color: #14b2ff;
	}
	.skyblue--2 > .progress-bar {
		background-color: #46c2ff;
	}
	.skyblue--3 > .progress-bar {
		background-color: #81d2fb;
	}
	.skyblue--4 > .progress-bar {
		background-color: #c1eaff;
	}
	.skyblue--5 > .progress-bar {
		background-color: #c1eaff;
	}
`;

const Home = ({ getAllProducts, getProperty, user,us, position, getUserData }) => {
	const [userType, setUserType] = useState();
	const [weather, setWeather] = useState([]);
	const [count, setCount] = useState(null);
	const [loading, setLoading] = useState(false);
	const [location, setLocation] = useState();

	useEffect(() => {
		if (user) {
			getUserData();
			getAllProducts();
			getProperty(user.email);
			setUserType(
				user.is_buyer && user.is_seller ? 'Prosumer' : user.is_seller ? 'seller' : user.is_buyer ? 'buyer' : ''
			);
			const URL = 'https://api.openweathermap.org/data/2.5/weather';
			const API_KEY = 'f33a484cf794d08d0148764789aaba32';

			const fetchWeather = async () => {
				const response = await fetch(`${URL}?q=${'massachusetts'}&units=${'metric'}&APPID=${API_KEY}`, {
					method: 'GET',
				});
				const data = await response.json();
				setWeather(data);
			};
			fetchWeather();
		}
	}, [getAllProducts, getProperty, getUserData]);

	useEffect(() => {
		(async () => {
			try {
				setLoading(true)
				const response = await fetchWrapper.get('property/get_energy_property_list');
				setCount(response);
				setLoading(false)
			} catch (e) {
				console.log(e, 'err');
			}
		})();
	}, []);

	useEffect(() => {
		(async () => {
			if(user){
				try {
					await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.latitude},${position.longitude}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`, {
						method: 'GET',
					}).then((result) => result.json()).then((data)=>{
						console.log(data.results[1].address_components[0], "here")
						const address = data.results[1].address_components[0].long_name + ", " + data.results[1].address_components[1].short_name
						setLocation(address)
					});
			
					} catch (e) {
						console.log(e, 'err');
					}
			}
		})();
	}, [])

	const d = new Date().toString().replace(/\S+\s(\S+)\s(\d+)\s(\d+)\s.*/, '$2, $1 $3');

	return (
		<Main>
			<TopBar>
				{loading && <Loader />}
				<div css={homeStyle}>
					<div className="homeSection position-relative">
						<Container>
							<Row className="px-3 pt-3">
								<Col md={6} className="mb-2 p-0 pr-1">
									<div className="welcome-wrapper">
										<div className="d-flex flex-row justify-content-between">
											<div className="user d-flex flex-row justify-content-start align-items-center">
												<img
													src={(user && user.picture) || '/images/3826549.jpg'}
													alt="logo"
													className="avatar"
												/>
												<span className="user-name text-white px-2">
													Hi {user && `${user.first_name} ${user.last_name}`}
												</span>
											</div>
											<div className="widgets d-flex flex-row justify-content between align-items-center">
												<div className="d-flex flex-column justify-content-center align-items-start mr-4">
													<div className="widgets-item">
														<FaRegCalendarAlt className="icon" />
														<div>{d}</div>
													</div>
													<div className="widgets-item">
														<MdLocationOn className="icon" />
														<div>{location}</div>
													</div>
												</div>
												<div className="weather d-flex flex-column justify-content-center align-items-center">
													{weather.main && (
														<Fragment>
															<img
																src={`https://openweathermap.org/img/wn/${weather.weather[0].icon}@2x.png`}
																alt={weather.weather[0].description}
																style={{ width: '50px', height: '50px' }}
															/>
															<span className="temperature">
																{Math.round(weather.main.temp)}
																<sup>&deg;</sup>C
															</span>
														</Fragment>
													)}
												</div>
											</div>
										</div>
										<div className="user-properties-wrapper">
											<div className="user-properties">
												<div className="d-flex flex-column align-items-center justify-content-start mt-4">
													<h1 className="mb-0">{count && count.properties}</h1>
													<span className="d-flex flex-column align-items-end">
														<p className="mb-0">Total Property</p>
													</span>
												</div>
											</div>
											<div className="user-properties">
												<div className="d-flex flex-column justify-content-center align-items-center mb-1">
													<p style={{ fontSize: '12px' }}>
														<AiFillThunderbolt color={'#fff12c'} />{' '}
														{(userType === 'seller' || userType === 'Prosumer') === true
															? 'No of Energy Sold'
															: 'No of Energy Bought'}
													</p>
													<FaChartArea size={40} />
													<h5 className="mt-2 mb-0">
														{(userType === 'seller' || userType === 'Prosumer') === true
															? count && count.energy_sold
															: count && count.energy_brought}
													</h5>
												</div>
											</div>
											<div className="user-properties">
												<div className="d-flex flex-column justify-content-center align-items-center mb-1">
													<p style={{ fontSize: '12px' }} className="d-flex flex-row">
														<GiRollingEnergy
															color={'#fff12c'}
															style={{ margin: '3px 3px auto 0px' }}
														/>{' '}
														<div>
															{userType === 'buyer'
																? 'Energy Bought'
																: userType === 'Prosumer'
																? 'No of Energy Bought'
																: 'Total Customer'}
														</div>
													</p>
													<FaChartLine size={40} />
													<h5 className="mt-2 mb-0">
														{userType === 'buyer'
															? '1024 KWH'
															: userType === 'Prosumer'
															? count && count.energy_brought
															: count && count.customers}
													</h5>
												</div>
											</div>
										</div>
									</div>
								</Col>
								<Col md={6} className="mb-2 p-0 pl-1">
									<div className="map-wrapper">
										<div className="row m-0">
											<div className="col-md-12 p-0 ">
												<div style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
													<GeoLocation />
												</div>
											</div>
										</div>
									</div>
								</Col>
							</Row>
							<HomeBottomHalf />
						</Container>
					</div>
				</div>
			</TopBar>
		</Main>
	);
};

Home.propTypes = {};

const mapStateToProps = (state) => ({
	user: state.user.user,
	property: state.user.property,
	market: state.market.market,
	position: state.user.position?.coords,
});

const mapDispatchToProps = { getAllProducts, getProperty, getUserData };

export default connect(mapStateToProps, mapDispatchToProps)(Home);
