/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import Main from '../../Layouts/Main';
import TopBar from '../../Layouts/TopBar/TopBar';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { FaStar } from 'react-icons/fa';
import { fetchWrapper } from '../../_helpers';
import { postSubsciption } from '../../_redux/actions/ActionPayment';
import Success from '../../Layouts/Success';
import AddToCartBox from './AddToCartBox';

const Details = styled.table`	
	height:220px;
	margin-bottom:20px;
	td{
		padding-left: 40px;
	}

`;

const detailStyle = css`
	padding:0px 20px;
	.single-product-img {
		width: 100%;
		height: 320px;
		object-fit: cover;
		border-radius: 16px;
	}
	.small-gallery {
		margin-top: 10px;
		display: flex;
	}
	.small-img {
		height: 80px;
		width: 80px;
		margin-right: 20px;
		border-radius: 8px;
	}
	.rating-wrapper {
		color: #cb8700;
		text-align: center;
	}
	.no-rating {
		${'' /* display: inline-block; */}
		border: 1px dotted #333;
		padding: 5px 10px;
		p {
			margin-bottom: 0;
			font-size: 0.785rem;
		}
	}
	.single-customer {
		border: 1px solid #2F486D;
		border-radius: 8px;
		.header {
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
			background-color: #fff;
			padding: 10px;
			display: flex;
			justify-content: center;
			align-items: center;
			.customer-img {
				height: 50px;
				width: 50px;
				border-radius: 50%;
			}
			h5 {
				font-size: 1rem;
				font-weight: bold;
				margin-bottom: 0;
				margin-left: 10px;
			}
		}
		.content {
			border-bottom-left-radius: 8px;
			border-bottom-right-radius: 8px;
			background-color: #cbe4ff;
			padding: 10px;
			padding: 20px 10px;
		}
	}
	.timeline {
		position: relative;
		max-width: 1200px;
		margin: 0 auto;
	}
	.timeline::after {
		content: '';
		position: absolute;
		width: 6px;
		background-color: white;
		top: 0;
		bottom: 0;
		left: 50%;
		margin-left: -3px;
	}
	.timeline-container {
		padding: 10px 40px;
		position: relative;
		background-color: inherit;
		width: 50%;
	}
	.timeline-container::after {
		content: '';
		position: absolute;
		width: 25px;
		height: 25px;
		right: -17px;
		background-color: white;
		border: 4px solid #ff9f55;
		top: 15px;
		border-radius: 50%;
		z-index: 1;
	}
	.left {
		left: 0;
	}
	.right {
		left: 50%;
	}
	.left::before {
		content: ' ';
		height: 0;
		position: absolute;
		top: 22px;
		width: 0;
		z-index: 1;
		right: 30px;
		border: medium solid white;
		border-width: 10px 0 10px 10px;
		border-color: transparent transparent transparent white;
	}
	.right::before {
		content: ' ';
		height: 0;
		position: absolute;
		top: 22px;
		width: 0;
		z-index: 1;
		left: 30px;
		border: medium solid white;
		border-width: 10px 10px 10px 0;
		border-color: transparent white transparent transparent;
	}
	.right::after {
		left: -16px;
	}
	.content {
		padding: 20px 30px;
		background-color: white;
		position: relative;
		border-radius: 6px;
	}
	.timeline-date {
		color: #ce4242;
	}
`;

const ProductDetail = ({ market, match, postSubsciption }) => {
	const id = match.params.id;
	const [product, setProduct] = useState(null);
	const [showModel, setShowModel] = useState(false);

	console.log('ProductDetails', product);

	useEffect(() => {
		const product = market.find((mar) => mar.id === id * 1);
		if (product) {
			setProduct(product);
		} else {
			const fetchProduct = async () => {
				try {
					setProduct(await fetchWrapper.get(`energy/energy-source/${id}`));
				} catch (e) {
					setProduct(null);
				}
			};
			fetchProduct();
		}
	}, [id, market]);

	const handleAddToCart = async (cart, property) => {
		const subsription = {
			...property,
			status: 'pending',
			energy_source_id: cart.id,
			auto_renewal: false,
			valid_from: '2020-11-24',
			valid_till: '2020-12-24',
		};
		var form_data = new FormData();

		for (var key in subsription) {
			console.log(key);
			form_data.append(key, subsription[key]);
		}
		if (await postSubsciption(form_data)) setShowModel(true);
	};

	return (
		<Main>
			<TopBar>
				<div css={detailStyle}>
					{product && (
						<div>
							<Row>
								<Col md={5}>
									<img
										src={product.picture ? product.picture : '/images/item1.jpg'}
										className="single-product-img"
										alt="item"
									/>
									{/* <div className="small-gallery">
										<img src="/images/item2.jpg" className="small-img" alt="gallery" />
										<img src="/images/item3.jpg" className="small-img" alt="gallery" />
										<img src="/images/item1.jpg" className="small-img" alt="gallery" />
										<img src="/images/item3.jpg" className="small-img" alt="gallery" />
									</div> */}
								</Col>
								<Col md={7}>
									<h3 className="mb-3">{product.name}</h3>
									<Details>
										<tbody>
											<tr>
												<th>Supplier</th><td>:  {product.supplier}</td>
											</tr>
											<tr>
												<th>Total customers</th><td>:  {product.customers} 4 </td>
											</tr>
											<tr>
												<th>Price</th> <td>: ${product.price_per_kwh} monthly / ${product.price_per_kwh} anually</td>
											</tr>
											<tr>
												<th>Percentage sold</th> <td>:  {product.sell_percent}%</td>
											</tr>
											<tr>
												<th>Product type</th> <td>:  {product.payment_type}</td>
											</tr>
											<tr>
												<th>Distance</th> <td>: 15km</td>
											</tr>
											<tr>
												<th>Payment types</th> <td>:  {product.type}Paypal, credit card</td>
											</tr>
										</tbody>
									</Details>

									{/* <Row>
										<Col md={3}>
											<p className="font-weight-bold">Supplier</p>
											<p className="font-weight-bold">Total customers</p>
											<p className="font-weight-bold">Price</p>
											<p className="font-weight-bold">Percentage sold</p>
											<p className="font-weight-bold">Product type</p>
											<p className="font-weight-bold">Distance</p>
											<p className="font-weight-bold">Payment types</p>
											
										</Col>
										<Col md={9}>
											{console.log(product)}
											<p>: {product.supplier}</p>
											<p>: {product.customers} 4 </p>
											<p>: ${product.price_per_kwh} monthly / ${product.price_per_kwh} anually</p>
											<p>: {product.sell_percent}%</p>
											<p>: {product.payment_type}</p>
											<p>: 15km</p>
											<p>: {product.type}Paypal, credit card</p>
										</Col>
									</Row> */}
									<AddToCartBox
										handleAddToCart={handleAddToCart}
										mar={product}
										buttonText="Add To Cart"
									/>
								</Col>
							</Row>
							<h5 className="mt-5">Customer detail:</h5>
							<Row>
								<Col md={4}>
									<div className="single-customer">
										<div className="header">
											<img src="/images/avatar.png" className="customer-img" alt="customer" />
											<h5>Sandra Barnett</h5>
										</div>
										<div className="content">
											<Row>
												<Col md={6}>
													<p className="text-right font-weight-bold">Payment</p>
													<p className="text-right font-weight-bold">Distance</p>
													<p className="text-right font-weight-bold">Used in</p>
													<p className="text-right font-weight-bold">Using since</p>
												</Col>
												<Col md={6}>
													<p>: $20 monthly</p>
													<p>: 15km away</p>
													<p>: Sandra's house</p>
													<p>: 02/01/2020 </p>
												</Col>
												<Col md={12}>
													<div className="d-flex justify-content-center">
														<div className="rating-wrapper no-rating">
															<p>Not Rated</p>
														</div>
													</div>
												</Col>
											</Row>
										</div>
									</div>
								</Col>
								<Col md={4}>
									<div className="single-customer">
										<div className="header">
											<img src="/images/avatar.png" className="customer-img" alt="customer" />
											<h5>Sandra Barnett</h5>
										</div>
										<div className="content">
											<Row>
												<Col md={6}>
													<p className="text-right font-weight-bold">Payment</p>
													<p className="text-right font-weight-bold">Distance</p>
													<p className="text-right font-weight-bold">Used in</p>
													<p className="text-right font-weight-bold">Using since</p>
												</Col>
												<Col md={6}>
													<p>: $20 monthly</p>
													<p>: 15km away</p>
													<p>: Sandra's house</p>
													<p>: 02/01/2020</p>
												</Col>
												<Col md={12}>
													<div className="rating-wrapper">
														<FaStar />
														<FaStar />
														<FaStar />
														<FaStar />
														<FaStar />
													</div>
												</Col>
											</Row>
										</div>
									</div>
								</Col>
								<Col md={4}>
									<div className="single-customer">
										<div className="header">
											<img src="/images/avatar.png" className="customer-img" alt="customer" />
											<h5>Sandra Barnett</h5>
										</div>
										<div className="content">
											<Row>
												<Col md={6}>
													<p className="text-right font-weight-bold">Payment</p>
													<p className="text-right font-weight-bold">Distance</p>
													<p className="text-right font-weight-bold">Used in</p>
													<p className="text-right font-weight-bold">Using since</p>
												</Col>
												<Col md={6}>
													<p>: $20 monthly</p>
													<p>: 15km away</p>
													<p>: Sandra's house</p>
													<p>: 02/01/2020</p>
												</Col>
												<Col md={12}>
													<div className="rating-wrapper">
														<FaStar />
														<FaStar />
														<FaStar />
														<FaStar />
														<FaStar />
													</div>
												</Col>
											</Row>
										</div>
									</div>
								</Col>
							</Row>
							{/* <Row className="mt-5">
								<Col md="12">
									<h4 className="mb-4">Your Timeline</h4>
									<div class="timeline">
										<div class="timeline-container left">
											<div class="content text-right">
												<h3 className="timeline-date">2020-10-24</h3>
												<h5 className="timeline-task">Posted New Energy</h5>
												<p className="timeline-details">Energy Name: Solar Energy</p>
											</div>
										</div>
										<div class="timeline-container right">
											<div class="content">
												<h3 className="timeline-date">2020-09-12</h3>
												<h5 className="timeline-task">Started Using Energy of Mathew</h5>
												<p className="timeline-details">Energy Name: Solar Energy</p>
											</div>
										</div>
										<div class="timeline-container left">
											<div class="content text-right">
												<h3 className="timeline-date">2020-08-06</h3>
												<h5 className="timeline-task">Subscribed New Energy</h5>
												<p className="timeline-details">Energy Name: Solar Energy</p>
											</div>
										</div>
										<div class="timeline-container right">
											<div class="content">
												<h3 className="timeline-date">2020-07-29</h3>
												<h5 className="timeline-task">Posted New Energy</h5>
												<p className="timeline-details">Energy Name: Solar Energy</p>
											</div>
										</div>
										<div class="timeline-container left">
											<div class="content text-right">
												<h3 className="timeline-date">2020-07-02</h3>
												<h5 className="timeline-task">Posted New Energy</h5>
												<p className="timeline-details">Energy Name: Solar Energy</p>
											</div>
										</div>
										<div class="timeline-container right">
											<div class="content">
												<h3 className="timeline-date">2020-06-23</h3>
												<h5 className="timeline-task">Posted New Energy</h5>
												<p className="timeline-details">Energy Name: Solar Energy</p>
											</div>
										</div>
									</div>
								</Col>
							</Row> */}
						</div>
					)}
					{showModel && (
						<Success
							show={showModel}
							onHide={() => setShowModel(false)}
							setShowModel={setShowModel}
							forLink="/cart"
							buttonText="Go to cart"
							text="Added to cart successfully"
						/>
					)}
				</div>
			</TopBar>
		</Main>
	);
};

const mapStateToProps = (state) => ({
	user: state.user.user,
	market: state.market.market,
});

const mapDispatchToProps = { postSubsciption };

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail);
