import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

export const Admin = () => {
	return <div>THis is Admin</div>;
};

Admin.propTypes = {
	prop: PropTypes,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
