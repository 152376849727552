/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useState, Fragment, useEffect } from 'react';
import { Card, Form, Container, Row, Col, Button, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import ImageUpload from '../../Layouts/ImageUpload/ImageUpload';
import Main from '../../Layouts/Main';
import TopBar from '../../Layouts/TopBar/TopBar';
import { postProperty, editProperty } from '../../_redux/actions/ActionUser';
import { yupResolver } from '@hookform/resolvers/yup';
import { propertyFormValidation } from '../../_helpers';
import Success from '../../Layouts/Success';
import { getAllEnergyTypes } from '../../_redux/actions/ActionMarket';
// toasts
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

const propertyForm = css`
	width: 80%;
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		-webkit-background-clip: text;
		background-clip: text;
	}
	input,
	input:focus,
	textarea {
		border-top-style: hidden;
		border-right-style: hidden;
		border-left-style: hidden;
		border-bottom-style: groove;
		background-color: transparent !important;
		font-size: 1rem;
		outline: none !important;
		border: 1px 0px 0px 0px solid black;
	}
`;

const bigButtons = css`
	margin: 1rem;
	font-size: 1.3rem;
	width: 50%;
	height: 3.5rem;
	border-radius: 10px;
	letter-spacing: normal;
	font-weight: 700;
	background-color: #e97724;
	color: white;
`;

const padding = css`
	padding: 1.81rem 2rem 1.81rem 2rem;
`;

function PropertyForm({ user, postProperty, position, data, type, editProperty, getAllEnergyTypes, energyTypes }) {
	const [image, setImage] = useState(null);
	const [showModel, setShowModel] = useState(false);
	const [file, setFile] = useState(null);
	const [loading, setLoading] = useState(false);
	const history = useHistory();

	useEffect(() => {
		getAllEnergyTypes();
	}, [getAllEnergyTypes]);

	const { register, handleSubmit, errors } = useForm({
		mode: 'onBlur',
		resolver: yupResolver(propertyFormValidation),
	});

	const onSubmit = async (newProperty) => {
		console.log(newProperty)
		setLoading(true);
		console.log(newProperty)
		const tempData = {
			name: newProperty.name,
			buy_percent: newProperty.buy_percent,
			prosumer_email: user.email,
			customer_no: newProperty.customer_no,
			property_type: type ? type : null,
			name_on_bill: newProperty.name_on_bill,
			meter_box_id: newProperty.meter_box_id,
			avg_energy_consumption: newProperty.avg_energy_consumption,
			propertyimage: image ? image : null,
			property_address: JSON.stringify([
				{
					address_line_one: newProperty.address1,
					address_line_two: newProperty.address2,
					city: newProperty.city,
					point: {
						lat: newProperty.latitude,
						lon: newProperty.longitude,
					},
					state: newProperty.state,
					zip_code: newProperty.zip_code,
				},
			]),
			electricitybill: newProperty.electricitybill[0] ? newProperty.electricitybill[0] : null,
		};

		console.log(tempData)
		var form_data = new FormData();

		if (newProperty?.preferred_energy?.length) {
			for (let index = 0; index < newProperty.preferred_energy.length; index++) {
				form_data.append(`preferred_energy`, newProperty.preferred_energy[index]);
			}
		}

		for (var key in tempData) {
			form_data.append(key, tempData[key]);
		}
		let res;
		
		if (data) {
			form_data.append('submit', 1);
			res = await editProperty(data.id, form_data);
			if(res){
				toast.success("Property Edied Successfully")
				history.push(`/property/`);
			}else{
				toast.error("something went wrong")
				history.push('/property/');
			}
		} else {
			res = await postProperty(form_data);
			if(res){
				toast.success("Property Created Successfully")
				history.push(`/property/`);
			}else{
				toast.error("something went wrong")
				history.push('/property/');
			}
		}

		// setLoading(false);
		// if (res) {
		// 	setShowModel(true);
		// }
	};

	const handleFile = async (e) => {
		const maxFileSize = 2 * 1024 * 1024;
		const imageFile = e.target.files[0];
		if (imageFile && imageFile.size < maxFileSize) {
			setFile(imageFile);
		} else if (imageFile) {
			toast.error('Image Must be less than 2 mb in size!');
		}
	};

	return (
		<Main>
			<TopBar>
				<Container className="d-flex justify-content-center mt-2">
					<Card css={propertyForm}>
						<div css={padding} className="position-relative ">
							<Form onSubmit={handleSubmit(onSubmit)} className="p-2">
								<p className="font-weight-bold ">Property Details</p>
								<Form.Group as={Row} controlId="formPlaintextSelects">
									<Form.Label className="text-right" column sm="4">
										Property Name *
									</Form.Label>
									<Col sm="8">
										<Form.Control
											type="text"
											name="name"
											isInvalid={errors.name}
											defaultValue={data && data.name}
											ref={register}
											placeholder="Enter the name of the property"
										/>

										{/* <Form.Text className="text-danger">{errors.name?.message}</Form.Text> */}
									</Col>
								</Form.Group>

								<Form.Group as={Row} controlId="formPlaintextBankName">
									<Form.Label className="text-right" column sm="4">
										Meter number *
									</Form.Label>
									<Col sm="8">
										<Form.Control
											type="number"
											name="meter_box_id"
											isInvalid={errors.meter_box_id}
											ref={register}
											defaultValue={data && data.meter_box_id}
											placeholder="Enter Meter Number"
										/>

										{/* <Form.Text className="text-danger">{errors.meter_box_id?.message}</Form.Text> */}
									</Col>
								</Form.Group>

								<Form.Group as={Row} controlId="formPlaintextRoutingNumber">
									<Form.Label className="text-right" column sm="4">
										Customer number *
									</Form.Label>
									<Col sm="8">
										<Form.Control
											type="number"
											name="customer_no"
											isInvalid={errors.customer_no}
											ref={register}
											defaultValue={data && data.customer_no}
											placeholder="Enter Customer Number"
										/>

										{/* <Form.Text className="text-danger">{errors.customer_no?.message}</Form.Text> */}
									</Col>
								</Form.Group>
								<Form.Group as={Row} controlId="formPlaintextAccountNumber">
									<Form.Label className="text-right" column sm="4">
										Average Consumption *
									</Form.Label>
									<Col sm="8">
										<Form.Control
											type="number"
											name="avg_energy_consumption"
											isInvalid={errors.avg_energy_consumption}
											ref={register}
											defaultValue={data && data.avg_energy_consumption}
											placeholder="Enter energy consumption"
										/>

										{/* <Form.Text className="text-danger">
											{errors.avg_energy_consumption?.message}
										</Form.Text> */}
									</Col>
								</Form.Group>
								<hr className="bg-primary my-4" />
								<p className="font-weight-bold ">Add Image of your property *</p>
								<div className="d-flex flex-column align-items-center">
									<div className="text-center">
										<ImageUpload setImage={setImage} />
									</div>
									{data && data.picture && !image && (
										<div>
											<img
												src={data.picture}
												style={{
													width: '12rem',
													height: '12rem',
													objectFit: 'cover',
													objectPosition: 'center',
												}}
												alt="avatar"
											/>
										</div>
									)}
								</div>
								<hr className="bg-primary my-4" />

								<p className="font-weight-bold ">Electricity Bill *</p>
								<div className="d-flex flex-column align-items-center">
								<label className="d-flex flex-column align-items-center">
									<input
										onChange={(e) => handleFile(e)}
										style={{ display: 'none' }}
										accept="image/x-png,image/webp,image/jpeg "
										type="file"
										name="electricitybill"
										ref={register}
									/>
									<div className="d-flex flex-column align-items-center">
										<p
											style={{
												padding: '.4rem 5rem',
												backgroundColor: '#e2e2e2',
											}}
										>
											Upload current electrical bill
										</p>
										{file && (
											<img
												src={URL.createObjectURL(file)}
												style={{
													width: '12rem',
													height: '12rem',
													objectFit: 'cover',
													objectPosition: 'center',
												}}
												alt="avatar"
											/>
										)}
											
									</div>
								</label>
								{data && data.electricity?.file && !file && (
									<div>
										<img
											src={data.electricity?.file}
											style={{
												width: '12rem',
												height: '12rem',
												objectFit: 'cover',
												objectPosition: 'center',
											}}
											alt="avatar"
										/>
									</div>
								)}
								</div>
								<hr className="bg-primary my-4" />
								<p className="font-weight-bold ">Property Address</p>

								<Fragment>
									<Form.Group as={Row} controlId="formPlaintextAccountNumber">
										<Form.Label className="text-right" column sm="4">
											Name on the bill *
										</Form.Label>
										<Col sm="6">
											<Form.Control
												type="text"
												name="name_on_bill"
												isInvalid={errors.name_on_bill}
												ref={register}
												defaultValue={data && data.name_on_bill}
												placeholder="Enter name of electric bill"
											/>

											{/* <Form.Text className="text-danger">{errors.address1?.message}</Form.Text> */}
										</Col>
									</Form.Group>
									<Form.Group as={Row} controlId="formPlaintextAccountNumber">
										<Form.Label className="text-right" column sm="4">
											Address 1 *
										</Form.Label>
										<Col sm="6">
											<Form.Control
												type="text"
												name="address1"
												isInvalid={errors.address1}
												ref={register}
												defaultValue={data && data.property_address?.address_line_one}
												placeholder="Enter your address 1"
											/>

											{/* <Form.Text className="text-danger">{errors.address1?.message}</Form.Text> */}
										</Col>
									</Form.Group>
									<Form.Group as={Row} controlId="formPlaintextAccountNumber">
										<Form.Label className="text-right" column sm="4">
											Address 2 *
										</Form.Label>
										<Col sm="6">
											<Form.Control
												type="text"
												name="address2"
												isInvalid={errors.address2}
												defaultValue={data && data.property_address?.address_line_two}
												ref={register}
												placeholder="Enter your address 2"
											/>

											{/* <Form.Text className="text-danger">{errors.address2?.message}</Form.Text> */}
										</Col>
									</Form.Group>

									<Form.Group as={Row} controlId="formPlaintextAccountNumber">
										<Form.Label className="text-right" column sm="4">
											Location (Coordinate)
										</Form.Label>
										<Col sm="3">
											<Form.Control
												type="number"
												name="latitude"
												step="any"
												defaultValue={
													data
														? data.property_address?.point?.lat
														: position && position.coords.latitude
												}
												isInvalid={errors.latitude}
												ref={register}
												placeholder="Enter latitide"
											/>
										</Col>
										<Col sm="3">
											<Form.Control
												type="number"
												name="longitude"
												step="any"
												defaultValue={
													data
														? data.property_address?.point?.lon
														: position && position.coords.longitude
												}
												isInvalid={errors.longitude}
												ref={register}
												placeholder="Enter longitude"
											/>
										</Col>

										{/* <Form.Text className="text-danger">{errors.address2?.message}</Form.Text> */}
									</Form.Group>

									<Form.Group as={Row} controlId="formPlaintextAccountNumber">
										<Form.Label className="text-right" column sm="4">
											City *
										</Form.Label>
										<Col sm="6">
											<Form.Control
												type="text"
												name="city"
												isInvalid={errors.city}
												ref={register}
												defaultValue={data && data.property_address?.city}
												placeholder="Enter your city"
											/>

											{/* <Form.Text className="text-danger">{errors.city?.message}</Form.Text> */}
										</Col>
									</Form.Group>
									<Form.Group as={Row} controlId="formPlaintextAccountNumber">
										<Form.Label className="text-right" column sm="4">
											Choose your state *
										</Form.Label>
										<Col sm="6">
											<Form.Control
												as="select"
												type="text"
												name="state"
												isInvalid={errors.state}
												ref={register}
												placeholder="Enter your address 1"
												defaultValue={data && data.property_address?.state}
											>
												<option>massachusetts</option>
											</Form.Control>

											{/* <Form.Text className="text-danger">{errors.state?.message}</Form.Text> */}
										</Col>
									</Form.Group>
									<Form.Group as={Row} controlId="formPlaintextAccountNumber">
										<Form.Label className="text-right" column sm="4">
											Enter your Zip code *
										</Form.Label>
										<Col sm="6">
											<Form.Control
												type="text"
												name="zip_code"
												isInvalid={errors.zip_code}
												ref={register}
												defaultValue={data && data.property_address?.zip_code}
												placeholder="Zip code"
											/>

											{/* <Form.Text className="text-danger">{errors.zip_code?.message}</Form.Text> */}
										</Col>
									</Form.Group>
								</Fragment>

								<hr className="bg-primary my-4" />

								<p className="font-weight-bold ">Percent clean energy to buy</p>

								<Form.Group as={Row} controlId="formPlaintextAccountNumber">
									<Col sm={{ span: 4, offset: 2 }}>
										<Form.Label className="text-right" column>
											Clean energy percent *
										</Form.Label>
									</Col>

									<Col sm="2">
										<Form.Control
											as="select"
											type="text"
											name="buy_percent"
											isInvalid={errors.buy_percent}
											ref={register}
											placeholder="Enter your address 1"
											defaultValue={data && data.buy_percent}
										>
											<option value="20">20%</option>
										</Form.Control>

										{/* <Form.Text className="text-danger">{errors.buy_percent?.message}</Form.Text> */}
									</Col>
								</Form.Group>

								<hr className="bg-primary my-4" />

								<div className="d-flex">
									<p className="font-weight-bold mr-5">Preferred energy sources</p>
									<div className="ml-5">
										{energyTypes.length > 0 &&
											energyTypes.map((type) => (
												<Form.Group controlId="solarEnergy">
													<Form.Check
														type="checkbox"
														name="preferred_energy"
														isInvalid={errors.preferred_energy}
														defaultChecked={
															data &&
															data.preferred_energy &&
															data.preferred_energy.length &&
															data.preferred_energy.find((eng) => eng.id === type.id)
														}
														value={type.id}
														id={type.name}
														label={type.name}
														ref={register}
													/>
												</Form.Group>
											))}
									</div>
								</div>
								<hr className="bg-primary my-4" />
								<div className="d-flex justify-content-center">
									<Button variant="secondary" type="submit" css={bigButtons} disabled={loading}>
										{!loading ? (
											'Save Property'
										) : (
											<Spinner animation="border" role="status">
												<span className="sr-only">Loading...</span>
											</Spinner>
										)}
									</Button>
								</div>
							</Form>
						</div>
					</Card>
					{showModel && (
						<Success
							show={showModel}
							setShowModel={setShowModel}
							forLink="/property"
							buttonText="Property List"
							text={data ? 'Property Edited Successfully' : 'Added to property successfully'}
						/>
					)}
				</Container>
			</TopBar>
		</Main>
	);
}
const mapStateToProps = (state) => ({
	user: state.user.user,
	position: state.user.position,
	energyTypes: state.market.energyTypes,
});
export default connect(mapStateToProps, { postProperty, editProperty, getAllEnergyTypes })(PropertyForm);
