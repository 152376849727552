/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';

const successModal = css`
	background: #ffffff;
	.modal-content {
		border-radius: 12px;
		border: 1px solid #000;
	}
	.close-btn {
		position: absolute;
		top: 1rem;
		right: 1rem;
		font-size: 1.3rem;
		cursor: pointer;
	}
	.success-text {
		font-family: Roboto;
		font-size: 24px;
		font-weight: normal;
		font-style: normal;
		text-align: center;
		color: #191919;
	}
`;

const bigButtons = css`
	margin: 1rem;
	font-size: 1rem;
	width: 80%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;
	height: 3rem;
	border-radius: 10px;
	letter-spacing: normal;
	font-weight: 700;
	background-color: #006191;
	color: white;
`;

function Success(props) {
	return (
		<Modal
			show={props.show}
			onHide={props.onHide}
			css={successModal}
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Body className="p-3 position-relative">
				<span className="close-btn" onClick={() => props.setShowModel(false)}>
					X
				</span>
				<div className="text-center mb-4">
					<IoMdCheckmarkCircleOutline size="75" color="#17ab13" />
					<h4 className="success-text">{props.text}</h4>
				</div>
				<Link to={props.forLink}>
					<Button css={bigButtons}>{props.buttonText}</Button>
				</Link>
			</Modal.Body>
		</Modal>
	);
}
export default Success;
