/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useState, useEffect } from 'react';
import { getUserData } from '../../_redux/actions/ActionUser';
import { useForm } from 'react-hook-form';
import styled from '@emotion/styled';
import Main from '../../Layouts/Main';
import TopBar from '../../Layouts/TopBar/TopBar';
import { Link } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { Row, Col, Card, Button, Form, Modal } from 'react-bootstrap';
import { fetchWrapper } from '../../_helpers';
import Disable2fa from './Disable2fa';
// toasts
import { toast } from 'react-toastify';

const Label2faModal = styled.label`
	font-size: ${(props) => (props.medium ? '16px' : '14px')};
	color: gray;
	margin-top: 20px;
`;

const settingStyle = css`
	padding:0px 20px;
	.card {
		border-radius: 32px;
	}
	.card-body {
		padding: 0.75rem 1.5rem;
	}
	.single-card {
		margin-top: 30px;
	}
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p {
		margin-bottom: 0;
	}
	ul {
		margin-bottom: 0;
	}
	.heading {
		font-size: 20px;
		margin-bottom: 14px;
		color: #333;
	}
	.sub-heading {
		font-size: 16px;
		margin-bottom: 14px;
		color: #777;
		font-weight: bold;
	}
	.single-card p {
		font-size: 14px;
		color: #777;
	}
	.single-card a {
		font-size: 14px;
		color: #777;
		text-decoration: underline;
	}
	.switch {
		position: relative;
		display: inline-block;
		width: 54px;
		height: 26px;
	}

	.switch input {
		opacity: 0;
		width: 0;
		height: 0;
	}

	.slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #ccc;
		-webkit-transition: 0.4s;
		transition: 0.4s;
	}

	.slider:before {
		position: absolute;
		content: '';
		height: 20px;
		width: 20px;
		left: 4px;
		bottom: 3px;
		background-color: white;
		-webkit-transition: 0.4s;
		transition: 0.4s;
	}

	input:checked + .slider {
		background-color: #01c107;
	}

	input:focus + .slider {
		box-shadow: 0 0 1px #01c107;
	}

	input:checked + .slider:before {
		-webkit-transform: translateX(26px);
		-ms-transform: translateX(26px);
		transform: translateX(26px);
	}

	/* Rounded sliders */
	.slider.round {
		border-radius: 34px;
	}

	.slider.round:before {
		border-radius: 50%;
	}
`;

const Setting = ({ user, getUserData }) => {
	const [open2faForm, setOpen2faForm] = useState(false);
	const [openConfirmAlert, setOpenConfirmAlert] = useState(false);
	const [twoFa, setTwoFa] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => { 
		(() => {
			if (user && user.has_2fa) {
				setTwoFa(true);	
			} else {
				setTwoFa(false);
			}
		})();
	}, []);

	const { register, handleSubmit, errors } = useForm({
		mode: 'onBlur',
	});

	const onSubmit = async (data) => {
		try {
			const response = await fetchWrapper.post('auth/users/two_factor/', JSON.stringify(data), true);
			if (response.code==="401") {
				toast.error(response.detail)
			}else if(response.success){
				dispatch({ type: 'SET_USER', payload: {...user, has_2fa:true} });
				toast.success(response.success)
				setOpen2faForm(false);
				setTwoFa(true);
			}
		} catch (e) {
			console.log(errors);
		}
	};

	return (
		<Main>
			<TopBar>
				<div css={settingStyle}>
					<Row>
						<Col md={6}>
							<div className="single-card">
								<h4 className="heading">Two-factor Authentication</h4>
								<Card>
									<Card.Body>
										<div className="d-flex justify-content-between align-items-center">
											<h6 className="sub-heading">Please enable two-factor authentication</h6>
											<div>
												<label className="switch">
													<input
														type="checkbox"
														checked={twoFa}
														onClick={() => {
															twoFa ? setOpenConfirmAlert(true) : setOpen2faForm(true);
														}}
													/>
													<span className="slider round"></span>
												</label>
											</div>
										</div>
										<p className="mb-2">
											It isn't difficult and it really helps to protect your account.
										</p>
										<p>
											Once enabled, it will require 5 digits security code in addition to your
											password whenever you sign in to p2p or link a new computer, phone or
											tablet.
										</p>
									</Card.Body>
									<Modal show={open2faForm} onHide={() => setOpen2faForm(false)}>
										<Modal.Dialog style={{ width: '550px', margin: '0' }}>
											<Form className="m-4" onSubmit={handleSubmit(onSubmit)}>
												<h4 className="text-center"> Two Factor Authentication </h4>
												<Label2faModal>
													Please select how you want to receive your 5 digit security code for
													Two Factor Authentication
												</Label2faModal>
												<select
													ref={register}
													name="two_factor_method"
													class="form-control"
													id="exampleFormControlSelect1"
													placeholder="Select"
												>
													<option disabled selected value="">
														Select a Method
													</option>
													<option value="email">Email</option>
													<option value="sms">Phone</option>
												</select>

												<div>
													<Label2faModal>Email Address</Label2faModal>
													<input
														ref={register}
														name="email"
														type="email"
														className="form-control"
														required
													/>
													<Label2faModal>Phone Number (*including your country code)</Label2faModal>
													<input
														ref={register}
														name="phone"
														type="text"
														className="form-control"
														required
													/>
													<Label2faModal>Enter your password</Label2faModal>
													<input
														ref={register}
														name="password"
														type="password"
														className="form-control"
														required
													/>
													<div className="d-flex flex-row justify-content-center py-4">
														<Button type="submit" variant="secondary">
															Enable Two Factor Authentication
														</Button>
													</div>
												</div>
											</Form>
										</Modal.Dialog>
									</Modal>
									<Disable2fa
										openConfirmAlert={openConfirmAlert}
										setOpenConfirmAlert={setOpenConfirmAlert}
										twoFa={twoFa}
										setTwoFa={setTwoFa}
										user={user}
									/>
								</Card>
							</div>
							<div className="single-card">
								<h4 className="heading">Password</h4>
								<Card>
									<Card.Body>
										<p>Change your Password</p>
									</Card.Body>
								</Card>
							</div>
							<div className="single-card">
								<h4 className="heading">About</h4>
								<Card>
									<Card.Body>
										<ul>
											<li>
												<Link to="">Terms of use</Link>
											</li>
											<li>
												<Link to="">About p2p</Link>
											</li>
										</ul>
									</Card.Body>
								</Card>
							</div>
						</Col>
						<Col md={6}>
							<div className="single-card">
								<h4 className="heading">Help</h4>
								<Card>
									<Card.Body>
										<p>Report a problem?</p>
									</Card.Body>
								</Card>
							</div>
						</Col>
					</Row>
				</div>
			</TopBar>
		</Main>
	);
};

const mapStateToProps = (state) => ({
	user: state.user.user,
});

const mapDispatchToProps = {getUserData};

export default connect(mapStateToProps, mapDispatchToProps)(Setting);
