import * as Yup from 'yup';

export const regValidationSchema = Yup.object().shape({
	first_name: Yup.string().required('First Name is required'),
	last_name: Yup.string().required('Last Name is required'),
	email: Yup.string().email('Email is invalid').required('Email is required'),
	password: Yup.string().required('Password is required').min(8, 'Password must be at least 8 characters').matches(/\d/, 'Password must contain atleast one number')
	.matches(/[a-z]/, 'Password must contain a letter').matches(/[A-Z]/, 'Password must contain a capital letter').matches(/[^a-zA-Z0-9_]/, 'Password must contain a special character'),
	confirmPassword: Yup.string()
		.oneOf([Yup.ref('password'), null], 'Passwords must match')
		.required('Confirm Password is required'),
	sellerBuyer: Yup.string().when(['is_buyer', 'is_seller'], {
		is: (is_buyer, is_seller) => !(is_buyer || is_seller),
		then: Yup.string().required('choose atleast one option'),
	}),
});

export const logValidationSchema = Yup.object().shape({
	email: Yup.string().email('Email is invalid').required('Email is required'),
	password: Yup.string().required('Password is required').min(8, 'Password must be at least 8 characters'),
});

export const onlyEmailValidation = Yup.object().shape({
	email: Yup.string().email('Email is invalid').required('Email is required'),
});

export const resetPasswordValidation = Yup.object().shape({
	token: Yup.string().required('token is required'),
	password: Yup.string().required('Password is required').min(8, 'Password must be at least 8 characters').matches(/\d/, 'Password must contain atleast one number')
	.matches(/[a-z]/, 'Password must contain a letter').matches(/[A-Z]/, 'Password must contain a capital letter').matches(/[^a-zA-Z0-9_]/, 'Password must contain a special character'),
	confirmPassword: Yup.string()
		.oneOf([Yup.ref('password'), null], 'Passwords must match')
		.required('Confirm Password is required'),
});

export const energyFormValidation = Yup.object().shape({
	name: Yup.string().required('Name is required'),
	supplier: Yup.string().required('Supplier is required'),
	net_energy_metering: Yup.number().required('Net Energy is required'),
	avg_production: Yup.number().required('Net Energy is required'),
	address1: Yup.string().required('Address is required'),
	address2: Yup.string().required('Address is required'),
	city: Yup.string().required('City is required'),
	state: Yup.string().required('State is required'),
	zip_code: Yup.string().required('Zip code is required'),
	sell_percent: Yup.string().required('Sell percent is required'),
	payment_duration: Yup.string().required('Payment duration is required'),
});

export const propertyFormValidation = Yup.object().shape({
	name: Yup.string().required('Name is required'),
	meter_box_id: Yup.number().required('Meter box id is required'),
	customer_no: Yup.number().required('Costumer Number is required'),
	avg_energy_consumption: Yup.number().required('Net Energy is required'),
	buy_percent: Yup.string().required('Buy percentage is required'),
	address1: Yup.string().required('Address is required'),
	address2: Yup.string().required('Address is required'),
	city: Yup.string().required('City is required'),
	latitude: Yup.string().notRequired(),
	longitude: Yup.string().notRequired(),
	state: Yup.string().required('State is required'),
	zip_code: Yup.string().required('Zip Code is required'),
	name_on_bill: Yup.string().required('Bill Name is required'),
	preferred_energy: Yup.array().min('1', 'Choose at least one'),
});
