import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Map, Marker, InfoWindow, GoogleApiWrapper } from 'google-maps-react';

// const mapStyles = {
// 	// position: 'relative',
// 	// width: '100%',
// 	// height: '100%',
// 	border:'2px solid black'
// };

function GoogleMap({ google, lat, lng, ...rest }) {
	const [activeMarker, setActiveMarker] = useState({});
	const [selectedPlace, setSelectedPlace] = useState({});
	const [showingInfoWindow, setShowingInfoWindow] = useState(false);

	const onMarkerClick = (props, marker) => {
		window.setTimeout(() => {
			setActiveMarker(marker);
			setSelectedPlace(props);
			setShowingInfoWindow(true);
		}, 100);
	};

	const onInfoWindowClose = () => {
		setActiveMarker(null);
		setShowingInfoWindow(false);
	};

	const onMapClicked = () => {
		if (showingInfoWindow) {
			setActiveMarker(null);
			setShowingInfoWindow(false);
		}
	};

	return (
		<Map google={google} onClick={onMapClicked} style={{width:"auto",height:"270px", position:"relative"}} zoom={10} initialCenter={{ lat, lng }}>
			<Marker
				name="buyer"
				icon={{
					url: 'http://maps.google.com/mapfiles/ms/icons/red.png',
					anchor: new google.maps.Point(0, 0),
					scaledSize: new google.maps.Size(32, 32),
				}}
				onClick={onMarkerClick}
				position={{ lat: 37.778519, lng: -122.40564 }}
			/>

			<Marker
				name="seller"
				icon={{
					url: 'http://maps.google.com/mapfiles/ms/icons/blue.png',
					anchor: new google.maps.Point(0, 0),
					scaledSize: new google.maps.Size(32, 32),
				}}
				onClick={onMarkerClick}
				position={{ lat: 37.759703, lng: -122.428093 }}
			/>

			<Marker name="Current location" onClick={onMarkerClick} />

			<InfoWindow
				marker={activeMarker}
				className="bg-dark"
				onClose={onInfoWindowClose}
				visible={showingInfoWindow}
			>
				<p className="text-dark mb-0">{selectedPlace.name}</p>
			</InfoWindow>

			{/* <InfoWindow position={{ lat: 37.765703, lng: -122.42564 }} visible>
				<small>Click on any of the markers to display an additional info.</small>
			</InfoWindow> */}
		</Map>
	);
}

GoogleMap.propTypes = {
	google: PropTypes.object.isRequired,
	lat: PropTypes.number.isRequired,
	lng: PropTypes.number.isRequired,
};

export default GoogleApiWrapper({
	apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
})(GoogleMap);
