import React, { useEffect, useRef } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// import PropTypes from "prop-types";

// routes
import NotFound from './components/NotFound/NotFound';
// import { PrivateRoute, AuthRoute } from './_helpers';
import Home from './components/Home/Home';
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';

import { PrivateRoute, AuthRoute, history, useInterval } from './_helpers';
import routes from './routes';

// toasts
import { toast } from 'react-toastify';

// base style
import './app.scss';
import { connect } from 'react-redux';
import { getUserData, setUserLocation, getAllNotification } from './_redux/actions/ActionUser';
import { logoutUser } from './_redux/actions/ActionAuthentication';

import TwoFact from './components/Auth/TwoFact';
import ActivateAcc from './components/Auth/ActivateAcc';
import ResetPassword from './components/Auth/ResetPassword';

const App = ({ isLogged, getUserData, setUserLocation, message, getAllNotification, notifications, logoutUser}) => {
	useEffect(() => {
		if (isLogged) {
			setUserLocation();
			getUserData();
			getAllNotification();
		}
	}, [isLogged, getUserData, setUserLocation, getAllNotification]);

	const isFirst = useRef(true);

	useEffect(() => {
		if (isFirst.current) {
			isFirst.current = false;
			return;
		}
		if (message) {
			if (message.error === true) {
				toast.error(message.message);
			} else if (message) {
				toast.info(message.message);
			}
		}
	}, [message]);
	
	useInterval(() => {
	if(isLogged){
		getAllNotification();
	}
	}, 1000 * 20);

	// session expires in 30 minutes
	useInterval(() => {
		if(isLogged){
			toast.error("Session Expired")
			logoutUser()
		}
		}, 30 * 60 * 1000 );
		
	toast.configure()

	return (
		<div>
			<Router history={history}>
				<Switch>
					<PrivateRoute path="/" exact component={Home} />
					<AuthRoute path="/login" exact component={Login} />
					<AuthRoute path="/reset-password" exact component={ResetPassword} />
					<AuthRoute path="/register" exact component={Register} />
					<AuthRoute path="/2-fact/:email" exact component={TwoFact} />
					<AuthRoute path="/activate/:email" exact component={ActivateAcc} />
					{routes.map((route, idx) => {
						return (
							route.component && (
								<PrivateRoute
									key={idx}
									path={route.path}
									exact={route.exact}
									name={route.name}
									component={route.component}
								/>
							)
						);
					})}
					<Route component={NotFound} />
				</Switch>
			</Router>
		</div>
	);
};

App.propTypes = {};

const mapStateToProps = (state) => ({
	isLogged: state.user.isLogged,
	message: state.ui.message,
	notifications: state.notification.notifications,
});

export default connect(mapStateToProps, { logoutUser, getUserData, setUserLocation, getAllNotification })(App);
