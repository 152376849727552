import React from 'react';
import { FaSolarPanel, FaIndustry, FaCarBattery } from 'react-icons/fa';
import { MdDevicesOther } from 'react-icons/md';
import { GiPaperWindmill } from 'react-icons/gi';

const EnergyTypeIcon = ({ id }) => {
	switch (id) {
		case 'Solar':
		case 1:
			return <FaSolarPanel />;
		case 'Battery':
		case 2:
			return <FaCarBattery />;
		case 'Others':
		case 3:
			return <MdDevicesOther />;
		case 4:
			return <FaIndustry />;
		case 5:
			return <GiPaperWindmill />;
		default:
			return null;
	}
};

export default EnergyTypeIcon;
