import * as ActionTypes from '../ActionTypes';
import { jwtCheck } from '../../_helpers';


const initialState = {
	isLogged: jwtCheck() ? true : false,
	isLoading: false,
	user: null,
	property: [],
	isPropertyLoading: false,
	position: null,
	errorMessage: '',
};

export default function (state = initialState, action) {
	switch (action.type) {
		case ActionTypes.SET_USER:
			return {
				...state,
				isLogged: true,
				user: action.payload,
			};
		case ActionTypes.SET_ALL_PROPERTY:
			return {
				...state,
				property: action.payload,
			};
		case ActionTypes.LOADING_UI_PROPERTY:
			return {
				...state,
				isPropertyLoading: true,
			};
		case ActionTypes.STOP_LOADING_UI_PROPERTY:
			return {
				...state,
				isPropertyLoading: false,
			};
		case ActionTypes.SET_LOGGEDIN:
			return { ...state, isLogged: true };
		case ActionTypes.SET_LOGGEDOUT:
			return { ...state, isLogged: false, user: [], allUser: [] };

		case ActionTypes.SET_POSITION:
			return { ...state, position: action.payload };
		case ActionTypes.SET_POS_ERROR:
			return { ...state, errorMessage: action.payload };
		default:
			return state;
	}
}
