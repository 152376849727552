export const getDistanceFromLatLonInM = (lat1, lon1, lat2, lon2) => {
	// Radius of the earth in m
	var R = 6371000;
	// deg2rad below
	var dLat = deg2rad(lat2 - lat1);
	var dLon = deg2rad(lon2 - lon1);
	var a =
		Math.sin(dLat / 2) * Math.sin(dLat / 2) +
		Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
	var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
	// Distance in m
	var d = R * c;
	return d ;
};

function deg2rad(deg) {
	return deg * (Math.PI / 180);
}

// export const distance = (lat1, lon1, lat2, lon2) => {
// 	// https://www.geodatasource.com/developers/javascript
// 	if (lat1 === lat2 && lon1 === lon2) {
// 		return 0;
// 	} else {
// 		var radlat1 = (Math.PI * lat1) / 180;
// 		var radlat2 = (Math.PI * lat2) / 180;
// 		var theta = lon1 - lon2;
// 		var radtheta = (Math.PI * theta) / 180;
// 		var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
// 		if (dist > 1) {
// 			dist = 1;
// 		}
// 		dist = Math.acos(dist);
// 		dist = (dist * 180) / Math.PI;
// 		dist = dist * 60 * 1.1515;
// 		console.log(dist)
// 		return dist;
// 	}
// };

export const round = (value, decimals) => {
	return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
};

export const dateFormat = (date) => {
	const dates = date.split('T')[0].split("-")
	return dates[1] + "-" + dates[2] + "-" + dates[0]
}
