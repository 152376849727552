/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useState } from 'react';
import { Card, Form, Container, Row, Col, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import ImageUpload from '../../Layouts/ImageUpload/ImageUpload';
import Main from '../../Layouts/Main';
import TopBar from '../../Layouts/TopBar/TopBar';
import { postAProduct } from '../../_redux/actions/ActionMarket';
import { yupResolver } from '@hookform/resolvers/yup';
import { energyFormValidation } from '../../_helpers';
import Success from '../../Layouts/Success';
import BillingInfo from './BillingInfo';

const billing = css`
	width: 80%;
	input,
	textarea {
		border-top: none;
		border-right: none;
		border-left: none;
		border-bottom: 2px solid #979da3;
		border-radius: 0;
		background-color: transparent !important;
		font-size: 1rem;
		outline: none !important;
		border: 1px 0px 0px 0px solid black;
	}
	input:not(:placeholder-shown),
	input:focus {
		border-bottom: 2px solid #000;
	}

	label {
		font-size: 0.95rem;
		font-weight: 700;
		color: #aaaaaa;
	}

	select,
	select:focus {
		-webkit-appearance: none;
		appearance: none;
		-moz-appearance: none;
		outline: none;
		border-top: none;
		border-right: none;
		border-left: none;
		border-bottom: 2px solid #000;
		border-radius: 0;

		background-color: transparent !important;
		background-image: url('/images/dropdown.svg');
		background-repeat: no-repeat !important;
		background-position: right 10px top 50%;
		background-size: 10px;
	}

	::-webkit-input-placeholder {
		/* Edge */
		color: #aaaaaa;
	}

	:-ms-input-placeholder {
		/* Internet Explorer */
		color: #aaaaaa;
	}

	::placeholder {
		color: #aaaaaa;
	}
`;
const bigButtons = css`
	margin: 1rem;
	font-size: 1.3rem;
	width: 50%;
	height: 3.5rem;
	border-radius: 10px;
	letter-spacing: normal;
	font-weight: 700;
	background-color: #e97724;
	color: white;
`;

const padding = css`
	padding: 1.81rem 2rem 1.81rem 2rem;
`;

function EnergyDetailForm({ match, user, postAProduct, position, property }) {
	const id = match.params.id;

	const [image, setImage] = useState(null);
	const [showModel, setShowModel] = useState(false);
	const [isbilling, setIsbilling] = useState(false);
	const [formDetail, setformDetail] = useState(null);
	const [loading, setLoading] = useState(false);

	const { register, handleSubmit, errors } = useForm({
		mode: 'onBlur',
		defaultValues: {},
		resolver: yupResolver(energyFormValidation),
	});

	const onSubmit = async (data) => {
		const tempData = {
			name: data.name,
			picture: image ? image : null,
			energy_type_id: id,
			prosumer_email: user.email,
			avg_production: data.avg_production,
			net_energy_metering: data.net_energy_metering,
			payment_duration: data.payment_duration,
			payment_type: data.payment_type,
			price_per_kwh: data.price_per_kwh,
			sell_percent: data.sell_percent,
			supplier: data.supplier,
			property_id: data.property_id,
			energy_source_address: JSON.stringify({
				address_line_one: data.address1,
				address_line_two: data.address2,
				city: data.city,
				point: {
					lat: data.latitude,
					lon: data.longitude,
				},
				state: data.state,
				zip_code: data.zip_code,
			}),
		};

		var form_data = new FormData();

		for (var key in tempData) {
			console.log(key);
			form_data.append(key, tempData[key]);
		}

		setformDetail(form_data);
		setIsbilling(true);
	};

	const handleDataSubmit = async () => {
		setLoading(true);
		const res = await postAProduct(formDetail);
		if (res) {
			setShowModel(true);
			setLoading(false);
		}
		setLoading(false);
	};

	return (
		<Main>
			<TopBar>
				<Container className="d-flex justify-content-center mt-2">
					{!isbilling ? (
						<Card css={billing}>
							<div css={padding} className="position-relative ">
								<Form onSubmit={handleSubmit(onSubmit)} className="p-2">
									<p className="font-weight-bold ">Energy Source Detail</p>
									<Form.Group as={Row} controlId="formPlaintextSelects">
										<Form.Label className="text-right" column sm="4">
											Name of solar panel
										</Form.Label>
										<Col sm="6">
											<Form.Control
												type="text"
												name="name"
												isInvalid={errors.name}
												ref={register}
												placeholder="Enter the name of solar panel"
											/>

											{/* <Form.Text className="text-danger">{errors.name?.message}</Form.Text> */}
										</Col>
									</Form.Group>

									<Form.Group as={Row} controlId="formPlaintextBankName">
										<Form.Label className="text-right" column sm="4">
											Supplier's Name
										</Form.Label>
										<Col sm="6">
											<Form.Control
												type="text"
												name="supplier"
												isInvalid={errors.supplier}
												ref={register}
												placeholder="Enter Name of supplier"
											/>

											{/* <Form.Text className="text-danger">{errors.supplier?.message}</Form.Text> */}
										</Col>
									</Form.Group>

									<Form.Group as={Row} controlId="formPlaintextRoutingNumber">
										<Form.Label className="text-right" column sm="4">
											Net Meter
										</Form.Label>
										<Col sm="6">
											<Form.Control
												type="number"
												name="net_energy_metering"
												isInvalid={errors.net_energy_metering}
												ref={register}
												placeholder="Enter in meter"
											/>

											{/* <Form.Text className="text-danger"> */}
											{/* {errors.net_energy_metering?.message} */}
											{/* </Form.Text> */}
										</Col>
									</Form.Group>
									<Form.Group as={Row} controlId="formPlaintextAccountNumber">
										<Form.Label className="text-right" column sm="4">
											Average Production
										</Form.Label>
										<Col sm="6">
											<Form.Control
												type="number"
												name="avg_production"
												isInvalid={errors.avg_production}
												ref={register}
												placeholder="Enter number of average production"
											/>

											{/* <Form.Text className="text-danger">{errors.avg_production?.message}</Form.Text> */}
										</Col>
									</Form.Group>

									<Form.Group as={Row} controlId="formPlaintextAccountNumber">
										<Form.Label className="text-right" column sm="4">
											Property
										</Form.Label>
										<Col sm="6">
											<Form.Control
												as="select"
												name="property_id"
												isInvalid={errors.property_id}
												ref={register}
												placeholder="Enter number of average production"
											>
												{property.length &&
													property.map((pro) => (
														<option key={pro.id} value={pro.id}>
															{pro.name}
														</option>
													))}
											</Form.Control>

											{/* <Form.Text className="text-danger">{errors.avg_production?.message}</Form.Text> */}
										</Col>
									</Form.Group>
									<p className="font-weight-bold ">Address</p>
									<Form.Group as={Row} controlId="formPlaintextAccountNumber">
										<Form.Label className="text-right" column sm="4">
											Address 1
										</Form.Label>
										<Col sm="6">
											<Form.Control
												type="text"
												name="address1"
												isInvalid={errors.address1}
												ref={register}
												placeholder="Enter your address 1"
											/>

											{/* <Form.Text className="text-danger">{errors.address1?.message}</Form.Text> */}
										</Col>
									</Form.Group>
									<Form.Group as={Row} controlId="formPlaintextAccountNumber">
										<Form.Label className="text-right" column sm="4">
											Address 2
										</Form.Label>
										<Col sm="6">
											<Form.Control
												type="text"
												name="address2"
												isInvalid={errors.address2}
												ref={register}
												placeholder="Enter your address 2"
											/>

											{/* <Form.Text className="text-danger">{errors.address2?.message}</Form.Text> */}
										</Col>
									</Form.Group>

									<Form.Group as={Row} controlId="formPlaintextAccountNumber">
										<Form.Label className="text-right" column sm="4">
											Coordinate
										</Form.Label>
										<Col sm="3">
											<Form.Control
												type="text"
												name="latitude"
												defaultValue={position && position.coords.latitude}
												isInvalid={errors.latitude}
												ref={register}
												placeholder="Enter latitide"
											/>
										</Col>
										<Col sm="3">
											<Form.Control
												type="text"
												name="longitude"
												defaultValue={position && position.coords.longitude}
												isInvalid={errors.longitude}
												ref={register}
												placeholder="Enter longitude"
											/>
										</Col>

										{/* <Form.Text className="text-danger">{errors.address2?.message}</Form.Text> */}
									</Form.Group>

									<Form.Group as={Row} controlId="formPlaintextAccountNumber">
										<Form.Label className="text-right" column sm="4">
											City
										</Form.Label>
										<Col sm="6">
											<Form.Control
												type="text"
												name="city"
												isInvalid={errors.city}
												ref={register}
												placeholder="Enter your city"
											/>

											{/* <Form.Text className="text-danger">{errors.city?.message}</Form.Text> */}
										</Col>
									</Form.Group>
									<Form.Group as={Row} controlId="formPlaintextAccountNumber">
										<Form.Label className="text-right" column sm="4">
											Choose your state
										</Form.Label>
										<Col sm="6">
											<Form.Control
												as="select"
												type="text"
												name="state"
												isInvalid={errors.state}
												ref={register}
												placeholder="Enter your address 1"
											>
												<option>massachusetts</option>
											</Form.Control>

											{/* <Form.Text className="text-danger">{errors.state?.message}</Form.Text> */}
										</Col>
									</Form.Group>
									<Form.Group as={Row} controlId="formPlaintextAccountNumber">
										<Form.Label className="text-right" column sm="4">
											Enter your Zip code
										</Form.Label>
										<Col sm="6">
											<Form.Control
												type="text"
												name="zip_code"
												isInvalid={errors.zip_code}
												ref={register}
												placeholder="Zip code"
											/>

											{/* <Form.Text className="text-danger">{errors.zip_code?.message}</Form.Text> */}
										</Col>
									</Form.Group>
									<p className="font-weight-bold ">Energy percentage you want sell</p>
									<Form.Group as={Row} controlId="formPlaintextAccountNumber">
										<Form.Label className="text-right" column sm="6">
											sell energy percentage
										</Form.Label>
										<Col sm="2">
											<Form.Control
												as="select"
												type="text"
												name="sell_percent"
												isInvalid={errors.sell_percent}
												ref={register}
												placeholder="Enter your address 1"
											>
												<option value="20">20%</option>
											</Form.Control>

											{/* <Form.Text className="text-danger">{errors.sell_percent?.message}</Form.Text> */}
										</Col>
									</Form.Group>
									<p className="font-weight-bold ">Payment terms</p>
									<Form.Group
										as={Row}
										className="d-flex justify-content-center"
										controlId="formPlaintextAccountNumber"
									>
										<Col sm="2">
											<Form.Control
												as="select"
												type="text"
												name="payment_duration"
												isInvalid={errors.payment_duration}
												ref={register}
											>
												<option value={2}>2 months</option>
											</Form.Control>

											{/* <Form.Text className="text-danger"> */}
											{/* {errors.payment_duration?.message} */}
											{/* </Form.Text> */}
										</Col>
										<Col sm="2">
											<Form.Control
												as="select"
												type="text"
												name="payment_type"
												isInvalid={errors.payment_type}
												ref={register}
												placeholder="Enter your address 1"
											>
												<option value="Fixed">fixed</option>
											</Form.Control>

											{/* <Form.Text className="text-danger">{errors.payment_type?.message}</Form.Text> */}
										</Col>
										<Col sm="2">
											<Form.Control
												as="select"
												type="text"
												name="price_per_kwh"
												isInvalid={errors.price_per_kwh}
												ref={register}
												placeholder="Enter your address 1"
											>
												<option value="11">11/kwh</option>
											</Form.Control>

											{/* <Form.Text className="text-danger">{errors.price_per_kwh?.message}</Form.Text> */}
										</Col>
									</Form.Group>

									<p className="font-weight-bold ">Add Image</p>
									<div className="d-flex justify-content-center">
										<ImageUpload setImage={setImage} />
									</div>

									<p className="font-weight-bold ">Accepted payment type</p>
									<div className="d-flex justify-content-center ">
										<div>
											<Form.Group controlId="creditCard">
												<Form.Check type="checkbox" name="creditCard" label="Credit card" />
											</Form.Group>
											<Form.Group controlId="paypal">
												<Form.Check type="checkbox" name="paypal" label="Paypal" />
											</Form.Group>
											<Form.Group controlId="crypto">
												<Form.Check type="checkbox" name="crypto" label="Digital wallet" />
											</Form.Group>
											<Form.Group controlId="token">
												<Form.Check type="checkbox" name="token" label="P2P Token" />
											</Form.Group>
											<Form.Group controlId="banking">
												<Form.Check type="checkbox" name="banking" label="Banking" />
											</Form.Group>
										</div>
									</div>
									<div className="my-3 d-flex justify-content-center">
										<Button variant="secondary" type="submit" css={bigButtons}>
											Submit
										</Button>
									</div>
								</Form>
							</div>
						</Card>
					) : (
						<BillingInfo handleDataSubmit={handleDataSubmit} loading={loading} />
					)}
					{showModel && (
						<Success
							show={showModel}
							setShowModel={setShowModel}
							forLink="/profile/energy-list"
							buttonText="Your Energy List"
							text="Added to market successfully"
						/>
					)}
				</Container>
			</TopBar>
		</Main>
	);
}
const mapStateToProps = (state) => ({
	user: state.user.user,
	position: state.user.position,
	property: state.user.property,
});

export default connect(mapStateToProps, { postAProduct })(EnergyDetailForm);
