import { toast } from 'react-toastify';
import { fetchWrapper } from '../../_helpers';
import * as ActionTypes from '../ActionTypes';

export const postSubsciption = (subscription) => async (dispatch) => {
	try {
		await fetchWrapper.post(`payment/`, subscription);
		toast.success("Added to Cart Successfully")
		return true;
	} catch (e) {
		toast.error(e.message)
		return false;
	}
};

export const getAllSubsciption = () => async (dispatch) => {
	try {
		dispatch({ type: ActionTypes.LOADING_UI });
		const response = await fetchWrapper.get(`payment/`);
		dispatch({ type: ActionTypes.STOP_LOADING_UI });
		return response;
	} catch (e) {}
};

export const getAllInvoice = () => async (dispatch) => {
	try {
		dispatch({ type: ActionTypes.LOADING_UI });
		const response = await fetchWrapper.get(`payment/display_billings`);
		dispatch({ type: ActionTypes.STOP_LOADING_UI });
		return response;
	} catch (e) {}
};
