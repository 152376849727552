import React from 'react';
import {useState} from 'react';
import {AiFillCaretLeft, AiFillCaretRight } from 'react-icons/ai';
import Tips from './Tips';
// for slider
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

const HomeSlider = () => {
    const [slideNumber, setSlideNumber] = useState(1)
    return (
        <CarouselProvider
						naturalSlideWidth={75}
						naturalSlideHeight={50}
						totalSlides={3}
						isPlaying={false}
						isIntrinsicHeight={true}
					>
					<div className="secondary-wrapper">
						<Slider>
							<Slide index={0}>
								<Tips title="1. Open widows" image="/images/tips.jpg"/>	
							</Slide>
							<Slide index={1}>
								<Tips title="2. Open Refrigerator" image="/images/tips.jpg"/>	
							</Slide>
							<Slide index={2}>
								<Tips title="3. Turn the lights off" image="/images/tips.jpg"/>	
							</Slide>
						</Slider>
						<div className="d-flex flex-row justify-content-between mt-4">
                            <div>
								<ButtonBack className="btn text-light p-0" onClick={(e) => {console.log(slideNumber); setSlideNumber(prev => prev - 1 )}} disabled={slideNumber===1}>
                                    <AiFillCaretLeft className="h4 mb-0"/>
                                </ButtonBack>
								<ButtonNext className="btn text-light p-0" onClick={(e) => {console.log(slideNumber); setSlideNumber(prev => prev + 1 )}} disabled={slideNumber===3}>
                                    <AiFillCaretRight className="h4 mb-0"/>
                                </ButtonNext>
                            </div>
                            <div>{slideNumber}</div>
						</div>
					</div>
					</CarouselProvider>
    )
}

export default HomeSlider
