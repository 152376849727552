/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useState, useEffect } from 'react';
import { MdNotifications } from 'react-icons/md';
import { Dropdown } from 'react-bootstrap';
import Notification from '../Notification/Notification';
import Breadcrumbs from '../BreadCrumb/BreadCrumbs';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateUserData } from '../../_redux/actions/ActionUser';
import { fetchWrapper } from '../../_helpers/fetchWrapper';

const topBar = css`
	padding: 0;
	margin: 0;
	width: 100%;
	min-height: 100vh;
	.top-nav {
		position: sticky;
		top: 0;
		height: 3.5rem;
		background-color: var(--primary);
		box-shadow: 0px 1px 4px -2px black;
		z-index: 1000;
		padding: 0 35px;

		a {
			color: black;
		}
		.icon-notification {
			cursor: pointer;
		}
		.icon-notification :hover {
			color: blue;
		}
		input[type='text'] {
			border: 0 none;
			width: 15rem;
			padding: 6px 15px 6px 35px;
			-webkit-border-radius: 20px;
			-moz-border-radius: 20px;
			border-radius: 20px;
			margin: 3px 12px;
			background-image: url('https://www.w3schools.com/css/searchicon.png');
			background-size: 25px;
			background-position: right center;
			background-origin: content-box;
			background-repeat: no-repeat;
		}

		input[type='text']:focus {
			width: 25rem;
			margin: 3px 12px;
			outline: none;
		}

		input[type='text'] {
			-webkit-transition: all 0.7s ease 0s;
			-moz-transition: all 0.7s ease 0s;
			-o-transition: all 0.7s ease 0s;
			transition: all 0.7s ease 0s;
		}
		.badge {
			position: absolute;
			display: flex;
			align-items: center;
			background-color: red;
			border: 2px solid #b6d8fe;
			height: 20px;
			width: auto;
			border-radius: 15px;
			color: white;
			top: -8px;
			left: 15px;
		}
		.username {
			margin-left: 30px;
			h5 {
				font-weight: bold;
				color: #016192;
				font-size: 16px;
				margin-bottom: 0;
			}
			p {
				color: #016192;
				font-size: 12px;
				margin-bottom: 0;
			}
			.btn {
				padding: 0;
				margin-left: 10px;
				background-color: transparent;
				line-height: 0;
				color: #016192;
				border: none;
				&:focus {
					border: none;
					box-shadow: none;
				}
			}
			.dropdown-menu {
				border-radius: 0;
				margin-top: 10px;
				border: none;
				padding: 0 0.75rem;
				div {
					:not(:last-child) {
						border-bottom: 1px solid rgba(182, 216, 254, 0.3);
					}
				}

				label {
					color: #016192;
					font-size: 14px;
					margin-bottom: 0;
				}
			}
		}
	}

	.notification-container {
		background-color: #011941;
		z-index: 10;
		margin-top: 3.6rem;
		width: 60%;
		height: 100vh;
		-webkit-transform: translateX(100%);
		transform: translateX(100%);
		position: fixed;
		top: 0;
		right: 0;
		padding-bottom: 5rem;
		overflow-y: auto;
		-webkit-transition: all ease 0.5s;
		transition: all ease 0.5s;
		opacity: 0 !important;
	}
	.notification-container.open {
		-webkit-transform: translateX(0%);
		transform: translateX(0%);
		overflow-y: auto;
		opacity: 1 !important;
	}
`;

function TopBar({ children, user, updateUserData }) {
	const history = useHistory();
	const [open, setOpen] = useState(false);
	const [search, setSearch] = useState('');
	const [buyer, setBuyer] = useState(false);
	const [seller, setSeller] = useState(false);
	const [notificationCount, setNotificationCount] = useState(null);

	useEffect(() => {
		if (user) {
			setBuyer(user.is_buyer);
			setSeller(user.is_seller);
		}
	}, [user]);

	useEffect(() => {
		(async () => {
			try {
				const response = await fetchWrapper.get(`notifications/notification_count`);
				setNotificationCount(response);
			} catch (e) {
				console.log(e);
			}
		})();
	});

	const handleSearch = () => {
		if (search) {
			history.push(`/search/${search}`);
		}
	};
	const handleNotification = async () => {
		setOpen(!open);
		try {
			const response = await fetchWrapper.put(`notifications/click_bell`);
			setNotificationCount(response);
		} catch (e) {
			console.log(e);
		}
	};

	const userType = (e) => {
		const value = e.target.value;
		const data = new FormData();

		if (value === 'buyer') {
			data.append('is_buyer', true);
			data.append('is_seller', false);
		} else if (value === 'seller') {
			data.append('is_seller', true);
			data.append('is_buyer', false);
		} else if (value === 'prosumer') {
			data.append('is_seller', true);
			data.append('is_buyer', true);
		}

		updateUserData(data);
	};

	return (
		<div className="position-relative bg-primary" css={topBar} onClick={() => setOpen(false)}>
			<div className={`notification-container  ${open ? 'open' : ''}`} onClick={(e) => e.stopPropagation()}>
				<Notification setOpen={setOpen} />
			</div>
			<hr className="bg-white m-0 p-0" />
			<div className="top-nav d-flex justify-content-end align-items-center">
				<div className="d-flex align-items-center">
					<div>
						<input
							type="text"
							placeholder="Search"
							value={search}
							onChange={({ target: { value } }) => setSearch(value)}
							onKeyPress={(event) => (event.key === 'Enter' ? handleSearch(event, search) : null)}
						/>
					</div>
					<div className="position-relative" onClick={(e) => e.stopPropagation()}>
						<MdNotifications
							size="25"
							color="#016192"
							className="icon-notification"
							onClick={handleNotification}
						/>
						{notificationCount > 0 && <div className="badge">{notificationCount}</div>}
					</div>
					<div className="username">
						<h5>{user && `${user.first_name} ${user.last_name ? user.last_name : ''}`}</h5>
						<div className="d-flex">
							<p>
								{buyer && seller ? 'Buying And Selling' : buyer ? 'Buying' : seller ? 'Selling' : null}
							</p>
							<Dropdown>
								<Dropdown.Toggle />
								<Dropdown.Menu>
									<div className="d-flex justify-content-between py-2">
										<label htmlFor="buyer">Buyer</label>
										<input
											type="radio"
											id="buyer"
											name="userType"
											value="buyer"
											checked={buyer && !seller}
											onChange={userType}
										/>
									</div>

									<div className="d-flex justify-content-between py-2">
										<label htmlFor="seller">Seller</label>
										<input
											type="radio"
											id="seller"
											name="userType"
											value="seller"
											checked={seller && !buyer}
											onChange={userType}
										/>
									</div>

									<div className="d-flex justify-content-between py-2">
										<label htmlFor="prosumer">Prosumer</label>
										<input
											type="radio"
											id="prosumer"
											name="userType"
											value="prosumer"
											checked={seller && buyer}
											onChange={userType}
										/>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>
				</div>
			</div>

			<hr className="m-0" />
			<div style={{ overflowX: 'hidden' }}>
				<Breadcrumbs />
				{children}
			</div>
		</div>
	);
}
const mapStateToProps = (state) => ({ user: state.user.user });

export default connect(mapStateToProps, { updateUserData })(TopBar);
