import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import { Provider } from 'react-redux';
import { ConfigureStore } from './_redux/configureStore';

// css
import 'react-toastify/dist/ReactToastify.css';

ReactDOM.render(
	<Provider store={ConfigureStore()}>
		<App />
	</Provider>,
	document.getElementById('root')
);
