/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useEffect, useState, Fragment } from 'react';
import Main from '../../Layouts/Main';
import TopBar from '../../Layouts/TopBar/TopBar';
import { Link } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { Tabs, Tab, Row, Col, Form, Button } from 'react-bootstrap';
// import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { getAllProducts } from '../../_redux/actions/ActionMarket';
import Success from '../../Layouts/Success';
import MarketList from './MarketList';
import { FaFilter } from 'react-icons/fa';
import { getProperty } from '../../_redux/actions/ActionUser';
import { postSubsciption } from '../../_redux/actions/ActionPayment';
import { SET_CART_COUNTER } from '../../_redux/ActionTypes';
import Loader from '../../Layouts/Loader/Loader';

const marketStyle = css`
	padding:20px;
	.custom-tabs {
		display: inline-flex;
		background-color: #dcdcdc;
		border-radius: 8px;
		border: 4px solid #fbfbfb;
		margin-bottom: 0;
	}
	.tab-items {
		padding: 5px 20px;
		margin-right: 20px;
		background-color: #cfcfcf;
		border-radius: 8px;
	}
	.tab-items:last-child {
		margin-right: 0 !important;
	}
	.tab-items a {
		color: #222;
	}
	.tab-items a:hover {
		text-decoration: none !important;
	}
	.tab-items.active {
		background-color: #191919;
	}
	.tab-items.active a {
		color: #fff;
	}
	.title-primary {
		font-size: 24px;
		font-weight: bold;
		color: #011941;
		margin-bottom: 10px;
	}
	.services .nav-tabs {
		border: none;
	}
	.services .nav-tabs .nav-link {
		border: none;
		color: #011941;
		opacity: 0.7;
		font-size: 14px;
		padding: 0;
		margin-right: 20px;
	}
	.services .nav-tabs .nav-link.active {
		border: none;
		color: #011941;
		background-color: transparent;
		opacity: 1;
		text-decoration: underline;
	}
	.services .tab-content {
		margin-top: 30px;
	}
	.energy-single {
		border-radius: 15px;
		background-color: #fbfbfb;
		padding: 20px;
		margin-bottom: 30px;
		:hover {
			border: 1px solid black;
		}
		h5 {
			color: #191919;
		}
		p {
			color: #011941;
			font-size: 14px;
			margin-bottom: 5px;
		}
	}
	.energy-img {
		width: 100%;
		height: 180px;
		object-fit: cover;
		margin-bottom: 20px;
		height: 180px;
		object-fit: contain;
	}
	.rating-wrapper {
		color: #cb8700;
		margin-bottom: 10px;
	}

	.filter-text {
		position: absolute;
		top: 0.5rem;
		right: 0.5rem;
		cursor: pointer;
	}
	.filter-container {
		background-color: #d7e2ee;
		z-index: 1;
		margin-top: 2rem;
		width: 15rem;
		border-radius: 10px;
		-webkit-transform: translateX(100%);
		transform: translateX(110%);
		position: absolute;
		top: 0;
		right: 0;
		padding: 0.5rem 0.7rem;
		-webkit-transition: all ease 0.2s;
		transition: all ease 0.2s;
		opacity: 0;
		font-size: 0.9rem;
	}
	.filter-container.open {
		-webkit-transform: translateX(0%);
		transform: translateX(0%);
		opacity: 1;
	}

	.slider {
		-webkit-appearance: none;
		overflow: hidden;
		width: 100%;
		height: 8px;
		border-radius: 5px;
		opacity: 0.7;
		outline: none;
		-webkit-transition: 0.2s;
		transition: opacity 0.2s;
	}

	.slider-left::-webkit-slider-thumb {
		background: #434343;
		box-shadow: -120px 0 0 120px #0075ff;
	}
	.slider-right::-webkit-slider-thumb {
		background: #434343;
		box-shadow: 80px 0 0 80px #0075ff;
	}

	.slider:hover {
		opacity: 1;
	}
	.slider::-webkit-slider-thumb {
		-webkit-appearance: none;
		appearance: none;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		cursor: pointer;
	}

	.slider::-moz-range-thumb {
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background: #4ba1cb;
		cursor: pointer;
	}
	.btn-add {
		background-color: #ffffff;
		color: #333;
		box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
	}
`;

const Market = ({ user, getAllProducts, market, getProperty, postSubsciption, loading, cartCounter}) => {

	const [showModel, setShowModel] = useState(false);
	const [showFilter, setShowFilter] = useState(false);
	const [type, setType] = useState(1);
	const [filtered, setFiltered] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		getAllProducts();
	}, [getAllProducts]);

	useEffect(() => {
		if (user) {
			getProperty(user.email);
		}
	}, [getProperty, user]);

	const { register, handleSubmit, watch } = useForm({
		mode: 'onBlur',
		defaultValues: {},
	});

	const priceMin = watch('price_min');
	const priceMax = watch('price_max');
	const distance = watch('distance_in_meter');

	const handleAddToCart = async (cart, property) => {
		const today = new Date();
		const nextMonth = new Date();
		nextMonth.setMonth(nextMonth.getMonth() + 1);
		const subsription = {
			...property,
			status: 'pending',
			energy_source_id: cart.id,
			auto_renewal: false,
			valid_from: today.toISOString().split('T')[0],
			valid_till: nextMonth.toISOString().split('T')[0],
		};
		var form_data = new FormData();

		for (var key in subsription) {
			console.log(key);
			form_data.append(key, subsription[key]);
		}
		if (await postSubsciption(form_data)){
			dispatch({type:SET_CART_COUNTER, payload: cartCounter + 1})
		};
	};

	const onSubmit = (data) => {
		console.log(data);
		data.energy_type_id = type;
		getAllProducts(data);
		setFiltered(true);
		setShowFilter(false);
	};

	return (
		<Main>
			<TopBar>
				<div css={marketStyle} onClick={() => setShowFilter(false)}>
					<div className="tab-menu">
						<ul className="custom-tabs mb-3">
							{user ? (
								user.is_buyer && user.is_seller ? (
									<Fragment>
										<li className="tab-items active">
											<Link to="/market">Market</Link>
										</li>
										<li className="tab-items">
											<Link to="/market/selling">Energy you are selling</Link>
										</li>
										<li className="tab-items">
											<Link to="/market/buying">Energy you are buying</Link>
										</li>
									</Fragment>
								) : user.is_buyer ? (
									<Fragment>
										<li className="tab-items active">
											<Link to="/market">Market</Link>
										</li>
										<li className="tab-items">
											<Link to="/market/buying">Energy you are buying</Link>
										</li>
									</Fragment>
								) : (
									<Fragment>
										<li className="tab-items active">
											<Link to="/market">Market</Link>
										</li>
										<li className="tab-items">
											<Link to="/market/selling">Energy you are selling</Link>
										</li>
									</Fragment>
								)
							) : null}
						</ul>
						{user && user.is_seller && (
							<Link to="/market/energy-type">
								<Button className="btn btn-add float-right">Add New Energy</Button>
							</Link>
						)}
					</div>

					<div className="shop-section">
						{loading && <Loader />}
						<div className="services position-relative">
							<h1 className="title-primary">Clean Energy</h1>
							<Tabs defaultActiveKey="all" id="uncontrolled-tab-example">
								<Tab eventKey="all" title="All">
									<Row>
										<MarketList market={market} handleAddToCart={handleAddToCart} />
									</Row>
								</Tab>

								{/* <Tab eventKey="geo" title="Geo">
									<Row>
										<MarketList
											market={
												market.length > 0 && market.filter((mar) => mar.energy_type_id === 3)
											}
											handleAddToCart={handleAddToCart}
										/>
									</Row>
								</Tab> */}
								{/* <Tab eventKey="wind" title="Wind">
									<Row>
										<MarketList
											market={
												market.length > 0 && market.filter((mar) => mar.energy_type_id === 2)
											}
											handleAddToCart={handleAddToCart}
										/>
									</Row>
								</Tab> */}
								<Tab eventKey="solar" title="Solar">
									<Row>
										<MarketList
											market={
												market.length > 0 && market.filter((mar) => mar.energy_type_id === 1)
											}
											handleAddToCart={handleAddToCart}
										/>
									</Row>
								</Tab>
								<Tab eventKey="battery" title="Battery">
									<Row>
										<MarketList
											market={
												market.length > 0 && market.filter((mar) => mar.energy_type_id === 2)
											}
											handleAddToCart={handleAddToCart}
										/>
									</Row>
								</Tab>
							</Tabs>
							<div className="filter-text" onClick={(e) => e.stopPropagation()}>
								<p onClick={() => setShowFilter((prev) => !prev)}>
									<FaFilter />{' '}
									{!filtered ? (
										'Filter by'
									) : (
										<span
											onClick={(e) => {
												e.stopPropagation();
												getAllProducts();
												setFiltered(false);
											}}
										>
											Clear
										</span>
									)}
								</p>
								<div className={`filter-container ${showFilter ? 'open' : ''}`}>
									<Form onSubmit={handleSubmit(onSubmit)}>
										<p className="mb-0 font-weight-bold">Price</p>
										<div className="d-flex my-3">
											<div>
												<p className="mb-0 text-center">{priceMin}</p>
												<input
													name="price_min"
													type="range"
													min="1"
													max="100"
													className="slider slider-left"
													id="myRange"
													ref={register}
												/>
											</div>
											<div>
												<p className="mb-0 text-center">{priceMax}</p>
												<input
													name="price_max"
													type="range"
													min="200"
													max="300"
													className="slider slider-right"
													id="myRange"
													ref={register}
												/>
											</div>
										</div>
										<div className="mb-3">
											<p className="mb-0 font-weight-bold">Product filter</p>
											<Form.Group as={Row} id="formGridCheckbox">
												<Col>
													<Form.Check
														type="checkbox"
														label="Solar"
														checked={type === 1}
														onChange={() => setType(1)}
													/>
													{/* <Form.Check
														type="checkbox"
														label="Wind"
														checked={type === 2}
														onChange={() => setType(2)}
													/> */}
												</Col>
												<Col>
													{/* <Form.Check
														type="checkbox"
														label="Geo"
														checked={type === 3}
														onChange={() => setType(3)}
													/> */}
													<Form.Check
														type="checkbox"
														label="Battary"
														checked={type === 4}
														onChange={() => setType(4)}
													/>
												</Col>
											</Form.Group>
										</div>
										<div className="mb-3">
											<p className="mb-0 font-weight-bold">Distance</p>
											<div>
												<p className="mb-0 text-center">{distance}</p>
												<input
													name="distance_in_meter"
													type="range"
													min="1"
													max="100"
													className="slider slider-left"
													id="myRange"
													ref={register}
												/>
											</div>

											<div className="d-flex justify-content-center my-2">
												<Button variant="outline-info small" type="submit">
													Search Now
												</Button>
											</div>
										</div>
									</Form>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* {showModel && (
					<Success
						show={showModel}
						onHide={() => setShowModel(false)}
						setShowModel={setShowModel}
						forLink="/cart"
						buttonText="Go to cart"
						text="Added to cart successfully"
					/>
				)} */}
			</TopBar>
		</Main>
	);
};

const mapStateToProps = (state) => ({
	user: state.user.user,
	market: state.market.market,
	loading: state.ui.loading,
	cartCounter : state.market.cartCounter,
});

const mapDispatchToProps = { getAllProducts, getProperty, postSubsciption };

export default connect(mapStateToProps, mapDispatchToProps)(Market);
