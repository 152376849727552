/** @jsx jsx */
import { jsx, css } from '@emotion/core';

const chartDateStyle = css`
	select,
	select:focus,
	select:active {
		border: none;
		border-bottom: 1px solid #9a9c9f;
		outline: none;
		margin-left: 5px;
		background-color: transparent !important;
		color: #9a9c9f;
		font-size: 12px;
	}
	label {
		font-size: 12px;
	}
`;

const ChartDate = () => {
	return (
		<div css={chartDateStyle}>
			<label htmlFor="year">Year</label>
			<select id="year">
				<option>2011</option>
				<option>2012</option>
				<option>2013</option>
				<option>2014</option>
				<option>2015</option>
				<option>2016</option>
				<option>2017</option>
				<option>2018</option>
				<option>2019</option>
				<option>2020</option>
				<option>2021</option>
				<option>2022</option>
				<option>2023</option>
				<option>2024</option>
				<option>2025</option>
				<option>2026</option>
			</select>
		</div>
	);
};

export default ChartDate;
