import { createStore, combineReducers, applyMiddleware } from 'redux';

import User from './reducers/user';
import UI from './reducers/ui';
import Market from './reducers/market';
import Notification from './reducers/notification';

import thunk from 'redux-thunk';
import logger from 'redux-logger';

export const ConfigureStore = () => {
	const store = createStore(
		combineReducers({
			user: User,
			ui: UI,
			market: Market,
			notification: Notification,
		}),

		applyMiddleware(thunk, logger)
	);
	return store;
};
