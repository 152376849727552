import { fetchWrapper } from '../../_helpers';
import * as ActionTypes from '../ActionTypes';
export const getAllProducts = (data) => async (dispatch) => {
	try {
		dispatch({ type: ActionTypes.LOADING_UI });
		let response;
		if (data) {
			response = await fetchWrapper.get(
				`energy/market?energy_type_id=${data && data.energy_type_id}&distance_in_meter=${
					data && data.distance_in_meter
				}0&price_min=${data && data.price_min}&price_max=${data && data.price_max}`
			);
		} else {
			response = await fetchWrapper.get(`energy/market`);
		}
		dispatch({ type: ActionTypes.SET_ALL_MARKET, payload: response });
		dispatch({ type: ActionTypes.STOP_LOADING_UI });
	} catch (e) {
		dispatch({ type: ActionTypes.STOP_LOADING_UI });
	}
};

export const getAllSellingProducts = () => async (dispatch) => {
	try {
		dispatch({ type: ActionTypes.LOADING_UI });

		let response = await fetchWrapper.get(`energy/selling`);

		dispatch({ type: ActionTypes.SET_ALL_SELLING, payload: response });
		dispatch({ type: ActionTypes.STOP_LOADING_UI });
	} catch (e) {
		dispatch({ type: ActionTypes.STOP_LOADING_UI });
	}
};
export const getAllBuyingProducts = () => async (dispatch) => {
	try {
		dispatch({ type: ActionTypes.LOADING_UI });

		let response = await fetchWrapper.get(`energy/buying`);

		dispatch({ type: ActionTypes.SET_ALL_BUYING, payload: response });
		dispatch({ type: ActionTypes.STOP_LOADING_UI });
	} catch (e) {}
};

export const postAProduct = (product) => async (dispatch) => {
	try {
		const response = await fetchWrapper.post(`energy/energy-source`, product);
		console.log(response);
		return true;
	} catch (e) {
		return false;
	}
};

export const addToCart = (cart) => (dispatch) => {
	dispatch({ type: ActionTypes.ADD_CART, payload: cart });
};

export const removeCart = (cartId) => (dispatch) => {
	dispatch({ type: ActionTypes.REMOVE_CART, payload: cartId });
};

export const getAllEnergyTypes = () => async (dispatch) => {
	try {
		const response = await fetchWrapper.get('energy/');
		dispatch({ type: ActionTypes.SET_ALL_ENERGY_TYPES, payload: response });
	} catch (e) {}
};
