import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useState } from 'react';
import styled from '@emotion/styled';
import { AiFillCamera } from 'react-icons/ai';
import { fetchWrapper } from '../../_helpers';
import { useForm } from 'react-hook-form';
// toasts
import { toast } from 'react-toastify';

const EditProfileButton = styled.div`
	width: 80px;
	display: flex;
	flex-direction: row;
	justify-content: center;

	.edit-btn {
		background-color: #fff;
		font-size: 11px;
		height: 100%;
		border-radius: 14px;
		padding-bottom: 5px;
	}
`;

const Overlay = styled.div`
	height: 150px;
	width: 150px;
	border-radius: 10px;
	background-color: #7f8389;
	position: relative;
`;
const ProfilePic = styled.img`
	height: 150px;
	width: 150px;
	border-radius: 10px;
	opacity: 0.3;
`;
const CamIconLayer = styled.div`
	position: absolute;
	top: 55px;
	left: 60px;
	height: 2rem;
	width: 2rem;
	z-index: 999999;
	cursor: pointer;

	.cam {
		font-size: 2rem;
		color: #f0f0f0;
		&:hover {
			font-size: 2.1rem;
			color: white;
		}
	}
	&:hover {
		height: 2.1rem;
		width: 2.1rem;
	}
	.inputfile {
		width: 0.1px;
		height: 0.1px;
		opacity: 0;
		overflow: hidden;
		position: absolute;
		z-index: -1;
	}
`;
const ProfileLabel = styled.label`
	font-size: 0.8rem;
	margin: 0px;
	vertical-align: middle;
`;

const EditInput = styled.input`
	background-color: #dcdcdc;
	font-size: 0.8rem;
	border: none;
	border-radius: 15px;
	height: 30px;
	margin-left: 10px;
	padding: 10px;
	&::placeholder {
		font-size: 0.8rem;
	}
	&:focus {
		outline: none;
	}
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
`;

const EditProfile = ({ user, updateUserData, getUserData }) => {
	const [openProfileEdit, setOpenProfileEdit] = useState(false);
	const [file, setFile] = useState(null);
	const [loading, setLoading] = useState(false);

	const { register, handleSubmit } = useForm({
		mode: 'onBlur',
	});

	const handleEdit = (newUser) => {
		const tempUser = { ...newUser };
		const data = new FormData();
		for (var key in newUser) {
			if (tempUser[key]) {
				data.append(key, tempUser[key]);
			}
		}
		if (updateUserData(data)) setOpenProfileEdit(false);
	};

	const handleFile = async (e) => {
		setLoading(true);
		const imageFile = e.target.files[0];
		if (imageFile) {
			setFile(imageFile);
			let avatar = new FormData();
			avatar.append('picture', imageFile);
			try {
				await fetchWrapper.put('auth/upload/', avatar, false);
				getUserData();
				toast.success('File Uploaded');
				setLoading(false);
			} catch (e) {
				console.log(e);
				toast.error('Upload Failed');
				setLoading(false);
			}
		}
	};

	return (
		<>
			<EditProfileButton>
				<Button
					className="edit-btn h-75"
					data-toggle="openEditProfile"
					data-target="#EditProfileModal"
					onClick={() => setOpenProfileEdit(true)}
				>
					Edit Profile
				</Button>
			</EditProfileButton>

			<Modal
				show={openProfileEdit}
				onHide={() => setOpenProfileEdit(false)}
				className="d-flex align-items-center"
				animation={false}
			>
				<Modal.Dialog style={{ width: '550px', margin: '0' }}>
					<Form.Group className="p-4">
						<h6 className="mb-2">Profile Picture:</h6>
						<div style={{ margin: '10px 0px 40px 40px' }}>
							<Overlay>
								<CamIconLayer alt="camera">
									<input
										type="file"
										id="changePic"
										accept="image/*"
										class="inputfile"
										onChange={handleFile}
									/>
									<label htmlFor="changePic">
										<AiFillCamera className="cam" />
									</label>
								</CamIconLayer>
								<ProfilePic
									src={
										(file && URL.createObjectURL(file)) ||
										(user && user.picture) ||
										'/images/avatar.png'
									}
								/>
							</Overlay>
						</div>
						<h6 className="mb-2 mt-4">User Name: </h6>
						<div style={{ margin: '10px 0px 40px 40px' }}>
							<div className="d-flex m-1 flex-row align-items-center">
								<ProfileLabel>First Name:</ProfileLabel>
								<EditInput
									type="text"
									name="first_name"
									defaultValue={user.first_name}
									ref={register}
								/>
							</div>
							<div className="d-flex m-1 flex-row align-items-center">
								<ProfileLabel>Last Name:</ProfileLabel>
								<EditInput type="text" name="last_name" defaultValue={user.last_name} ref={register} />
							</div>
						</div>
						<h6 className="mb-2 mt-4">Phone Number: </h6>
						<EditInput
							style={{ width: '300px', margin: '10px 0px 50px 40px' }}
							type="text"
							name="phone"
							defaultValue={user.phone ? user.phone : ''}
							ref={register}
						/>
						<Form.Row className="w-100 d-flex flex-row justify-content-center m-0">
							<Button
								style={{ backgroundColor: '#FA6E02', height: '50px', borderRadius: '10px' }}
								className="btn w-100 text-white"
								type="submit"
								onClick={handleSubmit(handleEdit)}
							>
								DONE
							</Button>
						</Form.Row>
					</Form.Group>
				</Modal.Dialog>
			</Modal>
		</>
	);
};

export default EditProfile;
