/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useEffect, useState } from 'react';
import Main from '../../Layouts/Main';
import TopBar from '../../Layouts/TopBar/TopBar';
import { Button, Card, Row, Col } from 'react-bootstrap';
import PropertyType from './PropertyType';
import { getProperty } from '../../_redux/actions/ActionUser';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import EnergyAllocation from './EnergyAllocation';
import Loader from '../../Layouts/Loader/Loader';

const propertyListStyle = css`
		margin: 0 2rem;
		padding:0px 20px;
	.property-card {
		border-radius: 12px !important;
		color: white;
		background: #4ba1cb;
	}
	.property-image {
		width: 10rem;
		height: 10rem;
		border-radius: 16px;
		object-fit: cover;
	}
	.property-description {
		font-size: 0.9rem;
	}
	.energy-details {
		border-radius: 12px !important;
		background: #cbe4ff;
		font-size: 0.9rem;
	}
	.btn-add {
		background-color: #ffffff;
		color: #333;
		box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
	}

	.show-less-energy {
		border-radius:10px;
		height:240px;
		margin-right:15px;
		overflow-y:scroll;
	}

	.show-less-energy::-webkit-scrollbar {
	display: none;
	}

	.show-more-energy {
		border-radius:10px;
		height:auto;
		margin-right:15px;
	}
	.btns {
		display:block;
		background-color:#CBE4FF;
		border:none;
		border-radius:10px;
		padding:5px 15px;
		margin:10px auto;
	}
	.btns:hover {
		background-color:#b2cdeb;
	}
`;

function Property({ getProperty, user, property, isLoading }) {
	const [modalShow, setModalShow] = useState(false);
	

	useEffect(() => {
		if (user) {
			getProperty(user.email);
		}
	}, [getProperty, user]);

	return (
		<Main>
			<TopBar>
				{isLoading && <Loader/>}
				<div css={propertyListStyle}>
					<div className="text-center mt-2 mb-3">
						<Button className="btn btn-add" onClick={() => setModalShow(true)}>
							Add new property
						</Button>
						<PropertyType show={modalShow} onHide={() => setModalShow(false)} />
					</div>
					{property.length > 0 &&
						property.sort(function(a, b) {
							return new Date(b.updated_date) - new Date(a.updated_date);
						}).map((pro) => (	
								
							<Card className="mb-3 p-4 property-card " key={pro.id}>
								<Row>
									<Col md={4} className="p-2">
										<h4 className="font-weight-bold">{pro.name}</h4>
										<div className="py-3">
											<img
												src={pro.picture || '/images/tips.jpg'}
												alt="property"
												className="property-image"
											/>
										</div>
										<div className="property-description">
											<p className="mb-0">Type : {pro.property_type}</p>
											<p className="mb-0">Average Consumption : {pro.avg_energy_consumption}</p>
											<p className="mb-0">
												Total number of energy : {pro['Energy_Sources'].length}
											</p>
											<p className="mb-0">Meter Covered : {pro.current_meter_reading}</p>
										</div>
									</Col>
									<Col md={8}>
										<div className="d-flex justify-content-between">
											<p>Energy allocation: </p>
											<Link to={`/property/form/${pro.property_type}/edit/${pro.id}`}> Edit</Link>
										</div>
										<EnergyAllocation energies={pro}/>
									</Col>
								</Row>
							</Card>
						))}
				</div>
			</TopBar>
		</Main>
	);
}

const mapStateToProps = (state) => ({
	user: state.user.user,
	property: state.user.property,
	isLoading: state.user.isPropertyLoading
});
export default connect(mapStateToProps, { getProperty })(Property);
