/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Card, Form, Container, Row, Col, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import Main from '../../Layouts/Main';
import TopBar from '../../Layouts/TopBar/TopBar';
import { FaEdit } from 'react-icons/fa';
import { fetchWrapper } from '../../_helpers';
const checkoutForm = css`
	width: 80%;
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		-webkit-background-clip: text;
		background-clip: text;
	}

	input,
	input:focus,
	textarea {
		border-top-style: hidden;
		border-right-style: hidden;
		border-left-style: hidden;
		border-bottom-style: groove;
		background-color: transparent !important;
		font-size: 1rem;
		outline: none !important;
		border: 1px 0px 0px 0px solid black;
	}

	hr {
		color: #000;
		width: 100%;
	}
	.card {
		width: 8rem;
		border-radius: 9px;
		font-size: 0.8rem;
		text-align: center;
		background-color: #006191;
		color: #ffffff;
		line-height: 1;
		padding: 0.4rem;
		cursor: pointer;
	}
`;

const bigButtons = css`
	margin: 1rem;
	font-size: 1.3rem;
	width: 50%;
	height: 3.5rem;
	border-radius: 10px;
	letter-spacing: normal;
	font-weight: 700;
	background-color: #e97724;
	color: white;
`;

const padding = css`
	padding: 1.81rem 2rem 1.81rem 2rem;
`;

function Checkout({ user, match }) {
	const { register, handleSubmit, errors } = useForm({
		mode: 'onBlur',
		defaultValues: {},
	});

	const { id, amount, invoiceId } = match.params;
	const history = useHistory();

	// useEffect(() => {
	// 	if (user) {
	// 		var form_data = new FormData();
	// 		form_data.append('email', user.email);
	// 		form_data.append('amount', 200);
	// 		form_data.append('subscription_id', subId);

	// 		fetchWrapper.post('payment/invoices', form_data).then((res) => setInvoice(res));
	// 	}
	// }, [subId, user]);

	const onSubmit = (data) => {
		if (invoiceId && id) {
			var form_data = new FormData();
			form_data.append('email', user.email);
			form_data.append('invoice_id', invoiceId);
			form_data.append('amount', data.amount);

			form_data.append('payment_method', data.payment_method);
			fetchWrapper.post(`payment/${id}/pay`, form_data).then((res) => {
				console.log(res, "rhere")
				history.push('/billing/invoice/', {payId : res.id});
			});
			
		}
	};
	return (
		<Main>
			<TopBar>
				<Container className="d-flex justify-content-center mt-2">
					<Card css={checkoutForm}>
						<div css={padding} className="position-relative ">
							<Form onSubmit={handleSubmit(onSubmit)} className="p-2">
								<div className="d-flex justify-content-between">
									<p className="font-weight-bold ">Preferred payment method</p>
									<FaEdit />
									{/* <Link to="/cart/checkout/saved-payment">
										<Card>
											<p className="mb-0">use saved</p>
											<p className="mb-0">payment method</p>
										</Card>
									</Link> */}
								</div>
								{/* <Form.Group as={Row} controlId="formPlaintextSelects">
									<Form.Label className="text-right" column sm="4">
										Name on the Card
									</Form.Label>
									<Col sm="8">
										<Form.Control
											type="text"
											name="name"
											isInvalid={errors.name}
											ref={register}
											placeholder="Enter the name of the Card"
										/>

										<Form.Text className="text-danger">{errors.name?.message}</Form.Text>
									</Col>
								</Form.Group>

								<Form.Group as={Row} controlId="formPlaintextBankName">
									<Form.Label className="text-right" column sm="4">
										Card number
									</Form.Label>
									<Col sm="8">
										<Form.Control
											type="text"
											name="meter_box_id"
											isInvalid={errors.meter_box_id}
											ref={register}
											placeholder="Enter Card Number"
										/>

										<Form.Text className="text-danger">{errors.meter_box_id?.message}</Form.Text>
									</Col>
								</Form.Group>

								<Form.Group as={Row} controlId="formPlaintextRoutingNumber">
									<Form.Label className="text-right" column sm="4">
										Expiration Date
									</Form.Label>
									<Col sm="4">
										<Form.Control
											type="date"
											name="customer_no"
											isInvalid={errors.customer_no}
											ref={register}
										/>
										<Form.Text className="text-danger">{errors.customer_no?.message}</Form.Text>
									</Col>
									<Col sm="4">
										<Form.Control type="date" name="customer_no" isInvalid={errors.customer_no} />

										<Form.Text className="text-danger">{errors.customer_no?.message}</Form.Text>
									</Col>
								</Form.Group> */}
								{/* <Form.Group as={Row} controlId="formPlaintextAccountNumber">
									<Form.Label className="text-right" column sm="4">
										Verification Code
									</Form.Label>
									<Col sm="8">
										<Form.Control
											type="text"
											name="avg_energy_consumption"
											isInvalid={errors.avg_energy_consumption}
											ref={register}
											placeholder="Enter Verification code from card"
										/>

										<Form.Text className="text-danger">
											{errors.avg_energy_consumption?.message}
										</Form.Text>
									</Col>
								</Form.Group> */}

								<Form.Group as={Row} controlId="formPlaintextBankName">
									<Form.Label className="text-right" column sm="4">
										Payment Type
									</Form.Label>
									<Col sm="8">
										<Form.Control
											as="select"
											type="text"
											name="payment_method"
											isInvalid={errors.payment_method}
											ref={register}
										>
											<option value="paypal">Paypal</option>
											<option value="p2ptoken">P2p Token</option>
											<option value="others">Others</option>
											p2ptoken
										</Form.Control>
									</Col>
								</Form.Group>
								<Form.Group as={Row} controlId="formPlaintextBankName">
									<Form.Label className="text-right" column sm="4">
										Amount
									</Form.Label>
									<Col sm="8">
										<Form.Control
											type="text"
											name="amount"
											defaultValue={amount}
											ref={register}
											readOnly
										/>
									</Col>
								</Form.Group>

								<hr />
								<div className="d-flex justify-content-between">
									<p className="font-weight-bold ">Billing Address</p>
									<FaEdit />
								</div>
								<Form.Group as={Row} controlId="formPlaintextAccountNumber">
									<Form.Label className="text-right" column sm="4">
										Address 1
									</Form.Label>
									<Col sm="8">
										<Form.Control
											type="text"
											name="address1"
											isInvalid={errors.address1}
											ref={register}
											placeholder="Enter your address 1"
										/>

										<Form.Text className="text-danger">{errors.address1?.message}</Form.Text>
									</Col>
								</Form.Group>
								<Form.Group as={Row} controlId="formPlaintextAccountNumber">
									<Form.Label className="text-right" column sm="4">
										Address 2
									</Form.Label>
									<Col sm="8">
										<Form.Control
											type="text"
											name="address2"
											isInvalid={errors.address2}
											ref={register}
											placeholder="Enter your address 2"
										/>

										<Form.Text className="text-danger">{errors.address2?.message}</Form.Text>
									</Col>
								</Form.Group>
								<Form.Group as={Row} controlId="formPlaintextAccountNumber">
									<Form.Label className="text-right" column sm="4">
										City
									</Form.Label>
									<Col sm="8">
										<Form.Control
											type="text"
											name="city"
											isInvalid={errors.city}
											ref={register}
											placeholder="Enter your city"
										/>

										<Form.Text className="text-danger">{errors.city?.message}</Form.Text>
									</Col>
								</Form.Group>
								<Form.Group as={Row} controlId="formPlaintextAccountNumber">
									<Form.Label className="text-right" column sm="4">
										Choose your state
									</Form.Label>
									<Col sm="8">
										<Form.Control
											as="select"
											type="text"
											name="state"
											isInvalid={errors.state}
											ref={register}
											placeholder="Enter your address 1"
										>
											<option>state</option>
										</Form.Control>

										<Form.Text className="text-danger">{errors.state?.message}</Form.Text>
									</Col>
								</Form.Group>
								<Form.Group as={Row} controlId="formPlaintextAccountNumber">
									<Form.Label className="text-right" column sm="4">
										Enter your Zip code
									</Form.Label>
									<Col sm="8">
										<Form.Control
											type="text"
											name="zip_code"
											isInvalid={errors.zip_code}
											ref={register}
											placeholder="Zip code"
										/>

										<Form.Text className="text-danger">{errors.zip_code?.message}</Form.Text>
									</Col>
								</Form.Group>

								<div className="text-center my-3">
									<Button variant="secondary" type="submit" css={bigButtons}>
										Confirm Payment
									</Button>

									<Form.Group controlId="formBasicCheckbox">
										<Form.Check
											type="checkbox"
											label="save this payment method for future payment process"
										/>
									</Form.Group>
								</div>
							</Form>
						</div>
					</Card>
				</Container>
			</TopBar>
		</Main>
	);
}
const mapStateToProps = (state) => ({
	user: state.user.user,
});
export default connect(mapStateToProps)(Checkout);
