import React from 'react';
import styled from '@emotion/styled';


const TipsDesc = styled.p`
	float:left;
    height:210px;
	font-size:12px;
    color:#b4d7fe;
    padding:2px;
`;

const Img = styled.img`
	float:right;
	width: 100px;
	font-size:12px;
    border-radius: 10px;
    @media (max-width: 990px) {
        display:none;
    }
`;

const Tips = ({title, image}) => {
    return (
        <>
            <p className="font-weight-bold mb-0">{title}</p>
            <div>
                <TipsDesc>
                    <Img src={image}  alt="tips" />
                        Opening windows creates a cross-wise breeze, allowing you to naturally cool your home
                        without switching on air conditioners. This is an ideal tactic in spring when temperatures
                        are mild...
                </TipsDesc>
            </div>
        </>
    )
}

export default Tips
