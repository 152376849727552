/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const propertyTypeStyle = css`
	.btn-dark-blue {
		background-color: #4ba1cb !important;
		padding: 14px 40px !important;
		color: #fff !important;
	}
`;

function PropertyType(props) {
	return (
		<Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered css={propertyTypeStyle}>
			<Modal.Body className="p-5">
				<div className="text-center">
					<h6>Choose your property type</h6>
					<div className="d-flex justify-content-around p-2">
						<Link className="text-decoration-none" to={`/property/form/residential/create`}>
							<Button className="p-2 btn-dark-blue">Residential</Button>
						</Link>
						<Link className="text-decoration-none" to="/property/form/commercial/create">
							<Button className="p-2 btn-dark-blue">Commercial</Button>
						</Link>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
}

export default PropertyType;
