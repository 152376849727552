/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useEffect, useRef, useState } from 'react';
import { Row, Container, Form, Button, Col } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

// components

// styled elements
// import loaderStyles from '../../_elements/loader';

//redux
import { connect } from 'react-redux';
import { registerUser } from '../../_redux/actions/ActionAuthentication';
import { regValidationSchema } from '../../_helpers';

const login = css`
	min-height: 100vh;
	.login-container {
		border-radius: 10px;
		width: 30rem;
	}
	.form-group {
		margin-bottom: 2rem;
	}
	.password-inputarea {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.field-icon {
		float: right;
		margin-left: -25px;
		position: relative;
		z-index: 2;
	}
	.heading {
		font-weight: 500;
		font-size: 24px;
		color: #002b71;
	}
	.sm-title {
		color: #002b71;
	}
`;
const bigButtons = css`
	margin: 0.8rem 1rem;
	font-size: 1.3rem;
	width: 50%;
	height: 3.5rem;
	border-radius: 10px;
	letter-spacing: normal;
	font-weight: 700;
	background-color: #e97724;
	color: white;
`;

const padding = css`
	padding: 1.81rem 2rem 1.81rem 2rem;
`;

export const Register = ({ registerUser }) => {
	//Skipping first iteration (exactly like componentWillReceiveProps):
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	const isFirstRun = useRef(true);
	const history = useHistory();
	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false;
			return;
		}
	}, []);

	const { register, handleSubmit, errors } = useForm({
		mode: 'onBlur',
		resolver: yupResolver(regValidationSchema),
	});

	// const password = watch('password');
	// const confirmPassword = watch('confirmPassword');

	const onSubmit = (user) => {
		console.log(user);
		const tempUser = {
			first_name: user.first_name,
			last_name: user.last_name,
			email: user.email,
			password: user.password,
			re_password: user.confirmPassword,
			is_buyer: user.is_buyer,
			is_seller: user.is_seller,
		};
		registerUser(tempUser, history);
	};

	return (
		<Container css={login} fluid className="bg-primary">
			<div className="d-flex justify-content-center wrapper">
				<div css={padding} className="position-relative login-container">
					<div className="text-center">
						<img className="mb-5 h-auto" style={{ width: '5rem' }} src="/images/p2p_vert.png" alt="logo" />
						<h1 className="mb-4 heading">Create account</h1>
					</div>

					<Form onSubmit={handleSubmit(onSubmit)}>
						<Row>
							<Col md={6}>
								<Form.Group controlId="formBasicFirstName" col="6">
									<div class="floating-label">
										<input
											class="floating-input"
											type="text"
											placeholder=" "
											isInvalid={errors.first_name}
											ref={register}
											name="first_name"
										/>
										<span class="highlight"></span>
										<label>First Name</label>
									</div>
									<Form.Text className="text-danger">{errors.first_name?.message}</Form.Text>
								</Form.Group>
							</Col>
							<Col md={6}>
								<Form.Group controlId="formBasicLastName">
									<div class="floating-label">
										<input
											class="floating-input"
											type="text"
											placeholder=" "
											isInvalid={errors.last_name}
											ref={register}
											name="last_name"
										/>
										<span class="highlight"></span>
										<label>Last Name</label>
									</div>
									<Form.Text className="text-danger">{errors.last_name?.message}</Form.Text>
								</Form.Group>
							</Col>
						</Row>
						<Form.Group controlId="formBasicEmail">
							<div class="floating-label">
								<input
									class="floating-input"
									type="email"
									name="email"
									placeholder=" "
									isInvalid={errors.email}
									ref={register}
								/>
								<span class="highlight"></span>
								<label>Email Address</label>
							</div>
							<Form.Text className="text-danger">{errors.email?.message}</Form.Text>
						</Form.Group>
						<Form.Group controlId="formBasicPassword">
							<div className="password-inputarea">
								<div class="floating-label">
									<input
										class="floating-input"
										type={showPassword ? 'text' : 'password'}
										placeholder=" "
										isInvalid={errors.password}
										name="password"
										ref={register}
									/>
									<span class="highlight"></span>
									<label>Password</label>
								</div>
								<span className="field-icon" onClick={() => setShowPassword((prev) => !prev)}>
									{showPassword ? <FaEye /> : <FaEyeSlash />}
								</span>
							</div>
							<Form.Text className="text-danger">{errors.password?.message}</Form.Text>
						</Form.Group>
						<Form.Group controlId="formBasicPassword2">
							<div className="password-inputarea">
								<div class="floating-label">
									<input
										class="floating-input"
										type={showConfirmPassword ? 'text' : 'password'}
										placeholder=" "
										isInvalid={errors.confirmPassword}
										name="confirmPassword"
										ref={register}
									/>
									<span class="highlight"></span>
									<label>Confirm Password</label>
								</div>
								<span className="field-icon" onClick={() => setShowConfirmPassword((prev) => !prev)}>
									{showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
								</span>
							</div>
							<Form.Text className="text-danger">{errors.confirmPassword?.message}</Form.Text>
						</Form.Group>
						<div className="d-flex justify-content-between">
							<div>
								<p className="sm-title m-0">You want to</p>
								<Form.Group className="m-0" controlId="formEnergyBuyer">
									<Form.Check
										label="Buy clean energy"
										type="checkbox"
										name="is_buyer"
										ref={register}
									/>
								</Form.Group>
								<Form.Group className="m-0" controlId="formEnergySeller">
									<Form.Check
										label="Sell clean energy"
										type="checkbox"
										name="is_seller"
										ref={register}
									/>
								</Form.Group>
								<Form.Text className="text-danger">{errors.sellerBuyer?.message}</Form.Text>
							</div>
							<Button
								css={bigButtons}
								className="text-uppercase"
								variant="secondary"
								type="submit"
								size="lg"
							>
								sign up
							</Button>
						</div>

						<p className="text-center mt-5">
							Already have an account?{' '}
							<Link className="text-secondary" to="/login">
								Sign In
							</Link>
						</p>
					</Form>
				</div>
			</div>
		</Container>
	);
};

Register.propTypes = {};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { registerUser };

export default connect(mapStateToProps, mapDispatchToProps)(Register);
