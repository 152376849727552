/** @jsx jsx */
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import { useState, useEffect, Fragment } from 'react';

import { connect } from 'react-redux';
import GoogleMap from './GoogleMap';

const GeoLocation = ({ position, errorMessage }) => {
	const [pos, setPos] = useState(null);
	const [error, setError] = useState('');

	useEffect(() => {
		if (position) {
			setPos(position);
		} else if (errorMessage) {
			setError(errorMessage);
		}
	}, [position, errorMessage]);

	return (
		<Fragment>
			{pos && <GoogleMap lat={pos.coords.latitude} lng={pos.coords.longitude} />}
			{error && (
				<h1
					className="position-relative"
					style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)', fontSize:'16px'}}
					
				>
					{error}
				</h1>
			)}
		</Fragment>
	);
};

GeoLocation.defaultProps = {};

const mapStateToProps = (state) => ({
	position: state.user.position,
	errorMessage: state.user.errorMessage,
});

GeoLocation.propTypes = {
	position: PropTypes.object,
	errorMessage: PropTypes.string,
};

export default connect(mapStateToProps)(GeoLocation);
