import * as ActionTypes from '../ActionTypes';

const initialState = {
	loading: false,
	message: null,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case ActionTypes.SET_MESSAGE:
			return {
				...state,
				loading: false,
				message: action.payload,
			};
		case ActionTypes.CLEAR_MESSAGE:
			return {
				...state,
				loading: false,
				message: null,
			};
		case ActionTypes.LOADING_UI:
			return {
				...state,
				loading: true,
			};
		case ActionTypes.STOP_LOADING_UI:
			return {
				...state,
				loading: false,
			};
		default:
			return state;
	}
}
