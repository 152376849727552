/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import Main from '../../Layouts/Main';
import { Card, Col, Row, Button } from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';
import TopBar from '../../Layouts/TopBar/TopBar';
import { removeCart } from '../../_redux/actions/ActionMarket';
import { BiCart } from 'react-icons/bi';
import { getAllSubsciption, getAllInvoice } from '../../_redux/actions/ActionPayment';
import { getAllProducts } from '../../_redux/actions/ActionMarket';
import { fetchWrapper } from '../../_helpers';
import Confirm from '../../Layouts/Confirm';
import Loader from '../../Layouts/Loader/Loader';

const cartStyle = css`
	.underline {
		border-bottom: 2px solid currentColor;
		display: inline-block;
		line-height: 1;
	}
	.status-card {
		padding: 19px 17px;
		border-radius: 18px;
		box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.35);
		background-color: #ffffff;
	}
	.status-message {
		font-size: 15px;
		line-height: 1.2;
		text-align: center;
		margin: 0.2rem 0;
	}
	.accepted {
		color: #009e05;
	}
	.pending {
		color: #be0404;
	}
	.rejected {
		color: #d41d1d;
	}
`;
const bigButtons = css`
	font-size: 1rem;
	border-radius: 10px;
	color: white;
	border: solid 1px #fbfbfb;
	background-color: #006191;
	padding: 0.2rem 1rem;
`;

const Cart = ({ getAllSubsciption, getAllProducts, market, user, loading, getAllInvoice }) => {
	const history = useHistory();
	const [subsription, setSubscription] = useState([]);
	const [showConfirmModal, setShowConfirmModal] = useState('');
	const [invoices, setInvoices] = useState([]);

	useEffect(() => {
		getAllProducts();
		(async () => { 
			try {
				const response = await getAllSubsciption();
				console.log(response);
				setSubscription(response ? response : []);
			}
			catch (e) {
				console.log(e)
			}
		})()
		
	}, [getAllSubsciption, getAllProducts]);

	useEffect(() => {	
		(async () => {
			try {
				const response = await getAllInvoice();
				setInvoices(response)
			}catch(e) {
				console.log(e)
			}
		})()
	}, [getAllInvoice]);

	const handleInvoice = async (id) => {
		if (user) {
			var form_data = new FormData();
			form_data.append('email', user.email);
			form_data.append('amount', 200);
			form_data.append('subscription_id', id);

			await fetchWrapper
				.post('payment/invoices', form_data)
				.then((res) => history.push('/cart/checkout/payment/success'));
		}
	};
	const handleDelete = async (subId) => {
		fetchWrapper.delete(`payment/delete_subscription/${subId}`).then((res) => {
			setSubscription((prev) => {
				const subs = [...prev];
				const index = subs.findIndex((sub) => sub.id === subId);
				subs.splice(index, 1);
				return subs;
			});
			setShowConfirmModal('');
		});
	};

	const getEnegry = (subs) => {
		const energy = market.find((mar) => mar.id === subs.energy_source_id);
		const invoice = invoices.find((invoice) => invoice.subscription_id === subs.id);
		return (
			<Row>
				<Col lg="4">
					<Row>
						<Col md={6}>
							<img
								src={subs && subs.picture ? subs.picture : '/images/item1.jpg'}
								alt="solar"
								style={{ width: '8rem', height: '6rem' }}
							/>
						</Col>
						<Col md={6}>
							<p className="font-weight-bold m-0">{energy && energy.name}</p>
							<p className="m-0">
								{energy && energy.price_per_kwh}$ monthly,{energy && energy.payment_type}
							</p>
							<p className="m-0">{energy && energy.sell_percent}% energy</p>
						</Col>
					</Row>
				</Col>
				<Col lg="4">
					<p className="m-0">Supplier: {energy && energy.supplier}</p>
					<p className="m-0">Capacity: {energy && energy.net_energy_metering}</p>
				</Col>
				<Col lg="4">
					<Card className="status-card">
						{subs.status === 'accepted' ? (
							<Fragment>
								<div className="status-message accepted">
									<p className="mb-0 text-center"> Seller has </p>
									<p className="mb-0 text-center"> accepted your request </p>
								</div>
								<Button
									css={bigButtons}
									className="text-center"
									onClick={() => handleInvoice(subs.id)}
									disabled={invoice}
								>
									{invoice ? 'Checked out' : 'Proceed to checkout'}
								</Button>
							</Fragment>
						) : subs.status === 'pending' ? (
							<Fragment>
								<div className="status-message pending">
									<p className="mb-0 text-center"> Seller hasn't </p>
									<p className="mb-0 text-center"> accepted your request </p>
								</div>
								<div css={bigButtons} className="text-center">
									Pending...
								</div>
							</Fragment>
						) : (
							<Fragment>
								<div className="status-message rejected">
									<p className="mb-0 text-center"> Sorry, seller has </p>
									<p className="mb-0 text-center"> rejected your request </p>
								</div>

								<div css={bigButtons} className="text-center bg-danger">
									Rejected
								</div>
							</Fragment>
						)}
					</Card>
				</Col>
			</Row>
		);
	};

	return (
		<Main>
			<TopBar>
				{loading ? (
					<Loader />
				) : (
					<div className="h-100 position-relative" css={cartStyle}>
						{/* {console.log(subsription.length)} */}
						{subsription.length > 0 ? (
							subsription.sort(function(a, b) {
								return new Date(b.created_date) - new Date(a.created_date);
							}).map((car) => (
								<Card
									style={{
										borderRadius: '16px',
										margin: '0 1rem .5rem 1rem',
										padding: '.2rem 1rem 0 1rem',
									}}
									key={car.id}
								>
									<Card.Body>
										{car.status === "pending" && 
										<span
											style={{
												position: 'absolute',
												right: 15,
												top: 15,
												cursor: 'pointer',
												fontSize: '1.2rem',
												color: 'red',
											}}
											onClick={() => setShowConfirmModal(car.id)}
										>
											X
										</span>
										}
										{getEnegry(car)}
									</Card.Body>
								</Card>
							))
						) : (
							<div>
								<div className="d-flex align-items-center justify-content-center text-danger">
									<BiCart size="46" className="px-2" /> <span>Cart is currently empty</span>
								</div>
								<div className="d-flex align-items-center justify-content-center mt-5">
									<Link className="text-decoration-none" to={`/market`}>
										<Button className="p-2" css={bigButtons}>
											Discover Clean Energy
										</Button>
									</Link>
								</div>
							</div>
						)}

						{showConfirmModal && (
							<Confirm
								show={showConfirmModal}
								onHide={() => setShowConfirmModal(false)}
								setShowModel={setShowConfirmModal}
								text="Do you want to cancel this order?"
								setSubscription={setSubscription}
								handleDelete={handleDelete}
							/>
						)}

						{/* {cart.length > 0 && (
				<div className="d-flex align-items-center" style={{ margin: '1.5rem 1rem .5rem 1rem' }}>
					<div className="mt-4 mr-3">
						<p className="underline">
							<span className="font-weight-bolder h5 pr-2">Subtotal</span>
							<span className="text-secondary font-weight-bolder px-4">
								{cartPrice()}$/monthly
							</span>
						</p>
					</div>
					<Link to="/cart/checkout">
						<Button css={bigButtons}>Proceed to checkout</Button>
					</Link>
				</div>
			)} */}
					</div>
				)}
			</TopBar>
		</Main>
	);
};

const mapStateToProps = (state) => ({
	market: state.market.market,
	user: state.user.user,
	loading: state.ui.loading,
});

const mapDispatchToProps = { removeCart, getAllSubsciption, getAllProducts, getAllInvoice };

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
