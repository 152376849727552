import React from 'react';
import { Slide } from 'pure-react-carousel';
import { Row, Col } from 'react-bootstrap';
import { FaSolarPanel, FaStar } from 'react-icons/fa';
import { getDistanceFromLatLonInM, round } from '../../../_helpers';
import styled from '@emotion/styled';
import { getUserData } from '../../../_redux/actions/ActionUser';
import { connect } from 'react-redux';

const Details = styled.div`
	line-height: 25px;
	font-size: 16px;
	color: #011941;
`;

const PaymentDetail = styled.div`
	line-height: 25px;
	font-size: 16px;
	font-weight: bold;
	color: #011941;
`;

const EachEnergyBuying = ({ items, i, position }) => {
	return (
		<Slide index={i / 2}>
			<div className="energy-single mx-2">
				<Row>
					<Col md={7}>
						<h5 style={{ color: '#011941', margin: '0' }}>
							<FaSolarPanel />
							<span className="ml-2">{items[i].energy_name}</span>
						</h5>
						<div className="rating-wrapper">
							<FaStar />
							<FaStar />
							<FaStar />
							<FaStar />
							<FaStar />
						</div>

						<Details>
							Supplier:
							<span className="pl-2">{items[i].supplier}</span>
							<br />
							Energy used:
							<span className="pl-2">{items[i].energy_used}%</span>
							<br />
							Distance:
							{" "}
							{round(
								getDistanceFromLatLonInM(
									position?.latitude,
									position?.longitude,
									items[i].location_distance?.lat,
									items[i].location_distance?.lon
								)/1609.34,
								2
							)} {"miles"}
							<span className="pl-2"></span>
						</Details>
					</Col>
					<Col md={5}>
						<img src={items[i].image} className="energy-img img-responsive float-right" alt="energy" />
					</Col>
					<Col md={12}>
						<Row>
							<Col md={4}>
								<PaymentDetail>{items[i].selling}$ monthly</PaymentDetail>
								<PaymentDetail>{items[i].Payment_method}</PaymentDetail>
							</Col>
							<Col md={4}>
								<PaymentDetail className="font-weight-bold text-center" style={{ fontSize: '16px' }}>
									{items[i].payment_type}
								</PaymentDetail>
							</Col>
						</Row>
					</Col>
				</Row>
			</div>
			{items[i + 1] && (
				<div className="energy-single mx-2">
					<Row>
						<Col md={7}>
							<h5 style={{ color: '#011941', margin: '0' }}>
								<FaSolarPanel />
								<span className="ml-2">{items[i + 1].energy_name}</span>
							</h5>
							<div className="rating-wrapper">
								<FaStar />
								<FaStar />
								<FaStar />
								<FaStar />
								<FaStar />
							</div>
							<Details>
								Supplier:
								<span className="pl-2">{items[i + 1].supplier}</span>
								<br />
								Energy used:
								<span className="pl-2">{items[i + 1].energy_used}%</span>
								<br />
								Distance:
								<span className="pl-2">
									{" "}
									{round(
										getDistanceFromLatLonInM(
											position?.latitude,
											position?.longitude,
											items[i+1].location_distance?.lat,
											items[i+1].location_distance?.lon
										),
										2
									)} {"miles"}
								</span>
							</Details>
						</Col>
						<Col md={5}>
							<img
								src={items[i+1].image}
								className="energy-img img-responsive float-right"
								alt="energy"
							/>
						</Col>
						<Col md={12}>
							<Row>
								<Col md={4}>
									<PaymentDetail>{items[i + 1].selling}$ monthly</PaymentDetail>
									<PaymentDetail>{items[i + 1].Payment_method}</PaymentDetail>
								</Col>
								<Col md={4}>
									<PaymentDetail
										className="font-weight-bold text-center"
										style={{ fontSize: '16px' }}
									>
										{items[i + 1].payment_type}
									</PaymentDetail>
								</Col>
							</Row>
						</Col>
					</Row>
				</div>
			)}
		</Slide>
	);
};
const mapStateToProps = (state) => ({
	position: state.user.position?.coords,
});
const mapDispatchToProps = { getUserData };

export default connect(mapStateToProps, mapDispatchToProps)(EachEnergyBuying);