import { fetchWrapper } from '../../_helpers';
import * as ActionTypes from '../ActionTypes';
import { toast } from 'react-toastify';

export const getUserData = () => async (dispatch) => {
	try {
		dispatch({ type: ActionTypes.LOADING_UI });
		const response = await fetchWrapper.get(`auth/users/me`);
		dispatch({ type: ActionTypes.SET_USER, payload: response });
		dispatch({ type: ActionTypes.STOP_LOADING_UI });
		return true;
	} catch (e) {
		if (e.code === 401) {
			localStorage.removeItem('token');
			dispatch({ type: ActionTypes.SET_LOGGEDOUT });
		}
		dispatch({ type: ActionTypes.STOP_LOADING_UI });
		return false;
	}
};

export const updateUserData = (user) => async (dispatch) => {
	try {
		console.log(user);
		const response = await fetchWrapper.patch(`auth/users/me`, user);
		dispatch({ type: ActionTypes.SET_USER, payload: response });
		toast.success("Profile Edited Successfully")
		return true;
	} catch (e) {
		toast.error("Profile Edit Failed")
		console.log(e);
		return false;
	}
};

export const getProperty = (email) => async (dispatch) => {
	try {
		dispatch({ type: ActionTypes.LOADING_UI_PROPERTY });
		const response = await fetchWrapper.get(`property/get_energies`);
		dispatch({ type: ActionTypes.SET_ALL_PROPERTY, payload: response });
		dispatch({ type: ActionTypes.STOP_LOADING_UI_PROPERTY });
	} catch (e) {
		dispatch({ type: ActionTypes.STOP_LOADING_UI_PROPERTY });
	}
};

export const postProperty = (newProperty) => async (dispatch) => {
	try {
		const response = await fetchWrapper.post(`property/`, newProperty);
		console.log(response);
		return response;
	} catch (e) {
		return false;
	}
};

export const editProperty = (id, newProperty) => async (dispatch) => {
	try {
		const response = await fetchWrapper.put(`property/${id}`, newProperty);
		console.log(response);
		return response;
	} catch (e) {
		return e;
	}
};

export const setUserLocation = () => (dispatch) => {
	if (navigator.geolocation) {

		navigator.geolocation.getCurrentPosition(showPosition, showError, {
			timeout: 10000,
			maximumAge: 1000,
			enableHighAccuracy: true,
		});
	}
	function showPosition(position) {
		dispatch(setPos(position));
	}

	function showError(error) {
		switch (error.code) {
			case error.PERMISSION_DENIED:
				dispatch(setError('User denied the request for Geolocation.'));
				break;
			case error.POSITION_UNAVAILABLE:
				dispatch(setError('Location information is unavailable.'));
				break;
			case error.TIMEOUT:
				dispatch(setError('The request to get user location timed out.'));
				break;
			case error.UNKNOWN_ERROR:
				dispatch(setError('An unknown error occurred.'));
				break;
			default:
				dispatch(setError('An unknown error occurred.'));
		}
	}
};

export const getAllNotification = () => async (dispatch) => {
	try {
		const response = await fetchWrapper.get(`notifications/`);
		console.log('notification', response);
		dispatch({ type: ActionTypes.SET_ALL_NOTIFICATION, payload: response });
	} catch (e) {}
};

const setError = (error) => ({
	type: ActionTypes.SET_POS_ERROR,
	payload: error,
});

const setPos = (position) => ({
	type: ActionTypes.SET_POSITION,
	payload: position,
});
