import Home from './components/Home/Home';

import Admin from './components/Admin/Admin';

import Profile from './components/Profile/Profile';

import Market from './components/Market/Market';
import BillingInfo from './components/Market/BillingInfo';
import EnergyType from './components/Market/EnergyType';
import EnergyDetailForm from './components/Market/EnergyDetailForm';
import ProductDetail from './components/Market/ProductDetail';
import SellingPage from './components/Market/EnergySelling/SellingPage';
import BuyingPage from './components/Market/EnergyBuying/BuyingPage';

import Cart from './components/Cart/Cart';
import Checkout from './components/Cart/Checkout';
import CheckoutSuccess from './components/Cart/CheckoutSuccess';
import SavedPayment from './components/Cart/SavedPayment';

import Property from './components/Property/Property';
import PropertyCreate from './components/Property/PropertyCreate';
import PropertyEdit from './components/Property/PropertyEdit';

import EnergyList from './components/Profile/EnergyList';

import Billing from './components/BIlling/Billing';
import Invoice from './components/BIlling/Invoice';

import NotificationDetails from './components/Notification/NotificationDetails';

import StaticProfile from './components/Search/StaticProfile';

import Setting from './components/Setting/Setting';

const routes = [
	{ path: '/', exact: true, name: 'Home', component: Home },
	{
		path: '/admin',
		exact: true,
		name: 'Admin',
		component: Admin,
	},
	{ path: '/profile', exact: true, name: 'Profile', component: Profile },
	{
		path: '/profile/energy-list',
		exact: true,
		name: 'Energy list',
		component: EnergyList,
	},
	{
		path: '/market',
		exact: true,
		name: 'Marketplace(all)',
		component: Market,
	},
	{
		path: '/market/selling',
		exact: true,
		name: 'Marketplace(sell)',
		component: SellingPage,
	},
	{
		path: '/market/buying',
		exact: true,
		name: 'Marketplace(buy)',
		component: BuyingPage,
	},
	{ path: '/cart', exact: true, name: 'Cart', component: Cart },
	{
		path: '/cart/checkout/:id/:amount/:invoiceId',
		exact: true,
		name: 'Checkout Form',
		component: Checkout,
	},
	{
		path: '/cart/checkout/payment/saved-payment',
		exact: true,
		name: 'Saved Payment',
		component: SavedPayment,
	},
	{
		path: '/cart/checkout/payment/success',
		exact: true,
		name: 'Payment Successful',
		component: CheckoutSuccess,
	},
	{
		path: '/market/billing',
		exact: true,
		name: 'Billing',
		component: BillingInfo,
	},
	{
		path: '/market/energy-type',
		exact: true,
		name: 'Energy Types',
		component: EnergyType,
	},
	{
		path: '/market/energy-detail-form/:id',
		exact: true,
		name: 'Energy Form',
		component: EnergyDetailForm,
	},
	{
		path: '/property',
		exact: true,
		name: 'Property',
		component: Property,
	},
	{ path: '/property/form/:type/create', exact: true, name: 'Add Property', component: PropertyCreate },
	{ path: '/property/form/:type/edit/:id', exact: true, name: 'Edit Property', component: PropertyEdit },
	{
		path: '/market/detail/:id',
		exact: true,
		name: 'Detail',
		component: ProductDetail,
	},
	{
		path: '/billing',
		exact: true,
		name: 'Billings',
		component: Billing,
	},
	{
		path: '/billing/invoice',
		exact: true,
		name: 'Invoice',
		component: Invoice,
	},
	{
		path: '/notification-details/:id',
		exact: true,
		name: 'Notification Detail',
		component: NotificationDetails,
	},
	{
		path: '/search/:name',
		exact: true,
		name: 'Profile',
		component: StaticProfile,
	},
	{
		path: '/setting',
		exact: true,
		name: 'Settings',
		component: Setting,
	},
];

export default routes;
