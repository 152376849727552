
export default {
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
	paddingTop:'300px',
	display: 'flex',
	zIndex: '10000',
	background: 'rgba(0,0,0, 0.5)',
	flexDirection: 'column',
	color: '#d26516',
	'& img': {
		pointerEvents: 'none',
		maxWidth: '100%',
		width: '80px',
	},
	'.loader-img-blue': {
		animation: 'right 1500ms ease-out infinite',
	},
	'.loader-img-white': {
		animation: 'spin 1500ms ease-out infinite',
	},
	'.loader-img-orange': {
		animation: 'left 1500ms ease-out infinite',
	},
};

