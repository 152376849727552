/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useEffect, useRef } from 'react';
import { Row, Container, Form, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';

// components

// styled elements
// import loaderStyles from '../../_elements/loader';

//redux
import { connect, useDispatch } from 'react-redux';
import { twoFactor } from '../../_redux/actions/ActionAuthentication';
import { fetchWrapper } from '../../_helpers';

const login = css`
	.login-container {
		border-radius: 10px;
		width: 30rem;
	}
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		-webkit-background-clip: text;
		background-clip: text;
	}

	input,
	input:focus,
	textarea {
		border-top-style: hidden;
		border-right-style: hidden;
		border-left-style: hidden;
		border-bottom-style: groove;

		background-color: transparent !important;
		font-size: 1rem;
		outline: none !important;
		border: 1px 0px 0px 0px solid black;
	}
`;
const bigButtons = css`
	margin: 1rem;
	font-size: 1.3rem;
	width: 100%;
	height: 3.5rem;
	border-radius: 10px;
	letter-spacing: normal;
	font-weight: 700;
	background-color: #e97724;
	color: white;
`;

const padding = css`
	padding: 1.81rem 2rem 1.81rem 2rem;
`;

export const TwoFact = ({ twoFactor, match }) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const email = match.params.email;
	//Skipping first iteration (exactly like componentWillReceiveProps):
	const isFirstRun = useRef(true);
	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false;
			return;
		}
	}, []);

	const { register, handleSubmit, errors, getValue, setError } = useForm({
		mode: 'onBlur',
	});

	const onSubmit = (user) => {
		var form_data = new FormData();

		for (var key in user) {
			form_data.append(key, user[key]);
		}

		activateAccount(form_data);
	};

	const activateAccount = async (data) => {
		try {
			await fetchWrapper.post(`auth/users/activation/`, data, false);
			history.push(`/login`);
		} catch (e) {
			console.log(e);
			if (e) {
				for (const key in e) {
					dispatch({ type: 'SET_MESSAGE', payload: { error: true, message: e[key][0] } });
				}
			}
		}
	};

	const activationResend = async () => {
		let form_data = new FormData();
		try {
			if (email) {
				form_data.append('email', email);
				await fetchWrapper.post(`auth/users/resend_activation/`, form_data, false);
				dispatch({ type: 'SET_MESSAGE', payload: { error: false, message: 'Check your email' } });
			} else if (getValue('email')) {
				form_data.append('email', getValue('email'));
				await fetchWrapper.post(`auth/users/resend_activation/`, form_data, false);
				dispatch({ type: 'SET_MESSAGE', payload: { error: false, message: 'Check your email' } });
			} else
				setError('email', {
					type: 'manual',
					message: 'Email is required',
				});
		} catch (e) {
			console.log(e);
			dispatch({ type: 'SET_MESSAGE', payload: { error: true, message: e.detail } });
		}
	};

	return (
		<Container css={login} fluid className="vh-100 bg-primary">
			<Row className="d-flex  justify-content-center border wrapper h-100  ">
				<div css={padding} className="position-relative login-container">
					<div className="text-center">
						<img className="mb-1 h-auto" style={{ width: '5rem' }} src="/images/p2plogo.png" alt="logo" />
						<h1 className="h3 p-5 font-weight-bold text-dark ">Activate Account</h1>
					</div>

					<Form onSubmit={handleSubmit(onSubmit)}>
						<Form.Group controlId="formBasicEmail">
							<Form.Control
								type="email"
								name="email"
								defaultValue={email}
								isInvalid={errors.email}
								placeholder="Enter email"
								ref={register}
							/>
							<Form.Text className="text-danger">{errors.email?.message}</Form.Text>
						</Form.Group>

						<Form.Group controlId="formBasicEmail">
							<Form.Control
								type="text"
								name="otp"
								isInvalid={errors.otp}
								placeholder="Enter otp"
								ref={register}
							/>
							<Form.Text className="text-danger">{errors.otp?.message}</Form.Text>
						</Form.Group>
						<div className="d-flex justify-content-between">
							<Button
								css={bigButtons}
								className="text-uppercase"
								onClick={() => activationResend()}
								variant="secondary"
								size="lg"
							>
								Resend
							</Button>
							<Button
								css={bigButtons}
								className="text-uppercase"
								variant="secondary"
								type="submit"
								size="lg"
							>
								Activate
							</Button>
						</div>
					</Form>
				</div>
			</Row>
		</Container>
	);
};

TwoFact.propTypes = {};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { twoFactor };

export default connect(mapStateToProps, mapDispatchToProps)(TwoFact);
