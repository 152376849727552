//authentication types
export const SET_USER = 'SET_USER';
export const SET_NEW_USER = 'SET_NEW_USER';
export const REMOVE_DELETED_USER = 'REMOVE_DELETED_USER';
export const SET_USER_LOADING = 'SET_USER_LOADING';
export const CLEAR_USER_LOADING = 'CLEAR_USER_LOADING';
export const SET_LOGGEDIN = 'SET_LOGGEDIN';
export const SET_LOGGEDOUT = 'SET_LOGGEDOUT';
export const SET_ALL_USER = 'SET_ALL_USER';

// user interface types
export const SET_MESSAGE = 'SET_MESSAGE';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';
export const LOADING_UI = 'LOADING_UI';
export const STOP_LOADING_UI = 'STOP_LOADING_UI';

//market
export const SET_ALL_MARKET = 'SET_ALL_MARKET';
export const ADD_CART = 'ADD_CART';
export const SET_CART_COUNTER = 'SET_CART_COUNTER';
export const REMOVE_CART = 'REMOVE_CART';
export const SET_ALL_SELLING = 'SET_ALL_SELLING';
export const SET_ALL_BUYING = 'SET_ALL_BUYING';
export const SET_ALL_ENERGY_TYPES = 'SET_ALL_ENERGY_TYPES';

//property
export const SET_ALL_PROPERTY = 'SET_ALL_PROPERTY';
export const LOADING_UI_PROPERTY = 'LOADING_UI_PROPERTY';
export const STOP_LOADING_UI_PROPERTY = 'STOP_LOADING_UI_PROPERTY';

//location types
export const SET_POSITION = 'SET_POSITION';
export const SET_POS_ERROR = 'SET_POS_ERROR';

//notification types
export const SET_ALL_NOTIFICATION = 'SET_ALL_NOTIFICATION';
export const SET_NEW_NOTIFICATION = 'SET_NEW_NOTIFICATION';
