/** @jsx jsx */
import {css, jsx} from '@emotion/core';
import { useState } from 'react';
import { Modal, Form, Button, Col } from 'react-bootstrap';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { BsPencil } from 'react-icons/bs';
import styled from '@emotion/styled';

const cardDetail = css`
	position:relative;
	width:300px;
	padding: 20px 50px;
	border-radius: 32px;
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
`;

const AtmButtonsNext = styled.div`
	.card-dots {
		position: absolute; 
		z-index: 2;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		top: 52px;
		left: 20px;
		background-color: white;
		width: 30px;
		height: 30px;
		border: 1px solid gray;
		border-radius: 15px;
		cursor: pointer;
	}

	input[type='checkbox'] {
		display: none;
	}

	input[type='checkbox']:checked ~ .buttons-wrapper-next {
		opacity: 1;
		transform: translateX(45px);
		width: 130px;
	}

	.buttons-wrapper-next {
		position: absolute;
		z-index: 1;
		top: 52px;
		left: 10px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		width: 120px;
		height: 30px;
		background-color: white;
		border-radius: 15px;
		border: 1px solid white;
		padding: 5px;
		opacity: 0;
		transition: opacity 300ms ease-in-out, transform 300ms ease-in-out, width 400ms ease-in-out;

		.inner-button {
			display: flex;
			flex-direction: row;
			align-items: center;
			background-color: white;
			border: 1px solid #c7c7c7;
			border-radius: 15px;
			padding: 2.5px 5px;

			.edit,
			.delete {
				font-size: 10px;
				margin-left: 4px;
			}
			.edit {
				color: #3b85aa;
			}
			.delete {
				color: #fb7878;
			}
		}
	}
`;

const buttonDone = {
	backgroundColor: '#E97724',
	width: '80%',
};
const inputfield = {
	border: 'none',
	borderBottom: '1px solid #5B5B5B',
	borderRadius: 'unset',
};

const TransferEarning = () => {
	const [modelOpen, setModelOpen] = useState(false);
	return (
		<div css={cardDetail}>
			<h6>Transfer your earning to</h6>
			<img src="/images/card.png" className="card-img" alt="atm-card" />
			<AtmButtonsNext>
				<input type="checkbox" id="toggleNext" />
				<label htmlFor="toggleNext" className="card-dots">
					<BsThreeDotsVertical className="h6 mb-0 text-muted" />
				</label>
				<div className="buttons-wrapper-next">
					<button
						className="inner-button"
						data-toggle="modal"
						data-target="#myModal"
						onClick={() => setModelOpen(true)}
					>
						<BsPencil style={{ fontSize: '14px', color: '#3B85AA' }} />
						<p className="edit mb-0">Edit</p>
					</button>
					<button className="inner-button">
						<RiDeleteBin6Line style={{ fontSize: '14px', color: '#FB7878' }} />
						<p className="delete mb-0">Delete</p>
					</button>
				</div>
			</AtmButtonsNext>
			<Modal
				show={modelOpen}
				onHide={() => setModelOpen(false)}
				className="d-flex align-items-center"
				animation={false}
			>
				<Modal.Dialog style={{ width: '800px', margin: '0' }}>
					<Form.Group className="p-4">
						<Form.Row>
							<Form.Label column sm="4">
								Name on the Card
							</Form.Label>
							<Col sm="8">
								<Form.Control style={inputfield} size="sm" type="text" placeholder="Sharma Paudel" />
							</Col>
						</Form.Row>
						<Form.Row>
							<Form.Label column sm="4">
								Card Number
							</Form.Label>
							<Col sm="8">
								<Form.Control style={inputfield} size="sm" type="number" placeholder="1111-2222-3333" />
							</Col>
						</Form.Row>
						<Form.Row>
							<Form.Label column sm="4">
								Expiration Date
							</Form.Label>
							<Col sm="8">
								<Form.Row>
									<Col className="d-flex">
										<Form.Text inline sm="4" className="text-muted mr-2">
											From
										</Form.Text>
										<Form.Control style={inputfield} as="select" placeholder="2020" size="sm">
											<option>2009</option>
											<option>2010</option>
											<option>2011</option>
											<option>2012</option>
											<option>2013</option>
											<option>2014</option>
											<option>2015</option>
											<option>2016</option>
											<option>2017</option>
											<option>2018</option>
											<option>2019</option>
											<option>2020</option>
										</Form.Control>
									</Col>
									<Col className="d-flex">
										<Form.Text inline sm="4" className="text-muted mr-2">
											To
										</Form.Text>
										<Form.Control style={inputfield} as="select" placeholder="2018" size="sm">
											<option>2021</option>
											<option>2022</option>
											<option>2023</option>
											<option>2024</option>
											<option>2025</option>
											<option>2026</option>
											<option>2027</option>
											<option>2028</option>
											<option>2029</option>
											<option>2030</option>
											<option>2031</option>
											<option>2032</option>
										</Form.Control>
									</Col>
								</Form.Row>
							</Col>
						</Form.Row>
						<Form.Row className="w-100 d-flex flex-row justify-content-center">
							<Button style={buttonDone} className="btn w-80 mt-4 text-white">
								Done
							</Button>
						</Form.Row>
					</Form.Group>
				</Modal.Dialog>
			</Modal>
		</div>
	);
};

export default TransferEarning;
