/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useState, useEffect } from 'react';
import { Card, Container, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Main from '../../Layouts/Main';
import TopBar from '../../Layouts/TopBar/TopBar';
import { IconContext } from 'react-icons';
import { getAllEnergyTypes } from '../../_redux/actions/ActionMarket';
import { connect } from 'react-redux';
import EnergyTypeIcon from './EnergyTypeIcon';

const energyType = css`
	width: 80%;
	.input-card {
		background-color: #d7e2ee;
		border-radius: 10px;
		margin: 0 10px;
		padding: 10px 20px;
		text-align: center;
		box-shadow: 0px 5px 10px #9e9e9e;
	}
	.form-check {
		margin-bottom: 0;
		input {
			display: inline-block;
		}
		.form-check-input {
			margin-left: 0;
		}
	}
`;
const bigButtons = css`
	margin: 1rem;
	font-size: 1.3rem;
	width: 50%;
	height: 3.5rem;
	border-radius: 10px;
	letter-spacing: normal;
	font-weight: 700;
	background-color: #e97724;
	color: white;
`;

function EnergyType({ energyTypes, getAllEnergyTypes }) {
	const [type, setType] = useState(1);

	useEffect(() => {
		getAllEnergyTypes();
	}, [getAllEnergyTypes]);

	return (
		<Main>
			<TopBar>
				<Container css={energyType} className="mt-2 h-100">
					<IconContext.Provider value={{ size: '50' }}>
						<Card className="px-5 py-3">
							<p className="font-weight-bold mb-4">Choose your energy type</p>
							<Form className="d-flex p-4 justify-content-center">
								{energyTypes.length > 0 &&
									energyTypes.map((eType) => (
										<Card className="input-card">
											<EnergyTypeIcon id={eType.name} />
											<p className="m-0">{eType.name}</p>
											<Form.Check
												checked={eType.id === type}
												onChange={() => setType(eType.id)}
												type="checkbox"
												name="energyType"
											/>
										</Card>
									))}
							</Form>
							<div className="my-3">
								<Link
									to={`/market/energy-detail-form/${type}`}
									className="d-flex justify-content-center text-decoration-none"
								>
									<Button variant="secondary" type="submit" css={bigButtons}>
										Confirm
									</Button>
								</Link>
							</div>
						</Card>
					</IconContext.Provider>
				</Container>
			</TopBar>
		</Main>
	);
}
const mapStateToProps = (state) => ({
	energyTypes: state.market.energyTypes,
});

export default connect(mapStateToProps, { getAllEnergyTypes })(EnergyType);
