import React from 'react';
import {useState} from 'react';
import { CarouselProvider, Slider, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
// import { Row, Col } from 'react-bootstrap';
// import { FaSolarPanel, FaStar} from 'react-icons/fa';
import EachEnergyBuying from './EachEnergyBuying';

const buttonEnabled = {
    width:"150px",
    backgroundColor:"#006191",
    color:"white",
    padding:"10px 20px",
    border:"none",
    borderRadius:"5px"
}

const buttonDisabled = {
    width:"150px",
    backgroundColor:"#006191",
    color:"white",
    padding:"10px 20px",
    border:"none",
    borderRadius:"5px",
    opacity:0.5
}

const BuyingItems = ({items}) => {
    const [slideNumber, setSlideNumber] = useState(0)

    const getEachEnergy = () => {
        let energyItems = [];
        for (let i = 0; i <= (items.length-1); i+=2) {
            energyItems.push(<EachEnergyBuying key={i} items={items} i={i}/>);
        }
        return energyItems;
    }

    return (
        <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={100}
            totalSlides={Math.ceil(items.length/2)}
            isIntrinsicHeight={true}>
            <Slider>
                {getEachEnergy()}
            </Slider>
            <div className="d-flex flex-row justify-content-between mx-4 mt-2 mb-4">
                    <ButtonBack onClick={() => {setSlideNumber(prev => prev - 1 )}}  disabled={slideNumber===0} style={{border:'none'}}>
                        <div style={slideNumber===0? buttonDisabled : buttonEnabled}>Previous</div>
                    </ButtonBack>
                    <ButtonNext onClick={() => {setSlideNumber(prev => prev + 1 )}} disabled={slideNumber===(items.length > 1 ? Math.ceil(items.length/2 - 1) : 0)} style={{border:'none'}}>
                        <div style={slideNumber=== (items.length > 1 ? Math.ceil(items.length/2 - 1) : 0) ? buttonDisabled : buttonEnabled}>Next</div>
                    </ButtonNext>
            </div>  
        </CarouselProvider>
    )
}

export default BuyingItems
