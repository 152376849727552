import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import Main from '../../Layouts/Main';
import TopBar from '../../Layouts/TopBar/TopBar';
import { Card, Table } from 'react-bootstrap';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { BsDownload } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import { fetchWrapper } from '../../_helpers';

export const Invoice = () => {
	
	const history = useHistory();
	const id = history.location.state.payId;
	const [payment, setPayment] = useState([])

	useEffect(()=>{
		(async () => {
			try{
				const res = await fetchWrapper.get(`payment/list_pyment_method/${id}`);
				setPayment(res)
			}catch(e){
				console.log(e)
			}
		})();
	}, [])

	const d = new Date(payment.created_date)

	const printDocument = () => {
		const input = document.getElementById('divToPrint');
		html2canvas(input).then((canvas) => {
			const imgData = canvas.toDataURL('image/png');
			const pdf = new jsPDF();
			pdf.addImage(imgData, 'JPEG', 0, 0);
			// pdf.output('dataurlnewwindow');
			pdf.save('download.pdf');
		});
	};

	return (
		<Main>
			<TopBar>
				<div>
					<div
						id="divToPrint"
						className="mt-4"
						style={{
							width: '210mm',
							minHeight: 'auto',
							marginLeft: 'auto',
							marginRight: 'auto',
						}}
					>
						<div className="d-flex justify-content-end">
							<div className="text-right pr-4">
								<p className="mb-0">{`${d.toLocaleString('default', { month: 'short' })} ${d.getDate()}, ${d.getFullYear()}`}</p>
								<h1 className="display-4">Invoice</h1>
							</div>
						</div>
						<div className="pl-4">
							<p className="mb-0">To:</p>
							<p className="mb-0">{payment.to}</p>
							<p className="mb-0">{payment.to_email}</p>
						</div>
 
						<Card className="mt-2 mx-4">
							<Table bordered className="mb-0 ">
								<tbody>
									<tr>
										<td className="pl-5">Product Name</td>
										<td className="pl-5">{payment.product_name}</td>
									</tr>
									<tr>
										<td className="pl-5">Paid To</td>
										<td className="pl-5">{payment.paid_to}</td>
									</tr>
									<tr>
										<td className="pl-5">Paid Amount</td>
										<td className="pl-5">${payment.amount}</td>
									</tr>
									<tr>
										<td className="pl-5">Payment type</td>
										<td className="pl-5">monthly</td>
									</tr>
									<tr>
										<td className="pl-5">Payment method</td>
										<td className="pl-5">{payment.payment_method}</td>
									</tr>
									<tr>
										<td className="pl-5">Paid For</td>
										<td className="pl-5">2nd Jan 2020 - 2nd feb 2020</td>
									</tr>
								</tbody>
							</Table>
						</Card>
					</div>
					<div
						className="d-flex justify-content-end"
						style={{
							width: '210mm',
							minHeight: 'auto',
							marginLeft: 'auto',
							marginRight: 'auto',
						}}
					>
						<BsDownload className="mr-4 mt-2 bg-white" onClick={printDocument} size="24" />
					</div>
				</div>
			</TopBar>
		</Main>
	);
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Invoice);
