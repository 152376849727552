/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useState, useEffect, useRef } from 'react';
import { Row, Container, Form, Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

//redux
import { connect } from 'react-redux';
import { loginUser, forgetPasswordUser } from '../../_redux/actions/ActionAuthentication';
import { logValidationSchema } from '../../_helpers';

const login = css`
	min-height: 100vh;
	.login-container {
		border-radius: 10px;
		width: 30rem;
	}
	.form-group {
		margin-bottom: 2rem;
	}
	.password-inputarea {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
	}
	.field-icon {
		float: right;
		margin-left: -25px;
		position: relative;
		z-index: 2;
	}
	.heading {
		font-weight: 500;
		font-size: 24px;
		color: #002b71;
	}
	.text-reverse {
		color: #254d8d;
	}
	.wrapper {
		position: relative;
	}
	.help-link {
		position: absolute;
		top: 1.81rem;
		right: 5rem;
		a {
			color: #002b71;
		}
	}
`;
const bigButtons = css`
	margin: 1rem;
	font-size: 1.3rem;
	width: 100%;
	height: 3.5rem;
	border-radius: 10px;
	letter-spacing: normal;
	font-weight: 400;
	background-color: #e97724;
	color: white;
`;

const padding = css`
	padding: 1.81rem 2rem 1.81rem 2rem;
`;

const link = css`
	font-size: 1rem;
	cursor: pointer;
	text-decoration: underline;
`;

export const Login = ({ loginUser, forgetPasswordUser }) => {
	const [forgetPassword, setForgerPassword] = useState(false);
	const [showPassword, setShowPassword] = useState(false);

	const history = useHistory();

	//Skipping first iteration (exactly like componentWillReceiveProps):
	const isFirstRun = useRef(true);
	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false;
			return;
		}
	}, []);

	const { register, handleSubmit, errors } = useForm({
		mode: 'onBlur',
		defaultValues: {
			email: '',
			password: '',
		},
		resolver: yupResolver(logValidationSchema),
	});
	const {
		register: registerForgetPassword,
		handleSubmit: handleSubmitForgetPassword,
		errors: errorsForgetPassword,
	} = useForm({
		mode: 'onBlur',
		defaultValues: {
			email: '',
		},
	});

	const onSubmit = (user) => {
		if (forgetPassword) {
			forgetPasswordUser(user, history);
		} else {
			const tempUser = { email: user.email, password: user.password };
			loginUser(tempUser, history);
		}
	};

	return (
		<Container css={login} fluid className="bg-primary">
			<Row className="d-flex justify-content-center wrapper">
				<div className="help-link">
					<Link>Help & Support</Link>
				</div>
				<div css={padding} className="position-relative login-container">
					<div className="text-center">
						<img className="mb-1 h-auto" style={{ width: '5rem' }} src="/images/p2p_vert.png" alt="logo" />
						<h1 className="p-5 heading">
							{!forgetPassword ? 'Great to see you again!' : 'Forgot Password'}
						</h1>
					</div>
					{!forgetPassword ? (
						<Form onSubmit={handleSubmit(onSubmit)}>
							<Form.Group controlId="formBasicEmail">
								<div class="floating-label">
									
									<input
										class="floating-input"
										type="email"
										name="email"
										placeholder=" "
										isInvalid={errors.email}
										ref={register}
									/>
									<span class="highlight"></span>
									<label>Email Address</label>
								</div>
								<Form.Text className="text-danger">{errors.email?.message}</Form.Text>
							</Form.Group>
							<Form.Group controlId="formBasicPassword">
								<div className="password-inputarea">
									<div class="floating-label">
										<input
											class="floating-input"
											type={showPassword ? 'text' : 'password'}
											placeholder=" "
											isInvalid={errors.password}
											name="password"
											ref={register}
										/>
										<span class="highlight"></span>
										<label>Password</label>
									</div>
									<span className="field-icon" onClick={() => setShowPassword((prev) => !prev)}>
										{showPassword ? <FaEye /> : <FaEyeSlash />}
									</span>
								</div>
								<Form.Text className="text-danger">{errors.password?.message}</Form.Text>
							</Form.Group>

							{/* <Form.Group controlId="formBasicPassword">
								<div className="password-inputarea">
									<Form.Control
										className="password-input"
										isInvalid={errors.password}
										type={showPassword ? 'text' : 'password'}
										name="password"
										placeholder="Password"
										ref={register}
									/>
									<span className="field-icon" onClick={() => setShowPassword((prev) => !prev)}>
										{showPassword ? <FaEye /> : <FaEyeSlash />}
									</span>
								</div>
								<Form.Text className="text-danger">{errors.password?.message}</Form.Text>
							</Form.Group> */}
							<div className="d-flex justify-content-between mt-5">
								<Form.Group controlId="formBasicPassword">
									<Form.Check
										isInvalid={errors.remember}
										type="checkbox"
										name="remember"
										ref={register}
										label="Remember Me"
										className="text-reverse"
									/>
									<Form.Text className="text-danger">{errors.remember?.message}</Form.Text>
								</Form.Group>
								<p
									className="text-right text-capitalize text-reverse"
									css={link}
									onClick={() => setForgerPassword(true)}
								>
									Forgot Password?
								</p>
							</div>
							<Button
								css={bigButtons}
								className="text-uppercase"
								variant="secondary"
								type="submit"
								size="lg"
							>
								sign in
							</Button>
							<p className="text-center text-reverse">
								Don't have an account?{' '}
								<Link className="text-secondary" to="/register">
									Sign Up
								</Link>
							</p>
						</Form>
					) : (
						<Form onSubmit={handleSubmitForgetPassword(onSubmit)} autocomplete="off">
							<p className="text-center text-muted">
								The password reset link will be sent to your email. Enter your email address
							</p>
							<Form.Group controlId="formBasicEmail">
								<div class="floating-label">
									<input
										autoComplete="off"
										name="email"
										class="floating-input"
										type="email"
										placeholder=" "
										isInvalid={errors.email}
										ref={registerForgetPassword}
									/>
									<span class="highlight"></span>
									<label>Email Address</label>
								</div>
								<Form.Text className="text-danger">{errorsForgetPassword.email?.message}</Form.Text>
							</Form.Group>

							<div className="d-flex justify-content-around">
								<Button css={bigButtons} variant="primary" type="submit" size="lg">
									Send Link
								</Button>
							</div>
						</Form>
					)}
				</div>
			</Row>
		</Container>
	);
};

Login.propTypes = {};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { loginUser, forgetPasswordUser };

export default connect(mapStateToProps, mapDispatchToProps)(Login);
