/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { memo } from 'react';

const chatHeading = css`
	font-size: 14px;
	font-weight: bold;
	color: #2d518a;
`;

const ChartHeading = memo(({ title }) => (
	<h1 css={chatHeading} className="mb-0">
		{title}
	</h1>
));

export default ChartHeading;
