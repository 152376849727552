/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useState, useEffect, Fragment } from 'react';
import Main from '../../../Layouts/Main';
import TopBar from '../../../Layouts/TopBar/TopBar';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Tabs, Tab, Row, Col, Card } from 'react-bootstrap';
import { FaTree } from 'react-icons/fa';
import LineChart from '../Chart';
import { getAllBuyingProducts } from '../../../_redux/actions/ActionMarket';
import { WiSmoke } from 'react-icons/wi';
import ChartDate from '../ChartDate';
import ChartHeading from '../ChartHeading';
import BuyingItems from './BuyingItems';
import { fetchWrapper } from '../../../_helpers';
// import LoaderSmall from '../../../Layouts/Loader/LoaderSmall';

const marketStyle = css`
	padding:0px 20px;
	.custom-tabs {
		display: inline-flex; 
		background-color: #dcdcdc;
		border-radius: 8px;
		border: 4px solid #fbfbfb;
		margin-bottom: 0;
	}
	.tab-items {
		padding: 5px 20px;
		margin-right: 20px;
		background-color: #cfcfcf;
		border-radius: 8px;
	}
	.tab-items:last-child {
		margin-right: 0 !important;
	}
	.tab-items a {
		color: #222;
	}
	.tab-items a:hover {
		text-decoration: none !important;
	}
	.tab-items.active {
		background-color: #191919;
	}
	.tab-items.active a {
		color: #fff;
	}
	.banner-card {
		box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.53);
		background-color: #f1faff;
		border-radius: 10px;
	}
	.energy-card {
		background-color: #488fb2;
	}
	.energy-card h1 {
		font-family: Roboto;
		font-size: 30px;
		font-weight: bold;
		color: #fbfbfb;
	}
	.vertical-line {
		border-right: 1px solid #b4d7fe;
		width: 33%;
		height: 100%;
	}
	.energy-card {
		background-color: #488fb2;
		border: none;
		border-radius: 0;
		padding: 0.5rem;
		h1 {
			font-family: Roboto;
			font-size: 2rem;
			font-weight: bold;
			color: #fbfbfb;
		}
	}
	.last-col {
		width: 33%;
	}
	.last-col p {
		color: #4b6b9c;
		font-weight: bold;
	}
	.impact-card {
		padding: 1rem 0.9rem;
		border-radius: 12px;
		background-color: #006191;
	}
	.impact-sub-card--1 {
		opacity: 0.6;
		border-radius: 10px;
		margin-bottom: 0.5rem;
		height: 8rem;
		background-image: linear-gradient(90deg, #008d0f 50%, #07ff20 100%);
	}
	.impact-sub-card--2 {
		opacity: 0.88;
		border-radius: 10px;
		height: 8rem;
		background-image: linear-gradient(90deg, #dc7123 50%, #ffa200 100%);
	}
	.text-bottom {
		display: inline-block;
		align-self: flex-end;
		color: #fff;
	}
	.text-bottom p {
		font-size: 10px;
	}
	.text-bottom h1 {
		font-size: 24px;
	}
	.title-primary {
		font-size: 24px;
		font-weight: bold;
		color: #011941;
		margin-bottom: 10px;
		position: absolute;
		top: 0.5rem;
	}
	.services .nav-tabs {
		border: none;
	}
	.services .nav-tabs .nav-link {
		border: none;
		color: #011941;
		opacity: 0.7;
		font-size: 14px;
		padding: 0;
		margin-right: 20px;
	}
	.services .nav-tabs .nav-link.active {
		border: none;
		color: #011941;
		background-color: transparent;
		opacity: 1;
		text-decoration: underline;
	}
	.services .tab-content {
		margin-top: 30px;
	}
	.energy-single {
		border-radius: 15px;
		background-color: #fbfbfb;
		padding: 20px;
		margin-bottom: 30px;

		&:hover {
			border: 1px solid #006191;
		}
	}
	.energy-img {
		width: 80%;
		height: 150px;
		object-fit: cover;
		margin-bottom: 20px;
	}
	.energy-single h5 {
		color: #191919;
	}
	.energy-single p {
		color: #011941;
		font-size: 14px;
		margin-bottom: 5px;
	}
	.rating-wrapper {
		color: #cb8700;
		margin-bottom: 10px;
	}
	.cart-btn {
		background-color: #006191;
		color: #fff;
		width: 100%;
		border-radius: 8px;
	}

	.info-orange,
	.info-blue {
		padding: 0.5rem 1rem;
		margin: 1rem 0;
		color: #fff;
		position: relative;
	}

	.info-orange {
		width: 60%;
		background: #e87839;
	}
	.info-orange:hover {
		background: linear-gradient(90deg, #e87839 0%, #e87839 50%, #e68853 100%);
	}

	.info-blue {
		width: 70%;
		background: #4ba1cb;
	}

	.info-blue:hover {
		background: linear-gradient(90deg, #4ba1cb 0%, #4ba1cb 50%, #8bc3df 100%);
	}
	.info-orange span {
		color: #e87839;
	}
	.info-blue span {
		color: #4ba1cb;
	}
	.info-orange span,
	.info-blue span {
		opacity: 0;
		position: absolute;
		top: 30%;
		left: 70%;
		font-size: 12px;
	}

	.info-orange:hover > span,
	.info-blue:hover > span {
		opacity: 1;
		transform: translateX(130%);
	}
`;

const BuyingPage = ({ user, getAllBuyingProducts, buying }) => {
	const [batteryProduct, setBatteryProduct] = useState([]);
	const [solarProduct, setSolarProduct] = useState([]);
	useEffect(() => {
		getAllBuyingProducts();
		
	}, [getAllBuyingProducts]);

	useEffect(() => {
        (async () => {
			try{
				const response = await fetchWrapper.get('energy/buying?type=Solar');
				setSolarProduct(response)
				console.log(response,"solar")
			}catch(e){
				console.log(e)
			}
			try{
				const response = await fetchWrapper.get('energy/buying?type=Battery');
				setBatteryProduct(response)
				console.log(response,"solar")
			}catch(e){
				console.log(e)
			}
        }
        )();
    }, [])

	const firstChartData = [
		{
			label: 'Total Capacity in kwh',
			data: [86, 67, 91],
			fill: true,
			borderColor: '#4da2cb',
			borderWidth: 2,
			hoverBorderWidth: 3,
			hoverBorderColor: '#4da2cb',
		},
	];
	const barChartData = [
		{
			label: 'Energy Consumption in kwh',
			data: [86, 85, 83, 87, 84],
			fill: false,
			borderColor: '#e9793a',
			backgroundColor: '#e9793a',
			borderWidth: 1,
			hoverBorderWidth: 2,
			hoverBorderColor: '#4da2cb',
		},
	];

	const secondChartData = [
		{
			label: 'Price Saved by solar',
			data: [50, 30, 85],
			backgroundColor: ['#4ba1cb', '#043270', '#e87839'],
			borderWidth: 1,
		},
	];

	return (
		<Main>
			<TopBar>
				<div css={marketStyle}>
					<div className="tab-menu">
						<ul className="custom-tabs">
							{user ? (
								user.is_buyer && user.is_seller ? (
									<Fragment>
										<li className="tab-items ">
											<Link to="/market">Shop</Link>
										</li>
										<li className="tab-items ">
											<Link to="/market/selling">Energy you are selling</Link>
										</li>
										<li className="tab-items active">
											<Link to="/market/buying">Energy you are buying</Link>
										</li>
									</Fragment>
								) : user.is_buyer ? (
									<Fragment>
										<li className="tab-items ">
											<Link to="/market">Shop</Link>
										</li>
										<li className="tab-items active">
											<Link to="/market/buying">Energy you are buying</Link>
										</li> 
									</Fragment>
								) : (
									<Fragment>
										<li className="tab-items ">
											<Link to="/market">Shop</Link>
										</li>
										<li className="tab-items active">
											<Link to="/market/selling">Energy you are selling</Link>
										</li>
									</Fragment>
								)
							) : null}
						</ul>
					</div>

					<div className="shop-section">
						<Card className="banner-card p-2 m-3">
							<div className="d-flex">
								<div className="vertical-line">
									<LineChart
										title="Energy Consumption in kwh"
										type="bar"
										data={barChartData}
										label={['Jan', 'Feb', 'Mar', 'Apr', 'May']}
										hoverBackgroundColor={['#e87839', '#333']}
									/>
								</div>
								<div className="vertical-line">
									<LineChart
										title="Total Capacity in kwh"
										type="line"
										data={firstChartData}
										label={['Jan', 'Feb', 'March']}
									/>
								</div>
								<div className="text-left last-col" style={{ width: '33%', margin: '0 3rem' }}>
									<p className="mb-0">Total energy you are using</p>
									<Card className="energy-card d-flex align-items-center h-auto mb-3 mt-2">
										<h1 className="mb-0">12 kwh</h1>
									</Card>
									<ChartHeading title="Buying in" />
									<div className="info-orange">
										Fixed<span>2050 kWh</span>
									</div>
									<div className="info-blue">
										Installment<span>5050 kWh</span>
									</div>
								</div>
							</div>
						</Card>
						<Row>
							<Col md={8}>
								<div className="services py-3 position-relative">
									<h1 className="title-primary">Energy You are using</h1>
									<Tabs
										defaultActiveKey="all"
										className="justify-content-end"
										id="uncontrolled-tab-example"
									>
										<Tab eventKey="all" title="All">
											{buying.length > 0 ? <BuyingItems items={buying}/> : <p>No Products</p> }
										</Tab>
										<Tab eventKey="solar" title="Solar">
											{solarProduct.length > 0 ? <BuyingItems items={solarProduct}/> : <p>No Products</p> }
										</Tab>
										<Tab eventKey="battery" title="Battery">
											{batteryProduct.length > 0 ? <BuyingItems items={batteryProduct}/> : <p>No Products</p> }
										</Tab>
									</Tabs>
								</div>
							</Col>
							<Col md={4}>
								<Card className="p-2 banner-card">
									<LineChart
										title="Total kW used"
										type="doughnut"
										data={secondChartData}
										label={['Producer(xxxkWh)', 'Battery(xxxkWh)', 'Grid(xxxkWh)']}
									/>
									<hr className="bg-primary" />
									<div>
										<ChartHeading title="Impact" />
										<ChartDate />
										<Card className="impact-card">
											<Card className="impact-sub-card--1">
												<div className="d-flex justify-content-between  p-2">
													<div className="text-bottom">
														<p className="mb-0">Trees saved</p>
														<h1 className="mb-0">15 Trees</h1>
													</div>
													<div>
														<FaTree size="100" color="#2dec41" />
													</div>
												</div>
											</Card>
											<Card className="impact-sub-card--2">
												<div className="d-flex justify-content-between  p-2">
													<div className="text-bottom">
														<p className="mb-0">Co2 Emmission</p>
														<h1 className="mb-0">251 gigaton</h1>
													</div>
													<div>
														<WiSmoke size="100" color="#fbfbfb" />
													</div>
												</div>
											</Card>
										</Card>
									</div>
								</Card>
							</Col>
						</Row>
					</div>
				</div>
			</TopBar>
		</Main>
	);
};

const mapStateToProps = (state) => ({
	user: state.user.user,
	buying: state.market.buying,
});

const mapDispatchToProps = { getAllBuyingProducts };

export default connect(mapStateToProps, mapDispatchToProps)(BuyingPage);
