import * as ActionTypes from '../ActionTypes';

const initialState = {
	loading: false,
	notifications: [],
};

export default function (state = initialState, action) {
	switch (action.type) {
		case ActionTypes.SET_ALL_NOTIFICATION:
			return {
				...state,
				loading: false,
				notifications: [...action.payload],
			};
		case ActionTypes.SET_NEW_NOTIFICATION:
			const tempNotification = [...state.notifications];
			const index = tempNotification.findIndex((not) => not.id === action.payload.id);
			if (index !== -1) {
				tempNotification[index] = action.payload;
				return {
					...state,
					loading: false,
					notifications: tempNotification,
				};
			}
			return {
				...state,
				loading: false,
				notifications: [...tempNotification, action.payload],
			};
		default:
			return state;
	}
}
