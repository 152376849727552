import * as ActionTypes from '../ActionTypes';

const initialState = {
	errors: null,
	market: [],
	selling: [],
	sellingLoader:false,
	buying: [],
	buyingLoader: false,
	cart: [],
	cartCounter: 0,
	energyTypes: [],
};

export default function (state = initialState, action) {
	let tempCart, cartIndex;
	switch (action.type) {
		case ActionTypes.SET_ALL_MARKET:
			return {
				...state,
				market: action.payload,
			};
		case ActionTypes.SET_ALL_SELLING:
			return {
				...state,
				selling: action.payload,
			};
		case ActionTypes.SET_ALL_BUYING:
			return {
				...state,
				buying: action.payload,
			};
		case ActionTypes.SET_CART_COUNTER:
			return {
				...state,
				cartCounter: action.payload,
			};
		case ActionTypes.ADD_CART:
			tempCart = [...state.cart];
			cartIndex = tempCart.findIndex((cart) => cart.id === action.payload.id);
			if (cartIndex !== -1) {
				tempCart[cartIndex] = action.payload;
				return {
					...state,
					cart: tempCart,
				};
			}
			return {
				...state,
				cart: [action.payload, ...state.cart],
			};
		case ActionTypes.REMOVE_CART:
			tempCart = [...state.cart];
			cartIndex = tempCart.findIndex((cart) => cart.id === action.payload);
			tempCart.splice(cartIndex, 1);
			return {
				...state,
				cart: tempCart,
			};

		case ActionTypes.SET_ALL_ENERGY_TYPES:
			return { ...state, energyTypes: [...action.payload] };
		default:
			return state;
	}
}
