import { fetchWrapper } from '../../_helpers';
import * as ActionTypes from '../ActionTypes';
// toasts
import { toast } from 'react-toastify';

export const registerUser = (user, history) => async (dispatch) => {
	try {
		await fetchWrapper.post(`auth/users/register/`, JSON.stringify(user), true);
		let form_data = new FormData();
		form_data.append('email', user.email);
		await fetchWrapper.post(`auth/users/resend_activation/`, form_data, false);
		// history.push(`/2-fact/${user.email}`);
		history.push(`/activate/${user.email}`);
	} catch (e) {
		if (e) {
			for (const key in e) {
				dispatch({ type: ActionTypes.SET_MESSAGE, payload: { error: true, message: e[key][0] } });
			}
		}
	}
};

export const twoFactor = (user, email, history) => async (dispatch) => {
	try {
		await fetchWrapper.post(`auth/users/two_factor/`, user, false);
		history.push(`/activate/${email}`);
	} catch (e) {
		dispatch({ type: ActionTypes.SET_MESSAGE, payload: { error: true, message: e.detail } });
	}
};

export const loginUser = (user, history) => async (dispatch) => {
	try {
		const response = await fetchWrapper.post(`auth/jwt/create/`, JSON.stringify(user), true);
		if (response.access) {
			localStorage.setItem('token', response.access);
			dispatch({ type: ActionTypes.SET_LOGGEDIN });
			history.push('/');
		} else if (
			(response.code === '401' && response.detail.trim() === 'Invalid otp') ||
			(response.code === '200' && response.detail.trim() === 'Otp sent. Either check your email or phone.')
		) {
			user.otp && dispatch({ type: ActionTypes.SET_MESSAGE, payload: { error: true, message: response.detail } });
			history.push(`/2-fact/${user.email}`);
		} 
		else if (response.code === '401'){
			dispatch({ type: ActionTypes.SET_MESSAGE, payload: { error: true, message: response.detail } });
		}
		else {
			history.push(`/activate/${user.email}`);
		}
	} catch (e) {
		dispatch({ type: ActionTypes.SET_MESSAGE, payload: { error: true, message: e.detail } });
	}
};

export const logoutUser = () => async (dispatch) => {
	try {
		localStorage.removeItem('token');
		dispatch({ type: ActionTypes.SET_LOGGEDOUT });
		await fetchWrapper.post(`auth/token/logout/`);
	} catch (e) {
		console.log(e);
	}
};

export const forgetPasswordUser = (email, history) => async (dispatch) => {
	try {
		await fetchWrapper.post('auth/users/password/reset/', JSON.stringify(email), true);
		history.push(`/reset-password?email=${email.email}`);
	} catch (error) {}
};

export const resetPasswordUser = (password, history) => async (dispatch) => {
	try {
		await fetchWrapper.post(`auth/users/password/reset/confirm/`, JSON.stringify(password), true);
		history.push('/login');
	} catch (error) {
		toast.error(error.otp)
	}
};
