/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useEffect, useState } from 'react';
import Main from '../../Layouts/Main';
import TopBar from '../../Layouts/TopBar/TopBar';
import { connect } from 'react-redux';
import { fetchWrapper } from '../../_helpers';
import { Row, Col, Button } from 'react-bootstrap';
import { FaStar } from 'react-icons/fa';
import EnergyTypeIcon from '../Market/EnergyTypeIcon';
import { useHistory, Link } from 'react-router-dom';

const marketStyle = css`
	padding: 0px 20px;
	.services .nav-tabs {
		border: none;
	}
	.services .nav-tabs .nav-link {
		border: none;
		color: #011941;
		opacity: 0.7;
		font-size: 14px;
		padding: 0;
		margin-right: 20px;
	}
	.services .nav-tabs .nav-link.active {
		border: none;
		color: #011941;
		background-color: transparent;
		opacity: 1;
		text-decoration: underline;
	}
	.services .tab-content {
		margin-top: 30px;
	}
	.energy-single {
		border-radius: 15px;
		background-color: #fbfbfb;
		padding: 20px;
		margin-bottom: 30px;
	}
	.energy-single:hover {
		border: 1px solid black;
	}
	.energy-img {
		width: 100%;
		height: 180px;
		object-fit: cover;
		margin-bottom: 20px;
	}
	.energy-single h5 {
		color: #191919;
	}
	.energy-single p {
		color: #011941;
		font-size: 14px;
		margin-bottom: 5px;
	}
	.rating-wrapper {
		color: #cb8700;
		margin-bottom: 10px;
	}
`;

const EnergyList = ({ user }) => {
	const [energy, setEnergy] = useState(null);
	const history = useHistory();
	useEffect(() => {
		if (user) {
			( async () => {
				try {
					setEnergy(await fetchWrapper.get(`energy/energy-source/list?prosumer_email=${user.email}`));
				} catch (e) {
					console.log(e);
				}
			})()
		}
	}, [user]);

	return (
		<Main>
			<TopBar>
				<div css={marketStyle}>
					<div className="services">
						<div className="d-flex justify-content-center">
							<Link to="/market/energy-type">
								<Button className="btn-rounded bg-white py-2 px-4 mx-3 my-3">Add New Energy</Button>
							</Link>
						</div>
						<Row>
							{energy &&
								energy.map((mar) => (
									<Col md={6}>
										<div
											className="energy-single"
											onClick={() => history.push(`/market/detail/${mar.id}`)}
										>
											<Row>
												<Col md={7}>
													<h5>
														<EnergyTypeIcon id={mar.energy_type_id} />
														<span className="ml-2">{mar.name}</span>
													</h5>
													<div className="rating-wrapper">
														<FaStar />
														<FaStar />
														<FaStar />
														<FaStar />
														<FaStar />
													</div>
													<p>Supplier:{mar.supplier}</p>
													<p>Distance: {mar.distance}</p>
													<p>Energy sold: {mar.sell_percent}%</p>
												</Col>
												<Col md={5}>
													<img
														src={mar.picture || '/images/item1.jpg'}
														className="energy-img img-responsive"
														alt="energy"
													/>
												</Col>
												<Col md={12}>
													<Row>
														<Col md={4}>
															<p className="font-weight-bold">$20 monthly</p>
															<p className="font-weight-bold">$200 annually</p>
														</Col>
														<Col md={4}>
															<p className="font-weight-bold text-center">
																{mar.payment_type}
															</p>
														</Col>
													</Row>
												</Col>
											</Row>
										</div>
									</Col>
								))}
						</Row>
					</div>
				</div>
			</TopBar>
		</Main>
	);
};

const mapStateToProps = (state) => ({
	user: state.user.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EnergyList);
