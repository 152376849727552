/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useEffect, useState } from 'react';
import Main from '../../Layouts/Main';
import TopBar from '../../Layouts/TopBar/TopBar';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import { fetchWrapper } from '../../_helpers';
import { FaStar } from 'react-icons/fa';
import EnergyTypeIcon from '../Market/EnergyTypeIcon';
import { useHistory } from 'react-router-dom';

const detailStyle = css`
	.single-product-img {
		width: 100%;
		height: 250px;
		object-fit: cover;
		border-radius: 16px;
	}
	.small-gallery {
		margin-top: 10px;
		display: flex;
	}
	.small-img {
		height: 80px;
		width: 80px;
		margin-right: 20px;
		border-radius: 8px;
	}
	.rating-wrapper {
		color: #cb8700;
		margin-bottom: 10px;
	}
	.services .nav-tabs {
		border: none;
	}
	.services .nav-tabs .nav-link {
		border: none;
		color: #011941;
		opacity: 0.7;
		font-size: 14px;
		padding: 0;
		margin-right: 20px;
	}
	.services .nav-tabs .nav-link.active {
		border: none;
		color: #011941;
		background-color: transparent;
		opacity: 1;
		text-decoration: underline;
	}
	.services .tab-content {
		margin-top: 30px;
	}
	.energy-single {
		border-radius: 15px;
		background-color: #fbfbfb;
		padding: 20px;
		margin-bottom: 30px;
	}
	.energy-single:hover {
		border: 1px solid black;
	}
	.energy-img {
		width: 100%;
		height: 180px;
		object-fit: cover;
		margin-bottom: 20px;
	}
	.energy-single h5 {
		color: #191919;
	}
	.energy-single p {
		color: #011941;
		font-size: 14px;
		margin-bottom: 5px;
	}
`;

const StaticProfile = ({ user, match }) => {
	const [energy, setEnergy] = useState(null);
	const history = useHistory();
	const name = match.params.name;

	useEffect(() => {
		if (user) {
			const fecchMarketData = async () => {
				try {
					setEnergy(await fetchWrapper.get(`energy/energy-source/list?prosumer_email=${user.email}`));
				} catch (e) {
					console.log(e);
				}
			};
			fecchMarketData();
		}
	}, [user]);
	return (
		<Main>
			<TopBar>
				<div css={detailStyle}>
					<Row>
						<Col md={5}>
							<img src="/images/avatar.png" className="single-product-img" alt="item" />
						</Col>
						<Col md={7}>
							<Row>
								<Col md={3}>
									<p className="font-weight-bold">Name</p>
									<p className="font-weight-bold">Email</p>
									<p className="font-weight-bold">Phone Number</p>
									<p className="font-weight-bold">Property</p>
								</Col>
								<Col md={9}>
									<p>:{name}</p>
									<p>:{name}@gmail.com</p>
									<p>:9860112945</p>
									<p>:2</p>
								</Col>
							</Row>
						</Col>
					</Row>
					<h5 className="font-weight-bold my-3">{name}'s energy</h5>
					<Row>
						{energy &&
							energy.map((mar) => (
								<Col md={6}>
									<div
										className="energy-single"
										onClick={() => history.push(`/market/detail/${mar.id}`)}
									>
										<Row>
											<Col md={7}>
												<h5>
													<EnergyTypeIcon id={mar.energy_type_id} />
													<span className="ml-2">{mar.name}</span>
												</h5>
												<div className="rating-wrapper">
													<FaStar />
													<FaStar />
													<FaStar />
													<FaStar />
													<FaStar />
												</div>
												<p>Supplier:{mar.supplier}</p>
												<p>Distance: {mar.distance}</p>
												<p>Energy sold: {mar.sell_percent}%</p>
												<p>Total users: 4</p>
											</Col>
											<Col md={5}>
												<img
													src="/images/item1.jpg"
													className="energy-img img-responsive"
													alt="energy"
												/>
											</Col>
											<Col md={12}>
												<Row>
													<Col md={4}>
														<p className="font-weight-bold">$20 monthly</p>
														<p className="font-weight-bold">$200 annually</p>
													</Col>
													<Col md={4}>
														<p className="font-weight-bold text-center">
															{mar.payment_type}
														</p>
													</Col>
													<Col md={4}>
														<Button
															style={{
																padding: '.2rem 1rem',
																backgroundColor: '#006191',
																color: 'white',
																borderRadius: '12px',
															}}
														>
															Order
														</Button>
													</Col>
												</Row>
											</Col>
										</Row>
									</div>
								</Col>
							))}
					</Row>
				</div>
			</TopBar>
		</Main>
	);
};

const mapStateToProps = (state) => ({
	user: state.user.user,
	market: state.market.market,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(StaticProfile);
