/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React from 'react';

import { BsCardImage } from 'react-icons/bs';

const imageUpload = css`
	.imagePreviewContainer {
		display: flex;
		padding: 10px;
		width: 50%;
		max-width: 50%;
	}

	.previewItem {
		display: flex;
		margin: 10px;
		padding: 10px;
		flex: 0 0 350px;
		background: #fff;
		box-shadow: 1px 2px 5px 0px hsla(0, 0%, 0%, 0.35);
	}

	.imagePreview {
		max-width: 150px;
		height: 150px;
	}

	.details {
		padding: 10px;
	}

	.details h6 {
		font-size: 10pt;
		fong-weight: normal;
		color: #999;
		padding: 5px;
		margin: 0;
	}

	.details i {
		cursor: pointer;
	}

	.dragDrop {
		margin-top: 10px;
		height: 100px;
		max-height: 100px;
		width: 400px;
		max-width: 400px;
		background: #e2e2e2;
		padding: 10px;
		text-align: center;
		color: black;
		border: 2px dashed grey;
	}

	.dragging {
		background-color: #fff;
		color: lightsteelblue;
	}

	.dragging i.material-icons {
		animation: bounce 1s infinite ease-in-out;
	}

	.inside {
		padding: 0.5rem;
	}

	@keyframes bounce {
		0% {
			transform: translateY(0);
		}
		50% {
			transform: translateY(5px);
		}
		100% {
			transform: translateY(0);
		}
	}
`;

export default class ImageUpload extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			files: [],
			urls: [],
			isDragging: false,
		};

		this.onChange = this.onChange.bind(this);
		this.handleDrop = this.handleDrop.bind(this);
		this.handleDragEnter = this.handleDragEnter.bind(this);
		this.handleDragOver = this.handleDragOver.bind(this);
		this.handleDragLeave = this.handleDragLeave.bind(this);
		this.handleFiles = this.handleFiles.bind(this);
		this.onRemove = this.onRemove.bind(this);
	}

	onRemove(index) {
		var { files, urls } = this.state;
		let newFiles = files.filter((file, i) => i !== index);
		let newUrls = urls.filter((url, i) => i !== index);

		this.setState({
			...this.state,
			files: newFiles,
			urls: newUrls,
		});
	}

	handleDrags(e) {
		e.preventDefault();
		e.stopPropagation();

		this.setState({
			...this.state,
			isDragging: true,
		});
	}

	handleDragEnter(e) {
		this.handleDrags(e);
	}

	handleDragOver(e) {
		this.handleDrags(e);
	}

	handleDragLeave(e) {
		e.preventDefault();
		e.stopPropagation();

		this.setState({
			...this.state,
			isDragging: false,
		});
	}

	onChange(e) {
		e.preventDefault();
		const files = e.target.files;
		[].forEach.call(files, this.handleFiles);
	}

	handleDrop(e) {
		e.stopPropagation();
		e.preventDefault();

		const data = e.dataTransfer;
		const files = data.files;
		console.log('Oops...you dropped this: ', files);

		[].forEach.call(files, this.handleFiles);

		this.setState({
			...this.state,
			isDragging: false,
		});
	}

	handleFiles(file) {
		// this could be refactored to not use the file reader

		var reader = new FileReader();

		reader.onloadend = () => {
			var imageUrl = window.URL.createObjectURL(file);

			this.setState({
				files: [file],
				urls: [imageUrl],
			});
			this.props.setImage(file);
		};

		reader.readAsDataURL(file);
	}

	render() {
		const { urls, files, isDragging } = this.state;
		const dropClass = isDragging ? 'dragDrop dragging' : 'dragDrop';

		return (
			<div css={imageUpload}>
				<label className="position-relative">
					<div
						className={dropClass}
						onDrop={this.handleDrop}
						onDragOver={this.handleDragOver}
						onDragEnter={this.handleDragEnter}
						onDragLeave={this.handleDragLeave}
					>
						<div className="inside ">
							<input
								onChange={this.onChange}
								style={{ display: 'none' }}
								accept="image/x-png,image/webp,image/jpeg "
								type="file"
								name="avatar"
							/>
							<div>
								<BsCardImage size="32" className="text-muted" />
							</div>
							<span>Add images here</span>
						</div>
					</div>
				</label>
				<div className="imagePreviewContainer">
					{urls &&
						urls.map((url, i) => (
							<div className="previewItem">
								<img className="imagePreview" src={url} alt="preview" />
								<div className="details">
									<h6>{files[i].name}</h6>
									<h6>{files[i].size.toLocaleString()} KBs</h6>
									<h6>{files[i].type}</h6>
									<i className="material-icons" onClick={() => this.onRemove(i)}>
										delete
									</i>
								</div>
							</div>
						))}
				</div>
			</div>
		);
	}
}
