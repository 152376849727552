/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Modal, Button } from 'react-bootstrap';

const confirmStyles = css`
	.modal-content {
		padding: 2rem 1rem;
	}
	.accept-btn {
		padding: 0.2rem 1.3rem;
		border-radius: 12px;
		background-color: #011941;
		color: #fff;
		font-size: 1.2rem;
		margin-right: 1rem;
	}
	.reject-btn {
		padding: 0.2rem 1.3rem;
		border-radius: 12px;
		border: solid 1.5px #011941;
		background-color: #fff;
		font-size: 1.2rem;
	}
	.text {
		color: #041a42;
	}
`;

function Confirm(props) {
	return (
		<Modal
			show={!!props.show}
			onHide={props.onHide}
			css={confirmStyles}
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Body className=" position-relative">
				<div className="text-center mb-2">
					<h4>{props.text}</h4>
				</div>

				<div className="justify-content-center d-flex my-3">
					<Button className="accept-btn" onClick={() => props.handleDelete(props.show)}>
						Yes
					</Button>
					<Button onClick={props.onHide} className="reject-btn">
						No
					</Button>
				</div>
			</Modal.Body>
		</Modal>
	);
}
export default Confirm;
