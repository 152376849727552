/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Fragment, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { getAllProducts } from '../../_redux/actions/ActionMarket';
import { dateFormat, fetchWrapper } from '../../_helpers';
import { Link } from 'react-router-dom';
// toasts
import { toast } from 'react-toastify';

const notificationStyle = css`
	.avatar {
		width: 3rem;
		height: 3rem;
		border-radius: 1.5rem;
		object-fit: cover;
		margin: 0 1rem;
	}
	.sub-text {
		font-size: 0.8rem;
	}
	.not-btn {
		border-radius: 10px;
		background: white;
	}
	.close-btn {
		cursor: pointer;
	}
`;

function Notification({ setOpen, notifications, getAllProducts }) {
	const dispatch = useDispatch();
	useEffect(() => {
		getAllProducts();
	}, [getAllProducts]);

	const groups = notifications.reduce((groups, message) => {
		const date = dateFormat(message.created_date);
		if (!groups[date]) {
			groups[date] = [];
		}
		groups[date].push(message);
		return groups;
	}, {});

	const getEnegry = (subs) => {
		return (
			<div className="d-flex align-items-center">
				<img src={subs.picture ? subs.picture : '/images/avatar.png'} className="avatar" alt="avatar" />
				<div>
					<p className="mb-0 ">
						<span className="font-weight-bold">{subs.user}</span>{' '}
						{subs.type === 'buyer_enquire_energy_source' ? (
							<span>has requested your energy</span>
						) : (
							<span>has {subs.status} your order</span>
						)}
					</p>
					<div className="d-flex justify-content-between">
						<p className="mb-0 sub-text text-muted">{formatTime(new Date(subs.created_date))}</p>
						<p className="mb-0 sub-text text-muted">
							{subs.type === 'buyer_enquire_energy_source' ? (
								<Link
									to={{
										pathname: `/notification-details/${subs.id}`,
										state: {
											userType: 'Buyer',
											subs:subs,
											status:subs.status
										},
									}}
									onClick={() => setOpen(false)}
								>
									Show details of buyer
								</Link>
							) : (
								<Link
									to={{
										pathname: `/notification-details/${subs.id}`,
										state: {
											userType: 'Seller',
										},
									}}
									onClick={() => setOpen(false)}
								>
									Show details of seller
								</Link>
							)}
						</p>
					</div>
				</div>
			</div>
		);
	};

	function formatTime(date) {
		var hours = date.getHours();
		var minutes = date.getMinutes();
		var ampm = hours >= 12 ? 'pm' : 'am';
		hours = hours % 12;
		hours = hours ? hours : 12; // the hour '0' should be '12'
		minutes = minutes < 10 ? '0' + minutes : minutes;
		var strTime = hours + ':' + minutes + ' ' + ampm;
		return strTime;
	}

	const handleAccept = (notification) => {
		const form_data = new FormData();
		form_data.append('subscription_status', 'accepted');
		form_data.append('energy_source_id', notification.energy_id);
		form_data.append('property_id', notification.property_id);

		fetchWrapper
			.post(`payment/${notification.id}/update_status`, form_data)
			.then((res) => {
				dispatch({ type: 'SET_NEW_NOTIFICATION', payload: { ...notification, status: 'accepted' } });
				toast.success('Accepted');
			})
			.catch((e) => toast.error(e.detail));
	};

	const handleReject = (notification) => {
		const form_data = new FormData();
		form_data.append('subscription_status', 'rejected');
		form_data.append('energy_source_id', notification.energy_id);
		form_data.append('property_id', notification.property_id);

		fetchWrapper
			.post(`payment/${notification.id}/update_status`, form_data)
			.then((res) => {
				dispatch({ type: 'SET_NEW_NOTIFICATION', payload: { ...notification, status: 'rejected' } });
				toast.success('rejected');
			})
			.catch((e) => toast.error(e.detail));
	};

	return (
		<div css={notificationStyle} className="text-white">
			<div className="d-flex justify-content-between  px-2 pt-2 mb-0">
				<p>Clear</p>
				<p>Notification</p>
				<p onClick={() => setOpen(false)} className="close-btn">
					Close
				</p>
			</div>
			<hr className="bg-white m-0 p-0" />
			<div className="p-2">
				{Object.keys(groups)
					.map((date) => (
						<Fragment>
							<p className="text-muted ml-2">{date}</p>
							{groups[date].length > 0 &&
								groups[date]
									.map((not) => (
										<div>
											<div className="d-flex justify-content-between align-items-center pl-3 pr-2 mb-4">
												{getEnegry(not)}
												{not.type === 'buyer_enquire_energy_source' ? (
													not.status === 'pending' ? (
														<div>
															<Button
																className="mx-2 not-btn text-info"
																onClick={() => handleAccept(not)}
															>
																Accept
															</Button>
															<Button
																className="mx-2 not-btn text-danger"
																onClick={() => handleReject(not)}
															>
																Decline
															</Button>
														</div>
													) : not.status === 'accepted' ? (
														<div>
															<Button className="mx-2 not-btn text-info" disabled>
																Accepted
															</Button>
														</div>
													) : not.status === 'rejected' ? (
														<div>
															<Button className="mx-2 not-btn text-danger" disabled>
																Rejected
															</Button>
														</div>
													) : null
												) : not.status === 'accepted' ? (
													<div>
														<Button className="mx-2 not-btn text-info">Show</Button>
													</div>
												) : (
													<div>
														<Button className="mx-2 not-btn text-danger">Rejected</Button>
													</div>
												)}
											</div>
										</div>
									))
									.reverse()}
						</Fragment>
					))
					.reverse()}
			</div>
		</div>
	);
}
const mapStateToProps = (state) => ({
	notifications: state.notification.notifications,
	market: state.market.market,
});

export default connect(mapStateToProps, { getAllProducts })(Notification);
