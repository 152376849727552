/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import { Row, Container, Form, Button } from 'react-bootstrap';

import PropTypes from 'prop-types';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

//redux
import { connect } from 'react-redux';
import { resetPasswordUser, forgetPasswordUser } from '../../_redux/actions/ActionAuthentication';

import { resetPasswordValidation } from '../../_helpers';

const login = css`
	.login-container {
		border-radius: 10px;
		width: 30rem;
	}
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		-webkit-background-clip: text;
		background-clip: text;
	}

	input,
	input:focus,
	textarea {
		border-top-style: hidden;
		border-right-style: hidden;
		border-left-style: hidden;
		border-bottom-style: groove;
		background-color: transparent !important;
		font-size: 1rem;
		outline: none !important;
		border: 1px 0px 0px 0px solid black;
	}
	.password-inputarea {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
	}

	.password-input {
		flex: 1;
		height: 100%;
		width: auto;
		display: hidden;
	}

	.password-input-items {
		margin: 0 2px;
	}
	@media only screen and (max-width: 767px) {
		.login-container {
			width: 100%;
			height: 100%;
			border-radius: 0px;
		}
	}
`;

const bigButtons = css`
	margin: 1rem;
	font-size: 1.3rem;
	width: 100%;
	height: 3.5rem;
	border-radius: 10px;
	letter-spacing: normal;
	font-weight: 700;
	background-color: #e97724;
	color: white;
`;

const backBtn = css`
	position: absolute;
	top: 2rem;
	left: 2rem;
	i {
		font-size: 2.4rem;
	}
`;

const padding = css`
	padding: 1.81rem 2rem 1.81rem 2rem;
`;

function ResetPassword({ ui, history, resetPasswordUser, location, forgetPasswordUser }) {
	const [email, setEmail] = useState('');
	const [token, setToken] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	useEffect(() => {
		const { email, code } = queryString.parse(location.search);
		if (email) {
			setEmail(email);
		}
		if (code) {
			setToken(code);
		}
	}, [location.search]);

	const { register, handleSubmit, errors } = useForm({
		mode: 'onBlur',
		defaultValues: {
			password: '',
		},
		resolver: yupResolver(resetPasswordValidation),
	});
	const onSubmit = (password) => {
		const tempPassword = {
			otp: password.token,
			new_password: password.password,
			re_new_password: password.confirmPassword,
			email,
		};
		resetPasswordUser(tempPassword, history);
	};

	const resendToken = () => {
		if (email) {
			const tempEmail = {
				email,
			};
			forgetPasswordUser(tempEmail, history);
		}
	};
	return (
		<Container css={login} fluid className="bg-primary vh-100">
			<Row className="d-flex align-items-center justify-content-center border wrapper h-100  ">
				<div css={padding} className="bg-white position-relative login-container">
					<div className="text-center">
						<img className="mb-1 h-auto" style={{ width: '5rem' }} src="/images/p2plogo.png" alt="logo" />
						<h1 className="h3 p-5 font-weight-bold text-dark ">Reset Password</h1>
					</div>
					<Link to="/login" css={backBtn} className="text-decoration-none">
						<i className="fas fa-arrow-left" />
					</Link>

					<Form onSubmit={handleSubmit(onSubmit)}>
						<Form.Group controlId="formBasicToken">
							<Form.Control
								type="text"
								name="token"
								isInvalid={errors.token}
								placeholder="Enter Token"
								defaultValue={token}
								ref={register}
							/>
							<Form.Text className="text-danger">{errors.token?.message}</Form.Text>
						</Form.Group>

						<Form.Group controlId="formBasicPassword">
							<div className="password-inputarea">
								<Form.Control
									className="password-input-items password-input"
									isInvalid={errors.password}
									type={showPassword ? 'text' : 'password'}
									name="password"
									ref={register}
									placeholder="Enter Password"
									autoComplete="new-password"
								/>
								<div className="password-input-items " onClick={() => setShowPassword((prev) => !prev)}>
									{showPassword ? <FaEye /> : <FaEyeSlash />}
								</div>
							</div>
							<Form.Text className="text-danger">{errors.password?.message}</Form.Text>
						</Form.Group>

						<Form.Group controlId="formBasicConfirmPassword">
							<div className="password-inputarea">
								<Form.Control
									isInvalid={errors.confirmPassword}
									className="password-input-items password-input"
									type={showConfirmPassword ? 'text' : 'password'}
									name="confirmPassword"
									placeholder="Confirm Password"
									autoComplete="new-password"
									ref={register}
								/>
								<div
									className="password-input-items "
									onClick={() => setShowConfirmPassword((prev) => !prev)}
								>
									{showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
								</div>
							</div>
							<Form.Text className="text-danger">{errors.confirmPassword?.message}</Form.Text>
						</Form.Group>
						<div className="d-flex justify-content-around">
							{email && (
								<Button css={bigButtons} variant="secondary" onClick={resendToken} size="lg">
									Resend
								</Button>
							)}
							<Button css={bigButtons} variant="primary" type="submit" size="lg">
								Reset
							</Button>
						</div>
					</Form>
				</div>
			</Row>
		</Container>
	);
}

ResetPassword.propTypes = {
	signupUser: PropTypes.func.isRequired,
	clearError: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	ui: state.ui,
});

export default connect(mapStateToProps, { resetPasswordUser, forgetPasswordUser })(ResetPassword);
