/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useRef } from 'react';
import Chart from 'chart.js';
import ChartDate from './ChartDate';
import ChartHeading from './ChartHeading';

export default function LineGraph(props) {
	const chartRef = useRef();

	useEffect(() => {
		const myChartRef = chartRef.current.getContext('2d');
		let gradient;
		if (props.hoverBackgroundColor) {
			gradient = myChartRef.createLinearGradient(0, 0, 0, 400);
			props.hoverBackgroundColor.map((g, i) => gradient.addColorStop(i, g));
			props.data[0].hoverBackgroundColor = gradient;
		}

		// if (props.type === 'doughnut') {
		// 	Chart.pluginService.register({
		// 		beforeDraw: function (chart) {
		// 			var width = chart.chart.width,
		// 				height = chart.chart.height,
		// 				ctx = chart.chart.ctx;

		// 			ctx.restore();
		// 			var fontSize = (height / 180).toFixed(2);
		// 			ctx.font = fontSize + 'em sans-serif';
		// 			ctx.textBaseline = 'middle';

		// 			var text = 'xxx kWh',
		// 				textX = Math.round((width - ctx.measureText(text).width) / 2),
		// 				textY = height / 1.7;

		// 			ctx.fillText(text, textX, textY);
		// 			ctx.save();
		// 		},
		// 	});
		// }

		new Chart(myChartRef, {
			type: props.type,

			layout: {
				padding: {
					top: 5,
					left: 15,
					right: 15,
					bottom: 15,
				},
			},
			data: {
				//Bring in data
				labels: props.label,
				datasets: props.data,
			},
			options: {
				responsive: true,
				maintainAspectRatio: false,
				animation: {
					duration: 1000,
					easing: 'easeInBounce',
				},
				legend: {
					display: true,
					position: 'top',
					align: 'start',
					fullWidth: false,
					labels: {
						boxWidth: 15,
						fontColor: '#000',
						fillStyle: '#000',
					},
				},
			},
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<div style={{ padding: '0 1rem' }}>
				<ChartHeading title={props.title} />
				<ChartDate />
			</div>
			<div style={{ width: '100%', height: '16rem' }}>
				<canvas id="myChart" width="100%" height="5rem" ref={chartRef} />
			</div>
		</div>
	);
}
