import React from 'react';
import { useState} from 'react';
import { useForm } from 'react-hook-form';
import { Button, Col, Form, Modal } from 'react-bootstrap';
import { fetchWrapper } from '../../_helpers';
// toasts
import { toast } from 'react-toastify';


const AddCard = () => {
    const [openModal, setOpenModal] = useState(false)

    const { register, handleSubmit, errors } = useForm({
		mode: 'onBlur',
	});

    const onSubmit = async (data) => {
        // const tempData = {
        //     name: data.name,
        //     expiry_from: data.expiry_from,
        //     expiry_to: data.expiry_to,
        //     card_no: data.card_no
        // }
        // var form_data = new FormData();
        
        // for (var key in tempData) {
		// 	console.log(key);
		// 	form_data.append(key, tempData[key]);
		// }

        try {
			const response = await fetchWrapper.post('payment/pyment_method/', JSON.stringify(data));
			if(response.success){
				toast.success("Card added successfully")
				setOpenModal(false)
			}else if(response.error){
				toast.error(response.error)
			}
			console.log(response, 'posted?');
		} catch (e) {
			console.log(e, errors);
		}
        // console.log(data)
    }

    
    return (
        <>
        <div className="w-100 d-flex flex-row justify-content-end mt-2">
            <Button className="bg-white" onClick={()=>setOpenModal(true)}> Add New Payment Card </Button>
        </div>
        <Modal
				show={openModal}
				onHide={() => setOpenModal(false)}
				className="d-flex align-items-center"
				animation={false}
			>
				<Modal.Dialog style={{ width: '800px', margin: '0' }}>
                    <Modal.Header>
                        <strong>Add your card details</strong>
                    </Modal.Header>
					<Form onSubmit={handleSubmit(onSubmit)}>
                        {/*  */}
						<Form.Group className="p-4">
							<Form.Row>
								<Form.Label column sm="4">
									Name on the Card
								</Form.Label>
								<Col sm="8">
									<Form.Control
										ref={register}
										name="name"
										size="sm"
										type="text"
										placeholder="Full Name"
									/>
								</Col>
							</Form.Row>
							<Form.Row>
								<Form.Label column sm="4">
									Card Number
								</Form.Label>
								<Col sm="8">
									<Form.Control
										ref={register}
										name="card_no"
										size="sm"
										type="number"	
                                        placeholder=""
									/>
								</Col>
							</Form.Row>
							<Form.Row>
								<Form.Label column sm="4">
									Expiration Date
								</Form.Label>
								<Col sm="8">
									<Form.Row>
										<Col className="d-flex">
											<Form.Text sm="4" className="text-muted mr-2">
												From
											</Form.Text>
											<Form.Control
												ref={register}
												name="expiry_from"
												as="select"
												placeholder="2020"
												size="sm"
												
											>
												<option>2009</option>
												<option>2010</option>
												<option>2011</option>
												<option>2012</option>
												<option>2013</option>
												<option>2014</option>
												<option>2015</option>
												<option>2016</option>
												<option>2017</option>
												<option>2018</option>
												<option>2019</option>
												<option>2020</option>
											</Form.Control>
										</Col>
										<Col className="d-flex">
											<Form.Text sm="4" className="text-muted mr-2">
												To
											</Form.Text>
											<Form.Control
												ref={register}
												name="expiry_to"
												as="select"
												placeholder="2018"
												size="sm"
												
											>
												<option>2021</option>
												<option>2022</option>
												<option>2023</option>
												<option>2024</option>
												<option>2025</option>
												<option>2026</option>
												<option>2027</option>
												<option>2028</option>
												<option>2029</option>
												<option>2030</option>
												<option>2031</option>
												<option>2032</option>
											</Form.Control>
										</Col>
									</Form.Row>
                                    
								</Col>
							</Form.Row>
							<Form.Row className="w-100 d-flex flex-row justify-content-center">
								<Button type="submit" variant="secondary" className="btn w-80 mt-4 text-white">
									Save
								</Button>
							</Form.Row>
						</Form.Group>
					</Form>
				</Modal.Dialog>
			</Modal>
        </>
    )
}

export default AddCard
