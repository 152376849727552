/** @jsx jsx */
import { jsx } from '@emotion/core';
import { withRouter, Link } from 'react-router-dom';
import {AiOutlineRight} from 'react-icons/ai'

import routes from '../../routes';

const Breadcrumbs = (props) => {
	const {
		match: { path },
	} = props;
	const pathnames = path.split('/').filter((x) => x);
	const findName = (route) => {
		const tempRoute = routes.find((r) => r.path === route);
		if (tempRoute) {
			return tempRoute.name;
		}
		return;
	};
	return (
		<div className={pathnames.length ? 'breadcrumb mb-2' : ''}>
			{pathnames.map((name, index) => {
				const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
				const displayName = findName(routeTo);
				const isLast = index === pathnames.length - 1;
				return displayName ? (
					isLast ? (
						<span key={name} active>
							{displayName}
						</span>
					) : (
						<div>
							<Link className="text-dark" key={name} to={routeTo}>
								{displayName}
							</Link>
							<AiOutlineRight style={{fontSize:"16px"}}/>
						</div>
					)
				) : null;
			})}
		</div>
	);
};

export default withRouter(Breadcrumbs);
