import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { FaStar } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import AddToCartBox from './AddToCartBox';
import EnergyTypeIcon from './EnergyTypeIcon';
import { connect } from 'react-redux';
import { getDistanceFromLatLonInM, round } from '../../_helpers';

const MarketList = ({ market, handleAddToCart, position }) => {
	const history = useHistory();
	return (
		market.length > 0 &&
		market.map((mar) => (
			<Col md={6} key={mar.id}>
				<div className="energy-single" onClick={() => history.push(`/market/detail/${mar.id}`)}>
					<Row>
						<Col md={7}>
							<h5>
								<EnergyTypeIcon id={mar.energy_type_id} />
								<span className="ml-2">{mar.name}</span>
							</h5>
							<div className="rating-wrapper">
								<FaStar />
								<FaStar />
								<FaStar />
								<FaStar />
								<FaStar />
							</div>
							<p>
								Supplier: <span className="pl-2">{mar.supplier}</span>
							</p>
							<p>
								Distance:{' '}
								<span className="pl-2">
									{' '}
									{`${round(
										getDistanceFromLatLonInM(
											position?.coords?.latitude,
											position?.coords?.longitude,
											mar.address?.point.lat,
											mar.address?.point.lon
										)/1609.34, 2
									)} miles away`}
								</span>
							</p>
							<p>
								Energy sold: <span className="pl-2">{mar.sell_percent}%</span>
							</p>
						</Col>
						<Col md={5}>
							<img
								src={mar.picture ? mar.picture : '/images/item1.jpg'}
								className="energy-img img-responsive"
								alt="energy"
							/>
						</Col>
						<Col md={12}>
							<Row>
								<Col md={4}>
									<p className="font-weight-bold">$20 monthly</p>
									<p className="font-weight-bold">$200 annually</p>
								</Col>
								<Col md={3}>
									<p className="font-weight-bold text-center">{mar.payment_type}</p>
								</Col>
								<Col md={5} onClick={(e) => e.stopPropagation()}>
									<AddToCartBox handleAddToCart={handleAddToCart} mar={mar} />
								</Col>
							</Row>
						</Col>
					</Row>
				</div>
			</Col>
		))
	);
};
const mapStateToProps = (state) => ({
	position: state.user.position,
});
export default connect(mapStateToProps)(MarketList);
