/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import {useEffect, useState} from 'react';
import Main from '../../Layouts/Main';
import TopBar from '../../Layouts/TopBar/TopBar';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { fetchWrapper } from '../../_helpers';
import { connect, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from '../../Layouts/Loader/Loader';

const notificationStyles = css`
	margin: 0px 20px;
	.image {
		width: 120px;
		height: 120px;
		margin: 25px 350px 20px 0.5px;
		border-radius: 42px; 
	}
	.product-image {
		box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
	}
	.accept-btn {
		padding: 0.2rem 1rem;
		border-radius: 12px;
		background-color: #011941;
		color: #fff;
		font-size: 1.2rem;
	}
	.reject-btn {
		padding: 0.2rem 1rem;
		border-radius: 12px;
		border: solid 1.5px #011941;
		background-color: #fff;
		font-size: 1.2rem;
	}
`;

const NotificationDetails = ({match, location, notifications}) => {

	const notificationId = match.params.id
	const userType = location.state.userType
	const notification= location.state.subs
	const status = location.state.status
	const [details, setDetails] = useState([])
	const [loading, setLoading] = useState([])

	const dispatch = useDispatch();

	useEffect(() => {
		(async () => {
			setLoading(true)
			try {
				const response = await fetchWrapper.get(`notifications/notification_detail/${notificationId}`);
				setDetails(response)
				console.log(response)
			} catch (e) {
				console.log(e, "thisis error");
			}
			setLoading(false)
		})();
	}, [notificationId])
	
	const handleAccept = (notification) => {
		const form_data = new FormData();
		form_data.append('subscription_status', 'accepted');
		form_data.append('energy_source_id', notification.energy_id);
		form_data.append('property_id', notification.property_id);

		fetchWrapper
			.post(`payment/${notification.id}/update_status`, form_data)
			.then((res) => {
				dispatch({ type: 'SET_NEW_NOTIFICATION', payload: { ...notification, status: 'accepted' } });
				toast.success('Accepted');
			})
			.catch((e) => toast.error(e.detail));
	};

	const handleReject = (notification) => {
		const form_data = new FormData();
		form_data.append('subscription_status', 'rejected');
		form_data.append('energy_source_id', notification.energy_id);
		form_data.append('property_id', notification.property_id);

		fetchWrapper
			.post(`payment/${notification.id}/update_status`, form_data)
			.then((res) => {
				dispatch({ type: 'SET_NEW_NOTIFICATION', payload: { ...notification, status: 'rejected' } });
				toast.error('Declined');
			})
			.catch((e) => toast.error(e.detail));
	};


	return (
		<Main>
			<TopBar>
				{loading && <Loader/>}
				<Card css={notificationStyles} className="px-5 py-2">
					<Container>
						<Row>
							<Col md="6">
								<h4 className="mb-0 font-weight-bold">{userType? userType : "Sender"} Info</h4>
								<img src={details.buyer_image? details.buyer_image : "/images/profile.png"} alt="user" className="image" />
								<Row className="mb-1">
									<Col className="font-weight-bold">Name</Col>
									<Col>{details.buyer_name && details.buyer_name}</Col>
								</Row>
								<Row className="mb-1">
									<Col className="font-weight-bold">Email</Col>
									<Col>{details.email}</Col>
								</Row>
								<Row className="mb-1">
									<Col className="font-weight-bold">Phone Number</Col>
									<Col>{details.phone_number}</Col>
								</Row>
								<Row className="mb-1">
									<Col className="font-weight-bold">Total Energy</Col>
									<Col>{details.total_energy}</Col>
								</Row>
								<Row className="mb-1">
									<Col className="font-weight-bold">Total Property</Col>
									<Col>{details.total_property}</Col>
								</Row>
							</Col>
							<Col md="6">
								<h4 className="mb-0 font-weight-bold">Product Info</h4>
								<img src={details.energy_image? details.energy_image : "/images/item3.jpg"} alt="product" className="image product-image" />
								<Row className="mb-1">
									<Col className="font-weight-bold">{details.product_name}</Col>
									<Col>{details.name}</Col>
								</Row>
								<Row className="mb-1">
									<Col className="font-weight-bold">Price</Col>
									<Col>${details.price} per year</Col>
								</Row>
								<Row className="mb-1">
									<Col className="font-weight-bold">Percentage Requested</Col>
									<Col>{details.percentage_used}%</Col>
								</Row>
								<Row className="mb-1">
									<Col className="font-weight-bold">Distance</Col>
									<Col>***</Col>
								</Row>
								<Row className="mb-1">
									<Col className="font-weight-bold">Payment method</Col>
									<Col>{details.payment_method}</Col>
								</Row>
							</Col>
						</Row>
						{userType==="Buyer" &&
							<div className="justify-content-center d-flex my-3">
								{/* <Button className="m-2 accept-btn" onClick={()=>handleAccept(notification)}>Accept</Button>
								<Button className="m-2 reject-btn" onClick={()=>handleReject(notification)}>Decline</Button> */}
								{
									status === 'pending' ? (
										<div>
											<Button
												className="mx-2 not-btn text-info"
												onClick={() => handleAccept(notification)}
											>
												Accept
											</Button>
											<Button
												className="mx-2 not-btn text-danger"
												onClick={() => handleReject(notification)}
											>
												Decline
											</Button>
										</div>
									) : status === 'accepted' ? (
										<div>
											<Button className="mx-2 not-btn text-info" disabled>
												Accepted
											</Button>
										</div>
									) : status === 'rejected' ? (
										<div>
											<Button className="mx-2 not-btn text-danger" disabled>
												Rejected
											</Button>
										</div>
									) : null
								}
							</div>
						}
					</Container>
				</Card>
			</TopBar>
		</Main>
	);
};


export default NotificationDetails;
