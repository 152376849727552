/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Card, Form, Container, Row, Col, Button, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

const billing = css`
	width: 80%;
	input,
	input:focus,
	textarea {
		border-top-style: hidden;
		border-right-style: hidden;
		border-left-style: hidden;
		border-bottom-style: groove;

		background-color: transparent !important;
		font-size: 1rem;
		outline: none !important;
		border: 1px 0px 0px 0px solid black;
	}
`;
const bigButtons = css`
	margin: 1rem;
	font-size: 1.3rem;
	width: 50%;
	height: 3.5rem;
	border-radius: 10px;
	letter-spacing: normal;
	font-weight: 700;
	background-color: #e97724;
	color: white;
`;

const padding = css`
	padding: 1.81rem 2rem 1.81rem 2rem;
`;

export default function BillingInfo({ handleDataSubmit, loading }) {
	const { register, handleSubmit, errors } = useForm({
		mode: 'onBlur',
		defaultValues: {
			email: '',
			password: '',
		},
	});

	const onSubmit = (data) => {};
	return (
		// <Main>
		// 	<TopBar>
		<Container className="d-flex justify-content-center mt-2">
			<Card css={billing}>
				<Row className="d-flex  justify-content-center  h-100  ">
					<div css={padding} className="position-relative ">
						<p className="font-weight-bold mb-4">
							Where should we send your earnings? (Earning minus transaction fee)
						</p>
						<Form onSubmit={handleSubmit(onSubmit)} className="p-2">
							<Form.Group as={Row} controlId="formPlaintextSelects">
								<Form.Label className="text-right" column sm="4">
									Checking Account
								</Form.Label>
								<Col sm="6">
									<Form.Control
										as="select"
										name="account"
										defaultValue="account"
										isInvalid={errors.account}
										ref={register}
									>
										<option value="account">Account</option>
									</Form.Control>
									<Form.Text className="text-danger">{errors.account?.message}</Form.Text>
								</Col>
							</Form.Group>

							<Form.Group as={Row} controlId="formPlaintextBankName">
								<Form.Label className="text-right" column sm="4">
									Bank Name
								</Form.Label>
								<Col sm="6">
									<Form.Control
										type="text"
										name="bank_name"
										isInvalid={errors.bank_name}
										ref={register}
										placeholder="Enter your bank Name"
									/>

									<Form.Text className="text-danger">{errors.bank_name?.message}</Form.Text>
								</Col>
							</Form.Group>

							<Form.Group as={Row} controlId="formPlaintextRoutingNumber">
								<Form.Label className="text-right" column sm="4">
									Routing Number
								</Form.Label>
								<Col sm="6">
									<Form.Control
										type="text"
										name="routing_number"
										isInvalid={errors.bank_name}
										ref={register}
										placeholder="Enter your Routing NUmber"
									/>

									<Form.Text className="text-danger">{errors.routing_number?.message}</Form.Text>
								</Col>
							</Form.Group>
							<Form.Group as={Row} controlId="formPlaintextAccountNumber">
								<Form.Label className="text-right" column sm="4">
									Account Number
								</Form.Label>
								<Col sm="6">
									<Form.Control
										type="text"
										name="account_number"
										isInvalid={errors.bank_name}
										ref={register}
										placeholder="Enter your account Number"
									/>

									<Form.Text className="text-danger">{errors.account_number?.message}</Form.Text>
								</Col>
							</Form.Group>
						</Form>
						<div className="my-3">
							{/* <Link
										to="/market/energy-type"
										className="d-flex justify-content-center text-decoration-none"
									> */}
							<Button variant="secondary" onClick={handleDataSubmit} css={bigButtons} disabled={loading}>
								{!loading ? (
									'Submit'
								) : (
									<Spinner animation="border" role="status">
										<span className="sr-only">Loading...</span>
									</Spinner>
								)}
							</Button>
							{/* </Link> */}
						</div>
					</div>
					<p className="text-muted mb-4">Note : You can edit your transaction account from your profile</p>
				</Row>
			</Card>
		</Container>
		// 	</TopBar>
		// </Main>
	);
}
