import { authHeader } from './auth-header';

// toasts
import { toast } from 'react-toastify';

const COMMON_URL = process.env.REACT_APP_BACKEND_URL;

function get(url) {
	const requestOptions = {
		method: 'GET',
		headers: authHeader(),
	};
	return fetch(COMMON_URL + url, requestOptions).then(handleResponse);
}

function post(url, body, contentType) { 
	let headers = authHeader();
	if (contentType) {
		headers['Content-Type'] = 'application/json';
	}
	const requestOptions = {
		method: 'POST',
		headers: headers,
		body: body,
	};
	return fetch(COMMON_URL + url, requestOptions).then(handleResponse);
}

function put(url, body, contentType) {
	let headers = authHeader();
	if (contentType) {
		headers['Content-Type'] = 'application/json';
	}
	const requestOptions = {
		method: 'PUT',
		headers,
		body: contentType ? JSON.stringify(body) : body,
	};
	return fetch(COMMON_URL + url, requestOptions).then(handleResponse);
}

function patch(url, body, contentType) {
	let headers = authHeader();
	if (contentType) {
		headers['Content-Type'] = 'application/json';
	}
	const requestOptions = {
		method: 'PATCH',
		headers,
		body: body,
	};
	return fetch(COMMON_URL + url, requestOptions).then(handleResponse);
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(url) {
	const requestOptions = {
		method: 'DELETE',
		headers: authHeader(),
	};
	return fetch(COMMON_URL + url, requestOptions).then(handleResponse);
}

// helper functions
function handleResponse(response) {
	return response.text().then((text) => {
		try {
			const data = text && JSON.parse(text);
			if (!response.ok) {
				if (data.code === 'token_not_valid') {
					const error = {
						code: 401,
						detail: 'Session Expired',
					};
					localStorage.removeItem('token');
					toast.error('Session Expired');
					window.location.reload(false);
					return Promise.reject(error);
				} else if (response.status === 401) {
					const error = { code: response.status, detail: (data && data.detail) || response.statusText };
					return Promise.reject(error);
				}
				const error = (data && data) || response;
				return Promise.reject(error);
			}

			return data;
		} catch (e) {
			const error = { code: 500, detail: 'something went wrong' };
			return Promise.reject(error);
		}
	});
}

export const fetchWrapper = {
	get,
	post,
	put,
	patch,
	delete: _delete,
};
