/** @jsx jsx */
import { jsx, css } from '@emotion/core';

// import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col, Button} from 'react-bootstrap';
import { FaSolarPanel } from 'react-icons/fa';
import { AiOutlineCaretRight } from 'react-icons/ai';
import HomeSlider from './HomeSlider';


const sellerhome = css`
	.wrapper {
		display:grid;
		grid-template-areas:
		"tips tips panel-center panel-center panel-center top-impact top-impact";
		@media (max-width: 1260px) {
			grid-template-areas:
			"tips top-impact"
			"panel-center panel-center";
		}
		@media (max-width: 768px) {
			grid-template-areas:
			"tips"
			"top-impact"
			"panel-center";
		}
	}
	
	.tips {
		grid-area:tips;
		height: 400px;
		width:280px;
		@media (max-width: 1260px) {
			min-width: 100%;
		}
		.primary-wrapper {
			height: 375px;
		}
	}

	.top-impact {
		grid-area:top-impact;
		height: 400px;
		@media (max-width: 1260px) {
			min-width: 400px;
		}
		.primary-wrapper {
			height: 375px;
		}
	}

	.panel-center {
		grid-area:panel-center;
		@media (max-width: 990px) {
			min-width:400px;
		}
	}
	.earning-rate {
		height: 50px;
		border-radius: 10px;
		box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
		background-color: #e97724;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		margin-bottom: 10px;
		span {
			color: white;
			font-size: 18px;
		}
	}
	.recommended-producer {
		width: 100%;
		height: 50px;
		background-color: #011941;
	}

	.user-impact {
		border-radius: 20px;
		height: 310px;
		min-width: 200px;
		display: flex;
		flex-direction: column;
		object-fit: cover;
		overflow: hidden;
		box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
		img {
			position: relative;
			height: 50%;
			width: 100%;
		}
		.img-text-1 {
			position: absolute;
			top: 20px;
			left: 20px;
			color: white;
			font-size: 1rem;
			text-shadow: rgba(0, 0, 0, 0.35) 15px 5px 15px;
			span {
				font-size: 1.5rem;
				font-weight: bold;
			}
		}
		.img-text-2 {
			position: absolute;
			top: 170px;
			left: 20px;
			color: white;
			font-size: 1rem;
			text-shadow: rgba(0, 0, 0, 0.35) 15px 5px 15px;
			span {
				font-size: 1.5rem;
				font-weight: bold;
			}
		}
	}

	.impact-cover {
		height: 330px;
		overflow-y: scroll;
		&::-webkit-scrollbar {
			display: none;
		}
	}

	.impactor {
		background-color: #cbe5ff;
		height: 60px;
		border-radius: 5px;
		margin-bottom: 10px;
		img {
			height: 50px;
			width: 50px;
			border-radius: 5px;
			margin: 0px 5px;
		}
		.impactor-name {
			font-size: 1rem;
			color: #33759b;
		}
		.impact {
			font-size: 0.8rem;
			color: #515151;
			display: block;
			span {
				font-size: 0.65rem;
				margin-bottom: -5px;
				color: #6a747e;
				display: block;
			}
		}
	}
	.panel-end-hidden {
		display: none;
		@media (max-width: 990px) {
			display: block;
			width: 100%;
		}
	}
`;

function HomeSeller({ property }) {
	return (
		<div css={sellerhome}>
			<div className="wrapper">
				<div className="tips pr-1 py-1">
					<div className="primary-wrapper">
						<p className="text-center text-white h6">Energy Saving Tips</p>
						<HomeSlider />
					</div>
				</div>
				<div md={4} className="panel-center px-1">
					<Row>
						<Col md={12}>
							<div className="earning-rate">
								<span> Earning Rate: $ 0.50/KWh </span>
							</div>
						</Col>
						<Col md={6} className="p-1 pl-3">
							<div className="primary-wrapper">
								<p className="text-center text-white h6">Recommended Producers</p>
								<div className="secondary-wrapper">
									<div className="recommended-producer d-flex flex-row justify-content-between">
										<div>
											<FaSolarPanel /> Solar 15 KW{' '}
										</div>
										<div>
											<AiOutlineCaretRight />
										</div>
									</div>
									<div className="recommended-producer d-flex flex-row justify-content-between">
										<div>
											<FaSolarPanel /> Solar 15 KW{' '}
										</div>
										<div>
											<AiOutlineCaretRight />
										</div>
									</div>
									<div className="recommended-producer d-flex flex-row justify-content-between">
										<div>
											<FaSolarPanel /> Solar 15 KW{' '}
										</div>
										<div>
											<AiOutlineCaretRight />
										</div>
									</div>
									<div className="recommended-producer d-flex flex-row justify-content-between">
										<div>
											<FaSolarPanel /> Solar 15 KW{' '}
										</div>
										<div>
											<AiOutlineCaretRight />
										</div>
									</div>
									<Link to="/market">
										<Button variant="primary" className="market-btn">
											Marketplace
										</Button>
									</Link>
								</div>
							</div>
						</Col>
						<Col md={6} className="p-1 pr-3">
							<div className="user-impact">
								<img src="/images/trees.png" alt="trees" />
								<div className="img-text-1">
									Trees Saved <br />
									<span>150 trees</span>
								</div>
								<img src="/images/co2.png" alt="co2" />
								<div className="img-text-2">
									CO2 Emitted <br />
									<span>500 gigaton</span>
								</div>
							</div>
						</Col>
					</Row>
				</div>

				<div className="top-impact pl-1 py-1">
					<div className="primary-wrapper">
						<p className="text-center text-white h6">Top Impact Made</p>
						<div className="impact-cover d-flex flex-column">
							<div className="impactor d-flex flex-row justify-content-start align-items-center">
								<img src="/images/profile.png" alt="avatar" />
								<div className="impactor-details p-1">
									<div className="impactor-name">Barbara Barrett</div>
									<div className="d-flex flex-row justify-content-between pr-4">
										<div className="impact mr-2">
											<span>Trees Saved</span>
											<strong>1500 trees</strong>
										</div>
										<div className="impact">
											<span>CO2 Emission</span>
											<strong>16 gigatotn</strong>
										</div>
									</div>
								</div>
							</div>
							<div className="impactor d-flex flex-row justify-content-start align-items-center">
								<img src="/images/profile.png" alt="avatar" />
								<div className="impactor-details p-1">
									<div className="impactor-name">Louis Foster</div>
									<div className="d-flex flex-row justify-content-between pr-4">
										<div className="impact mr-2">
											<span>Trees Saved</span>
											<strong>1500 trees</strong>
										</div>
										<div className="impact">
											<span>CO2 Emission</span>
											<strong>16 gigatotn</strong>
										</div>
									</div>
								</div>
							</div>
							<div className="impactor d-flex flex-row justify-content-start align-items-center">
								<img src="/images/profile.png" alt="avatar" />
								<div className="impactor-details p-1">
									<div className="impactor-name">George Wagner</div>
									<div className="d-flex flex-row justify-content-between pr-4">
										<div className="impact mr-2">
											<span>Trees Saved</span>
											<strong>1500 trees</strong>
										</div>
										<div className="impact">
											<span>CO2 Emission</span>
											<strong>16 gigatotn</strong>
										</div>
									</div>
								</div>
							</div>
							<div className="impactor d-flex flex-row justify-content-start align-items-center">
								<img src="/images/profile.png" alt="avatar" />
								<div className="impactor-details p-1">
									<div className="impactor-name">Bruce Fuller</div>
									<div className="d-flex flex-row justify-content-between pr-4">
										<div className="impact mr-2">
											<span>Trees Saved</span>
											<strong>1500 trees</strong>
										</div>
										<div className="impact">
											<span>CO2 Emission</span>
											<strong>16 gigatotn</strong>
										</div>
									</div>
								</div>
							</div>
							<div className="impactor d-flex flex-row justify-content-start align-items-center">
								<img src="/images/profile.png" alt="avatar" />
								<div className="impactor-details p-1">
									<div className="impactor-name">Skyler Grey</div>
									<div className="d-flex flex-row justify-content-between pr-4">
										<div className="impact mr-2">
											<span>Trees Saved</span>
											<strong>1500 trees</strong>
										</div>
										<div className="impact">
											<span>CO2 Emission</span>
											<strong>16 gigatotn</strong>
										</div>
									</div>
								</div>
							</div>
							<div className="impactor d-flex flex-row justify-content-start align-items-center">
								<img src="/images/profile.png" alt="avatar" />
								<div className="impactor-details p-1">
									<div className="impactor-name">Sovit Lincoln</div>
									<div className="d-flex flex-row justify-content-between pr-4">
										<div className="impact mr-2">
											<span>Trees Saved</span>
											<strong>1500 trees</strong>
										</div>
										<div className="impact">
											<span>CO2 Emission</span>
											<strong>16 gigatotn</strong>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

// HomeSeller.propTypes = {};

export default HomeSeller;
