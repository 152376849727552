import React from 'react';
import { Slide } from 'pure-react-carousel';
import { Row, Col } from 'react-bootstrap';
import { FaSolarPanel, FaStar } from 'react-icons/fa';
// import { getDistanceFromLatLonInM, round } from '../../../_helpers';
import styled from '@emotion/styled';

const Details = styled.div`
	line-height: 25px;
	font-size: 16px;
	color: #011941;
`;

const PaymentDetail = styled.div`
	line-height: 25px;
	font-size: 16px;
	font-weight: bold;
	color: #011941;
`;

const EachEnergySelling = ({ items, i }) => {
	return (
		<Slide index={i / 2}>
			<div className="energy-single mx-2">
				<Row>
					<Col md={7}>
						<h5 style={{ color: '#011941', margin: '0' }}>
							<FaSolarPanel />
							<span className="ml-2">{items[i].energy_name}</span>
						</h5>
						<div className="rating-wrapper">
							<FaStar />
							<FaStar />
							<FaStar />
							<FaStar />
							<FaStar />
						</div>

						<Details>
							Being used by:
							<span className="pl-2"> {items[i].buyer_name} </span>
							<br />
							Selling:
							<span className="pl-2">{items[i].selling}%</span>
							<br />
							Average Production:
							<span className="pl-2">{items[i].average_production} kWh/year</span>
						</Details>
					</Col>
					<Col md={5}>
						<img src={items[i].image} className="energy-img img-responsive float-right" alt="energy" />
					</Col>
					<Col md={12}>
						<Row>
							<Col md={4}>
								<PaymentDetail>$200 monthly</PaymentDetail>
								<PaymentDetail>{items[i].Payment_method}</PaymentDetail>
							</Col>
							<Col md={4}>
								<PaymentDetail className="font-weight-bold text-center" style={{ fontSize: '16px' }}>
									Fixed
								</PaymentDetail>
							</Col>
						</Row>
					</Col>
				</Row>
			</div>
			{items[i + 1] && (
				<div className="energy-single mx-2">
					<Row>
						<Col md={7}>
							<h5 style={{ color: '#011941', margin: '0' }}>
								<FaSolarPanel />
								<span className="ml-2">{items[i + 1].energy_name}</span>
							</h5>
							<div className="rating-wrapper">
								<FaStar />
								<FaStar />
								<FaStar />
								<FaStar />
								<FaStar />
							</div>
							<Details>
								Being used by:
								<span className="pl-2"> {items[i + 1].buyer_name} </span>
								<br />
								Selling:
								<span className="pl-2">{items[i + 1].selling}%</span>
								<br />
								Average Production:
								<span className="pl-2">{items[i + 1].average_production} kWh/year</span>
							</Details>
						</Col>
						<Col md={5}>
							<img
								src={items[i+1].image}
								className="energy-img img-responsive float-right"
								alt="energy"
							/>
						</Col>
						<Col md={12}>
							<Row>
								<Col md={4}>
									<PaymentDetail>$200 monthly</PaymentDetail>
									<PaymentDetail>Paypal</PaymentDetail>
								</Col>
								<Col md={4}>
									<PaymentDetail
										className="font-weight-bold text-center"
										style={{ fontSize: '16px' }}
									>
										Fixed
									</PaymentDetail>
								</Col>
							</Row>
						</Col>
					</Row>
				</div>
			)}
		</Slide>
	);
};

export default EachEnergySelling;
