import React, { Fragment } from 'react';
import { useState } from 'react';
import { Button, Card } from 'react-bootstrap';

const EnergyAllocation = ({ energies }) => {
	const [showAllEnergy, setShowAllEnergy] = useState(false);
	return (
		<Fragment>
			<div class={showAllEnergy ? 'show-more-energy' : 'show-less-energy'}>
				{energies['Energy_Sources'].length > 0 &&
					energies['Energy_Sources'].map((eng) => (
						<Card className="energy-details p-2 text-dark mb-3  mr-5">
							<p className="font-weight-bold mb-0">{eng.name}</p>
							<p className="mb-0">Supplier: {eng.supplier}</p>
							<p className="mb-0">Term: 12 years, fixed</p>
							<p className="mb-0">Distance: 12 miles away</p>
							<p className="mb-0"> Energy used: {eng.sell_percent}%</p>
							<p className="mb-0">Cost: ${eng.price_per_kwh} kwh</p>
						</Card>
					))}
			</div>
			{energies.Energy_Sources.length > 2 && (
				<Button className="btn btn-add m-4 d-block mx-auto" onClick={() => setShowAllEnergy(!showAllEnergy)}>
					{showAllEnergy ? 'Hide' : <span>Show All {energies.Energy_Sources.length} Energies</span>}
				</Button>
			)}
		</Fragment>
	);
};

export default EnergyAllocation;
